import { useMutation } from '@tanstack/react-query';

import { protestPromissoryNote } from 'api/client/promissoryNote';
import { useUpdatePromissoryNoteCache } from 'hooks/PromissoryNotes/useUpdatePromissoryNoteCache';

export const useProtestPromissoryNote = () => {
  const { updatePromissoryNote } = useUpdatePromissoryNoteCache();

  return useMutation({
    mutationFn: protestPromissoryNote,
    onSuccess: (_, data) => {
      const { id, protestReferenceNumber } = data;

      updatePromissoryNote(id, {
        state: 'protested',
        archived: false,
        protestNumber: protestReferenceNumber,
      });
    },
  });
};
