import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteCreditLimit } from 'api/client/creditLimits';
import { QueryKeys } from 'api/client/types';

export const useDeleteCreditLimit = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteCreditLimit,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.CreditLimits] });
    },
  });
};
