import { saveAs } from 'file-saver';
import { parseAsync } from 'json2csv';
import { useCallback } from 'react';

import { Instrument } from 'types/instrument';

export const useExportInstruments = () => {
  const exportInstruments = useCallback(async (instruments: Instrument[]) => {
    const csvToExport = await parseAsync(instruments);
    const fileName = `instruments-${new Date().toISOString()}.csv`;
    const fileContent = new Blob([csvToExport], {
      type: 'text/csv;charset=utf-8;',
    });

    saveAs(fileContent, fileName);
  }, []);

  return { exportInstruments };
};
