import { rem } from 'polished';
import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Menu = styled.ul`
  border: 1px solid ${getTheme('colors.green.base')};
  border-radius: ${getTheme('radius.lg')};
  box-shadow: ${getTheme('shadows.menu')};
  background-color: ${getTheme('colors.white.100')};
  min-width: ${rem(180)};
`;

export const MenuItemIcon = styled.span`
  display: inline-flex;
  font-size: ${rem(16)};
`;

export const MenuItemDivider = styled.li`
  border-bottom: 1px solid ${getTheme('colors.black.10')};
`;
