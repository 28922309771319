import { InstrumentType, InstrumentTypeAbbreviation } from './instrument';
import { Instrument } from './instrument';
import { Invoice } from './invoice';
import { SupplierBankAccount } from './supplierBankAccount';

export type BillOfExchangeState =
  | 'endorsed'
  | 'active'
  | 'declined'
  | 'cancelled'
  | 'retired'
  | 'matured'
  | 'expired'
  | 'protested'
  | 'enforced';

export interface IBillOfExchange extends Instrument {
  state: BillOfExchangeState;
  marginBps: number;
  fundsDisbursed: number;
  disbursementDate: string;
  displayPaymentPlace: boolean;
}

export class BillOfExchange implements IBillOfExchange {
  id!: string;
  clientPrefix!: string;
  buyerCompanyProfileId!: string;
  buyerUserProfileId?: string;
  investorCompanyProfileId!: string;
  investorUserProfileId?: string;
  supplierCompanyProfileId!: string;
  supplierUserProfileId?: string;
  state!: BillOfExchangeState;
  archived!: boolean;
  remitId!: string;
  reference!: string;
  title!: string;
  buyerName!: string;
  investorName!: string;
  maturity!: string;
  amount!: string;
  currency!: string;
  jurisdiction!: string;
  urlToDboe!: string;
  thumbnail!: null;
  type!: string;
  issuedDate!: string;
  hash!: string;
  pdfHash!: string;
  investmentDate!: string;
  issuerName!: string;
  issuancePlace!: string;
  paymentPlace!: string;
  checkedOut!: boolean;
  protestNumber!: null | string;
  enforceableDate!: null | string;
  marginBps!: number;
  fundsDisbursed!: number;
  disbursementDate!: string;
  displayPaymentPlace!: boolean;
  hasApproved!: boolean;
  fundBy?: string;
  invoices!: Invoice[];
  supplierBankAccount!: SupplierBankAccount;

  constructor(billOfExchange: IBillOfExchange) {
    Object.assign(this, billOfExchange);
  }

  isPending(): boolean {
    switch (this.state) {
      case 'endorsed':
        return true;
      default:
        return false;
    }
  }

  isDarkStyle(): boolean {
    switch (this.state) {
      case 'expired':
        return true;
      case 'cancelled':
        return true;
      case 'retired':
        return true;
      case 'declined':
        return true;
      default:
        return this.checkedOut;
    }
  }

  getInstrumentType(): InstrumentType {
    return InstrumentType.BILL_OF_EXCHANGE;
  }

  getInstrumentTypeAbbreviation(): InstrumentTypeAbbreviation {
    return InstrumentTypeAbbreviation.dboe;
  }
}
