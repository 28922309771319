import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useUser } from 'components/UserProvider';
import { NOT_FOUND_PATH } from 'helpers/navigation';

type Props = {
  children: ReactNode;
};

export const AdminPage = (props: Props) => {
  const { isAdmin } = useUser();

  if (!isAdmin) {
    return <Navigate to={NOT_FOUND_PATH} />;
  }

  return <>{props.children}</>;
};
