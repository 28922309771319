import range from 'lodash/range';
import {
  ClipboardEventHandler,
  ComponentProps,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Input } from 'components/Input';

import * as Styles from './CodeInput.styles';

const generateEmptyValueOfLength = (length: number) => {
  return Array(length).fill('');
};

const mapInputValueToStringValue = (value: string[]) => {
  return value.join('');
};

const getTotalNumberOfEnteredCharacters = (value: string[]) => {
  return value.filter((character) => character !== '').length;
};

type Props = ComponentProps<typeof Input> & {
  numberOfCharacters?: number;
  onChange?: (value: string) => void;
};

export const CodeInput = (props: Props) => {
  const {
    className,
    id,
    isInvalid,
    numberOfCharacters = 6,
    onBlur,
    onChange,
    onPaste,
  } = props;
  const [codeValue, setCodeValue] = useState<string[]>(() =>
    generateEmptyValueOfLength(numberOfCharacters)
  );
  const inputRefs = useRef<Record<number, HTMLInputElement>>({});

  useEffect(() => {
    setCodeValue(generateEmptyValueOfLength(numberOfCharacters));
    inputRefs.current = {};
  }, [numberOfCharacters]);

  const focusOnNextCharacter = (position: number) => {
    inputRefs.current?.[position + 1]?.focus();
  };

  const selectInputCharacter = (position: number) => {
    inputRefs.current?.[position]?.select();
  };

  const onCharacterChange = (position: number, character: string) => {
    if (!/\d/.test(character) && character) {
      return;
    }

    setCodeValue((value) => {
      const result = [
        ...value.slice(0, position),
        character,
        ...value.slice(position + 1),
      ];

      return result;
    });

    if (character !== '') {
      focusOnNextCharacter(position);
    }
  };

  const onCharacterPaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
    const data = event.clipboardData.getData('text');
    const pastedDigitsOnlyValue = data
      .replace(/[^\d]/g, '')
      .split('')
      .slice(0, numberOfCharacters);

    const value = [
      ...pastedDigitsOnlyValue,
      ...generateEmptyValueOfLength(
        numberOfCharacters - pastedDigitsOnlyValue.length
      ),
    ];

    setCodeValue(value);
  };

  useEffect(() => {
    if (getTotalNumberOfEnteredCharacters(codeValue) !== numberOfCharacters) {
      onChange?.('');

      return;
    }

    onChange?.(mapInputValueToStringValue(codeValue));
  }, [codeValue, onChange, numberOfCharacters]);

  return (
    <Styles.Container className={className}>
      {range(numberOfCharacters).map((position) => (
        <Styles.CharacterInput
          key={position}
          maxLength={1}
          autoFocus={position === 0}
          onChange={(event) => onCharacterChange(position, event.target.value)}
          value={codeValue[position]}
          ref={(inputReference) => {
            if (inputRefs.current && inputReference) {
              inputRefs.current[position] = inputReference;
            }
          }}
          onFocus={() => selectInputCharacter(position)}
          isInvalid={isInvalid}
          id={position === 0 ? id : undefined}
          onBlur={onBlur}
          onPaste={(event) => {
            event.preventDefault();
            onCharacterPaste(event);
            onPaste?.(event);
          }}
        />
      ))}
    </Styles.Container>
  );
};
