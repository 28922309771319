import { rem } from 'polished';
import styled from 'styled-components/macro';

import { InputIcon } from 'components/Input';
import { getTheme } from 'styles';

const Icon = styled(InputIcon)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${rem(24)};
  width: ${rem(24)};
  font-size: ${rem(16)};
`;

export const InvalidIcon = styled(Icon)`
  color: ${getTheme('colors.error')};

  --secondary-color: ${getTheme('colors.white.100')};
`;

export const ValidIcon = styled(Icon)`
  color: ${getTheme('colors.green.base')};

  --secondary-color: ${getTheme('colors.white.100')};
`;
