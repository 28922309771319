import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${getTheme('space.14')};
`;
