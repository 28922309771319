import { useMatch } from 'react-router-dom';

import { useLogout } from 'api/hooks/useLogout';
import { Avatar } from 'components/Avatar';
import {
  AccountIcon,
  AlarmIcon,
  AnalyticsIcon,
  DataIcon,
  FeedbackIcon,
  FolderIcon,
  LogoutIcon,
  NoteIcon,
  QuantumSafetyIcon,
  SettingsIcon,
  WorkspaceIcon,
} from 'components/Icon';
import { Menu, MenuItem, MenuItemDivider, MenuItemIcon } from 'components/Menu';
import { NavLink } from 'components/NavLink';
import { HoverOverlay } from 'components/Overlay';
import { useUser } from 'components/UserProvider';
import { displayNonFunctionalComponent } from 'config';
import { getFullName } from 'helpers/user';

import { InstrumentsSummary } from './InstrumentsSummary';
import * as Styles from './Sidebar.styles';
import { useInstrumentsSummary } from './useInstrumentsSummary';

export const Sidebar = () => {
  const { mutate: logout } = useLogout();
  const {
    summary: { active, archived, numberOfPendingInstruments },
  } = useInstrumentsSummary();
  const settingsRouteMatch = useMatch('/settings/*');
  const { isAdmin, user } = useUser();

  return (
    <Styles.Sidebar>
      <Styles.Header />

      <Styles.Content>
        <Styles.ContentSection>
          <Styles.Title>Instruments</Styles.Title>
          <Styles.InstrumentCategories>
            <Styles.InstrumentCategory>
              <Styles.CardLink to="/active">
                <Styles.CardPreview>
                  <AlarmIcon />
                </Styles.CardPreview>
                <Styles.CardSummary>
                  <Styles.CardSummaryTitle>
                    Active Instruments
                  </Styles.CardSummaryTitle>

                  <InstrumentsSummary summary={active} />

                  {Boolean(numberOfPendingInstruments) && (
                    <Styles.PendingCount>
                      {numberOfPendingInstruments} Pending Approval
                    </Styles.PendingCount>
                  )}
                </Styles.CardSummary>
              </Styles.CardLink>
            </Styles.InstrumentCategory>

            <Styles.InstrumentCategory>
              <Styles.CardLink to="/archived">
                <Styles.CardPreview>
                  <FolderIcon />
                </Styles.CardPreview>
                <Styles.CardSummary>
                  <Styles.CardSummaryTitle>
                    Archived Instruments
                  </Styles.CardSummaryTitle>
                  <InstrumentsSummary summary={archived} />
                </Styles.CardSummary>
              </Styles.CardLink>
            </Styles.InstrumentCategory>
          </Styles.InstrumentCategories>
        </Styles.ContentSection>

        {displayNonFunctionalComponent && (
          <Styles.ToolsSection>
            <Styles.Title>Tools</Styles.Title>
            <Styles.Tools>
              <Styles.Tool>
                <Styles.ToolIcon>
                  <AnalyticsIcon />
                </Styles.ToolIcon>
                <Styles.ToolTitle>Analytics</Styles.ToolTitle>
              </Styles.Tool>

              <Styles.Tool>
                <Styles.ToolIcon>
                  <DataIcon />
                </Styles.ToolIcon>
                <Styles.ToolTitle>Data</Styles.ToolTitle>
              </Styles.Tool>

              <Styles.Tool>
                <Styles.ToolIcon>
                  <SettingsIcon />
                </Styles.ToolIcon>
                <Styles.ToolTitle>Setup</Styles.ToolTitle>
              </Styles.Tool>
            </Styles.Tools>
          </Styles.ToolsSection>
        )}

        <Styles.FinancingDataSection>
          <Styles.Title>Financing Data</Styles.Title>
          <Styles.FinancingDataItems>
            <Styles.ItemLink to="/credit-limits">
              <Styles.FinancingDataItem>
                <Styles.FinancingDataIcon>
                  <NoteIcon />
                </Styles.FinancingDataIcon>
                <Styles.FinancingDataItemTitle>
                  Credit Limits
                </Styles.FinancingDataItemTitle>
              </Styles.FinancingDataItem>
            </Styles.ItemLink>

            <Styles.ItemLink to="/margins">
              <Styles.FinancingDataItem>
                <Styles.FinancingDataIcon>
                  <AnalyticsIcon />
                </Styles.FinancingDataIcon>
                <Styles.FinancingDataItemTitle>
                  Margins
                </Styles.FinancingDataItemTitle>
              </Styles.FinancingDataItem>
            </Styles.ItemLink>

            <Styles.ItemLink to="/data-visibility">
              <Styles.FinancingDataItem>
                <Styles.FinancingDataIcon>
                  <DataIcon />
                </Styles.FinancingDataIcon>
                <Styles.FinancingDataItemTitle>
                  Data Visibility
                </Styles.FinancingDataItemTitle>
              </Styles.FinancingDataItem>
            </Styles.ItemLink>
          </Styles.FinancingDataItems>
        </Styles.FinancingDataSection>

        {displayNonFunctionalComponent && (
          <Styles.QuantumSafetySection>
            <Styles.Title>Quantum Safety</Styles.Title>
            <Styles.QuantumSafety>
              <Styles.QuantumSafetyPreview>
                <QuantumSafetyIcon />
              </Styles.QuantumSafetyPreview>
              <Styles.QuantumSafetySummary>
                Find out how we ensure your instruments are 100% safe from
                forgery or other nefarious activity.
              </Styles.QuantumSafetySummary>
            </Styles.QuantumSafety>
          </Styles.QuantumSafetySection>
        )}
      </Styles.Content>

      <Styles.UserSection>
        <HoverOverlay
          closeOnOverlayClick
          content={
            <Menu>
              <MenuItem as={NavLink} to="/settings" variant="heading">
                Settings
              </MenuItem>
              {displayNonFunctionalComponent && (
                <MenuItem as={NavLink} to="/settings/account">
                  <MenuItemIcon>
                    <AccountIcon />
                  </MenuItemIcon>
                  Account
                </MenuItem>
              )}

              {isAdmin && (
                <MenuItem as={NavLink} to="/settings/workspace">
                  <MenuItemIcon>
                    <WorkspaceIcon />
                  </MenuItemIcon>
                  Workspace
                </MenuItem>
              )}

              {displayNonFunctionalComponent && <MenuItemDivider />}
              {displayNonFunctionalComponent && (
                <MenuItem onClick={() => logout()}>
                  <MenuItemIcon>
                    <LogoutIcon />
                  </MenuItemIcon>
                  Sign out of TradeSecure
                </MenuItem>
              )}
            </Menu>
          }
        >
          <Styles.UserCard isActive={Boolean(settingsRouteMatch)}>
            <Styles.CardPreview>
              <Avatar name={getFullName(user)} />
            </Styles.CardPreview>
            <Styles.CardSummary>
              <Styles.CardSummaryTitle>
                {getFullName(user)}
              </Styles.CardSummaryTitle>
              <Styles.UserTitle>
                {isAdmin && 'Administrator'}
                {!isAdmin && 'Member'}, {user.registered_name}
              </Styles.UserTitle>
            </Styles.CardSummary>
          </Styles.UserCard>
        </HoverOverlay>

        <Styles.UserActions>
          <Styles.UserAction startIcon={<LogoutIcon />} onClick={logout}>
            Log out
          </Styles.UserAction>
          {displayNonFunctionalComponent && (
            <Styles.UserAction startIcon={<FeedbackIcon />}>
              Feedback
            </Styles.UserAction>
          )}
        </Styles.UserActions>
      </Styles.UserSection>
    </Styles.Sidebar>
  );
};
