import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ENDPOINT_OVERRIDE_MAP } from 'api/config/ClientConfiguration';
import { Alert } from 'components/Alert';
import { AlarmIcon } from 'components/Icon';
import { InstrumentHistoryTable } from 'components/InstrumentHistoryTable';
import { NothingToSee } from 'components/NothingToSee';
import { PageContent } from 'components/PageContent';
import {
  BackLink,
  PageHeader,
  PageHeaderDetails,
  PageHeaderIcon,
  PageHeaderTitle,
} from 'components/PageHeader';
import { PageLoader } from 'components/PageLoader';
import { getErrorMessage } from 'helpers/error';
import { InstrumentType } from 'types/instrument';

type RouterParams = {
  id: string;
};

type Props = {
  mode: InstrumentType;
};

export const InstrumentHistory = (props: Props) => {
  const { id = '' } = useParams<RouterParams>();

  const { mode } = props;
  const endpointConfig = ENDPOINT_OVERRIDE_MAP[mode];
  const {
    data = [],
    error,
    isLoading,
  } = endpointConfig.useInstrumentHistory(id);

  const history = useMemo(() => sortBy(data, ['transaction_time']), [data]);

  const hasHistory = !isEmpty(history);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader>
        <BackLink />
        <PageHeaderDetails>
          <PageHeaderIcon>
            <AlarmIcon />
          </PageHeaderIcon>
          <PageHeaderTitle>Instrument History</PageHeaderTitle>
        </PageHeaderDetails>
      </PageHeader>
      <PageContent>
        {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}

        {!hasHistory && <NothingToSee />}

        {hasHistory && <InstrumentHistoryTable instrumentHistory={history} />}
      </PageContent>
    </>
  );
};
