import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useEffect } from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ApiError } from 'api/client/ApiError';
import { useFetchInstrumentToAccept } from 'api/hooks/BillOfExchange/useBuyerAcceptance';
import { Alert } from 'components/Alert';
import { AuthLayout } from 'components/AuthLayout';
import { Button } from 'components/Button';
import { useMultiStepView } from 'components/MultiStepView';
import { getErrorMessage } from 'helpers/error';
import { silentSubmit } from 'helpers/form';

import * as SharedStyles from '../styles';
import { FetchedInstrument } from '../types';
import * as Styles from './EnterOTP.styles';

type FormData = {
  code: string;
};

const validation: yup.ObjectSchema<FormData> = yup.object({
  code: yup.string().trim().required(),
});

type Props = {
  otpId: string;
  webLinkId: string;
  onSuccess: (data?: FetchedInstrument) => void;
};

export const EnterOTP = (props: Props) => {
  const { onSuccess, otpId, webLinkId } = props;

  const { setView } = useMultiStepView();
  const [notFoundError, setNotFoundError] = useState(false);

  const {
    control,
    formState: { isSubmitSuccessful, isSubmitting, isValid },
    handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(validation),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    data: fetchedInstrument,
    error: fetchInstrumentError,
    isSuccess,
    mutateAsync: fetchInstrument,
  } = useFetchInstrumentToAccept();

  useEffect(() => {
    handleApiError(fetchInstrumentError as ApiError);
  }, [fetchInstrumentError]);

  const handleApiError = (error: ApiError) => {
    if (error) {
      switch (error.status) {
        case 404: {
          setNotFoundError(true);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful && isSuccess) {
      onSuccess(fetchedInstrument);
      setView('view-instrument');
    }
    if (notFoundError) {
      onSuccess(undefined);
      setView('view-instrument');
    }
  }, [
    isSubmitSuccessful,
    setView,
    onSuccess,
    isSuccess,
    fetchedInstrument,
    notFoundError,
  ]);

  const onSubmit = async (data: FormData) => {
    await fetchInstrument({
      otpId: otpId,
      webLinkId: webLinkId,
      otp: data.code,
    });
  };

  return (
    <AuthLayout>
      <SharedStyles.Card>
        <SharedStyles.Title>Enter Code</SharedStyles.Title>
        <Styles.Description>
          A 6-digit one-time passcode has been sent to your phone number.
        </Styles.Description>
        <SharedStyles.Form onSubmit={handleSubmit(silentSubmit(onSubmit))}>
          <SharedStyles.FormControl>
            <SharedStyles.FormLabel htmlFor="verification-code">
              Please enter the one-time passcode here.
            </SharedStyles.FormLabel>
            <Controller
              control={control}
              name="code"
              render={({ field }) => (
                <Styles.CodeInput
                  id="verification-code"
                  {...omit(field, 'ref')}
                />
              )}
            />
          </SharedStyles.FormControl>

          {notFoundError === false && Boolean(fetchInstrumentError) && (
            <Alert>{getErrorMessage(fetchInstrumentError)}</Alert>
          )}

          <Styles.Actions>
            <Button
              type="submit"
              size="medium"
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
            >
              Submit
            </Button>
          </Styles.Actions>
        </SharedStyles.Form>
      </SharedStyles.Card>
    </AuthLayout>
  );
};
