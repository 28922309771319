import styled from 'styled-components/macro';

import {
  Table as TableComponent,
  TableHeaderCell as TableHeaderCellComponent,
} from 'components/Table';
import { getTheme } from 'styles';

export const Table = styled(TableComponent)`
  width: 100%;
  border-radius: ${getTheme('radius.none')};
  border: none;
  border-bottom: 1px solid ${getTheme('colors.black.10')};
`;

export const TableHeaderCell = styled(TableHeaderCellComponent)`
  &:first-child {
    padding-left: 0;
  }
`;
