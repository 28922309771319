import { useMutation } from '@tanstack/react-query';

import { logout as apiLogout } from 'api/client/authentication';
import { useAuthentication } from 'hooks/useAuthentication';

export const useLogout = () => {
  const { logout } = useAuthentication();

  return useMutation({
    mutationFn: async () => {
      apiLogout();
      logout();
    },
  });
};
