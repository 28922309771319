import React, { useState } from 'react';

import { DropDownMenuSelect } from './DropDownMenu.styles';

interface Props {
  defaultValue?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  valueKey: string;
  labelKey: string;
  onChange: (selectedValue: string) => void;
}

export const DropDownMenu = (props: Props) => {
  const { defaultValue, labelKey, onChange, options, placeholder, valueKey } =
    props;
  const [selectedOption, setSelectedOption] = useState(defaultValue ?? '');

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    onChange(event.target.value);
  };

  return (
    <>
      <DropDownMenuSelect value={selectedOption} onChange={handleChange}>
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option[valueKey]}>
            {option[labelKey]}
          </option>
        ))}
      </DropDownMenuSelect>
    </>
  );
};
