import { useMutation } from '@tanstack/react-query';

import { enforceBillOfExchange } from 'api/client/billOfExchange';
import { formatToISODate } from 'helpers/date';
import { useUpdateBillOfExchangeCache } from 'hooks/BillOfExchange/useUpdateBillOfExchangeCache';

export const useEnforceBillOfExchange = () => {
  const { updateBillOfExchange } = useUpdateBillOfExchangeCache();

  return useMutation({
    mutationFn: enforceBillOfExchange,
    onSuccess: (_, data) => {
      const { enforceableDate, id } = data;

      updateBillOfExchange(id, {
        state: 'enforced',
        archived: true,
        enforceableDate: formatToISODate(enforceableDate),
      });
    },
  });
};
