import { useMutation, useQueryClient } from '@tanstack/react-query';

import { linkInvestorToProvider } from 'api/client/dataVisibility';
import { QueryKeys } from 'api/client/types';

export const useAddProvider = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: linkInvestorToProvider,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.DataVisibility] });
    },
  });
};
