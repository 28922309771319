import { useRejectBillOfExchange } from 'api/hooks/BillOfExchange/useRejectBillOfExchange';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogText,
  DialogTitle,
} from 'components/Dialog';
import { getErrorMessage } from 'helpers/error';

import * as Styles from './RejectBillOfExchangeDialog.styles';
type Props = {
  id: string;
  buyerContact: {
    name: string;
    email: string;
  };
  accessToken: string;
  webLinkId: string;
  onClose: () => void;
  onAction: (bool: boolean) => void;
};

export const RejectBillOfExchangeDialog = (props: Props) => {
  const {
    accessToken,
    buyerContact,
    id,
    onAction: handleIsActionable,
    onClose,
  } = props;

  const {
    error,
    isLoading,
    isSuccess,
    mutateAsync: rejectBillOfExchange,
  } = useRejectBillOfExchange();

  const onReject = async () => {
    await rejectBillOfExchange({ id, buyerContact, accessToken });
    handleIsActionable(false);
  };

  return (
    <Dialog>
      <DialogTitle>Reject this Bill of Exchange?</DialogTitle>
      {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}
      <Styles.Content>
        <DialogText>Are you sure you want to proceed?</DialogText>
      </Styles.Content>
      {isSuccess && (
        <Alert severity={'info'}>
          {'Success! Bill of Exchange has been rejected'}
        </Alert>
      )}
      <DialogActions>
        <Button
          variant="secondary"
          size="large"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          size="large"
          onClick={onReject}
          isLoading={isLoading}
          disabled={isSuccess || isLoading || Boolean(error)}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};
