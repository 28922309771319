import { useMutation } from '@tanstack/react-query';

import { approveBillOfExchange } from 'api/client/billOfExchange';
import { useUpdateBillOfExchangeCache } from 'hooks/BillOfExchange/useUpdateBillOfExchangeCache';

export const useApproveBillOfExchange = () => {
  const { updateBillOfExchange } = useUpdateBillOfExchangeCache();

  return useMutation({
    mutationFn: approveBillOfExchange,
    onSuccess: (_, request) => {
      updateBillOfExchange(request.id, { state: 'active', archived: false });
    },
  });
};
