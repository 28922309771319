import { AccountIcon, ChevronRightIcon, WorkspaceIcon } from 'components/Icon';
import { PageContent } from 'components/PageContent';
import {
  PageHeader,
  PageHeaderDetails,
  PageHeaderTitle,
} from 'components/PageHeader';
import { useUser } from 'components/UserProvider';
import { displayNonFunctionalComponent } from 'config';

import * as Styles from './Settings.styles';

export const Settings = () => {
  const { isAdmin } = useUser();

  return (
    <>
      <PageHeader>
        <PageHeaderDetails>
          <PageHeaderTitle>Settings</PageHeaderTitle>
        </PageHeaderDetails>
      </PageHeader>
      <PageContent>
        <Styles.Items>
          {displayNonFunctionalComponent && (
            <Styles.Item>
              <Styles.ItemLink to="account">
                <Styles.ItemIcon>
                  <AccountIcon />
                </Styles.ItemIcon>
                <Styles.ItemDetails>
                  <Styles.ItemTitle>Account</Styles.ItemTitle>
                  <Styles.ItemDescription>
                    Edit your profile, update your password and manage other
                    account settings
                  </Styles.ItemDescription>
                </Styles.ItemDetails>
                <Styles.ChevronIcon>
                  <ChevronRightIcon />
                </Styles.ChevronIcon>
              </Styles.ItemLink>
            </Styles.Item>
          )}

          {isAdmin && (
            <Styles.Item>
              <Styles.ItemLink to="workspace">
                <Styles.ItemIcon>
                  <WorkspaceIcon />
                </Styles.ItemIcon>
                <Styles.ItemDetails>
                  <Styles.ItemTitle>Workspace</Styles.ItemTitle>
                  <Styles.ItemDescription>
                    View your workspace members
                  </Styles.ItemDescription>
                </Styles.ItemDetails>
                <Styles.ChevronIcon>
                  <ChevronRightIcon />
                </Styles.ChevronIcon>
              </Styles.ItemLink>
            </Styles.Item>
          )}
        </Styles.Items>
      </PageContent>
    </>
  );
};
