import { useClick, useDismiss, useInteractions } from '@floating-ui/react';
import { cloneElement, useState } from 'react';

import * as SharedStyles from './styles';
import { Props } from './types';
import { useFloating } from './useFloating';
import { useFloatingDismiss } from './useFloatingDismiss';

export const ClickOverlay = (props: Props) => {
  const { children, closeOnOverlayClick, content, placement } = props;
  const [isDisplayed, setIsDisplayed] = useState(false);

  const { context, refs, strategy, x, y } = useFloating({
    open: isDisplayed,
    onOpenChange: setIsDisplayed,
    placement,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const floatingDismiss = useFloatingDismiss(context, {
    closeOnFloatingClick: closeOnOverlayClick,
  });
  const { getFloatingProps, getReferenceProps } = useInteractions([
    click,
    dismiss,
    floatingDismiss,
  ]);

  return (
    <>
      {cloneElement(children, {
        ref: refs.setReference,
        ...getReferenceProps(),
      })}

      {isDisplayed && (
        <SharedStyles.Overlay
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          {...getFloatingProps()}
        >
          {content}
        </SharedStyles.Overlay>
      )}
    </>
  );
};
