import { ReactNode } from 'react';
import { StyledComponentPropsWithRef } from 'styled-components/macro';

import { ChevronRightIcon } from 'components/Icon';

import * as Styles from './ListItemLink.styles';

type Props = StyledComponentPropsWithRef<typeof Styles.Link> & {
  children: ReactNode;
};

export const ListItemLink = (props: Props) => {
  const { children, ...otherProps } = props;

  return (
    <Styles.Link {...otherProps}>
      {children}

      <Styles.ChevronIcon>
        <ChevronRightIcon />
      </Styles.ChevronIcon>
    </Styles.Link>
  );
};
