import { useQuery } from '@tanstack/react-query';

import { getBuyerCompany } from 'api/client/buyerCompany';
import { QueryKeys } from 'api/client/types';

export const useBuyerCompany = () => {
  return useQuery({
    queryKey: [QueryKeys.BuyerCompany],
    queryFn: getBuyerCompany,
  });
};
