import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';

import { ActiveInstrumentsList } from 'components/ActiveInstrumentList';
import { Alert } from 'components/Alert';
import { AlarmIcon } from 'components/Icon';
import {
  InstrumentsPageActions,
  InstrumentsViewType,
} from 'components/InstrumentsPageActions';
import { NothingToSee } from 'components/NothingToSee';
import {
  PageHeader,
  PageHeaderDetails,
  PageHeaderIcon,
  PageHeaderTitle,
} from 'components/PageHeader';
import { PageLoader } from 'components/PageLoader';
import { getErrorMessage } from 'helpers/error';
import { useActiveBillsOfExchange } from 'hooks/BillOfExchange/useActiveBillsOfExchange';
import { usePendingBillsOfExchange } from 'hooks/BillOfExchange/usePendingBillsOfExchange';
import { useActivePromissoryNotes } from 'hooks/PromissoryNotes/useActivePromissoryNotes';
import { usePendingPromissoryNotes } from 'hooks/PromissoryNotes/usePendingPromissoryNotes';
import { useExportInstruments } from 'hooks/useExportInstruments';
import { Instrument, InstrumentType } from 'types/instrument';

import * as Styles from './ActiveInstruments.styles';

export const ActiveInstruments = () => {
  const [activeTab, setActiveTab] = useState<InstrumentType>(
    InstrumentType.PROMISSORY_NOTE
  );
  const [instrumentsView, setInstrumentsView] =
    useState<InstrumentsViewType>('card');
  const [activeInstruments, setActiveInstruments] = useState<Instrument[]>([]);
  const [pendingInstruments, setPendingInstruments] = useState<Instrument[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [hasInstruments, setHasInstruments] = useState<boolean>(false);
  const { exportInstruments } = useExportInstruments();

  const {
    error: pendingPromissoryNotesError,
    instruments: pendingPromissoryNotes,
    isLoading: isLoadingPendingPromissoryNotes,
  } = usePendingPromissoryNotes();

  const {
    error: activePromissoryNotesError,
    instruments: activePromissoryNotes,
    isLoading: isLoadingActivePromissoryNotes,
  } = useActivePromissoryNotes();

  const {
    error: pendingBillsOfExchangeError,
    instruments: pendingBillsOfExchange,
    isLoading: isLoadingPendingBillsOfExchange,
  } = usePendingBillsOfExchange();

  const {
    error: activeBillsOfExchangeError,
    instruments: activeBillsOfExchange,
    isLoading: isLoadingActiveBillsOfExchange,
  } = useActiveBillsOfExchange();

  useEffect(() => {
    switch (activeTab) {
      case InstrumentType.PROMISSORY_NOTE:
        setActiveInstruments(activePromissoryNotes);
        setPendingInstruments(pendingPromissoryNotes);
        setIsLoading(
          isLoadingPendingPromissoryNotes || isLoadingActivePromissoryNotes
        );
        setHasInstruments(
          !isEmpty(pendingPromissoryNotes) || !isEmpty(activePromissoryNotes)
        );
        setError(pendingPromissoryNotesError || activePromissoryNotesError);
        break;
      case InstrumentType.BILL_OF_EXCHANGE:
        setActiveInstruments(activeBillsOfExchange);
        setPendingInstruments(pendingBillsOfExchange);
        setIsLoading(
          isLoadingPendingBillsOfExchange || isLoadingActiveBillsOfExchange
        );
        setHasInstruments(
          !isEmpty(pendingBillsOfExchange) || !isEmpty(activeBillsOfExchange)
        );
        setError(pendingBillsOfExchangeError || activeBillsOfExchangeError);
        break;
    }
  }, [
    activeBillsOfExchange,
    activeBillsOfExchangeError,
    activePromissoryNotes,
    activePromissoryNotesError,
    activeTab,
    isLoadingActiveBillsOfExchange,
    isLoadingActivePromissoryNotes,
    isLoadingPendingBillsOfExchange,
    isLoadingPendingPromissoryNotes,
    pendingBillsOfExchange,
    pendingBillsOfExchangeError,
    pendingPromissoryNotes,
    pendingPromissoryNotesError,
  ]);

  return (
    <>
      <PageHeader>
        <PageHeaderDetails>
          <PageHeaderIcon>
            <AlarmIcon />
          </PageHeaderIcon>
          <PageHeaderTitle>Active Instruments</PageHeaderTitle>
          {hasInstruments && (
            <InstrumentsPageActions
              onExport={() => exportInstruments(activeInstruments)}
              view={instrumentsView}
              onViewChange={setInstrumentsView}
            />
          )}
        </PageHeaderDetails>
      </PageHeader>
      <Styles.TabContainer>
        <Styles.TabItem
          active={activeTab === InstrumentType.PROMISSORY_NOTE}
          onClick={() => setActiveTab(InstrumentType.PROMISSORY_NOTE)}
        >
          {'Promissory Notes'}
        </Styles.TabItem>{' '}
        <Styles.TabItem
          active={activeTab === InstrumentType.BILL_OF_EXCHANGE}
          onClick={() => setActiveTab(InstrumentType.BILL_OF_EXCHANGE)}
        >
          {'Bills of Exchange'}
        </Styles.TabItem>
      </Styles.TabContainer>
      <Styles.PageContent>
        <Styles.TabContent active={true}>
          {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}

          {isLoading && <PageLoader />}

          {!hasInstruments && !isLoading && <NothingToSee />}

          {hasInstruments && (
            <ActiveInstrumentsList
              activeInstruments={activeInstruments}
              pendingInstruments={pendingInstruments}
              instrumentsView={instrumentsView}
            />
          )}
        </Styles.TabContent>
      </Styles.PageContent>
    </>
  );
};
