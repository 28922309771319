import { useQuery } from '@tanstack/react-query';

import { validateWebLink } from 'api/client/authentication';
import { QueryKeys } from 'api/client/types';

export const useValidateWebLink = (encodedWebLink: string) => {
  return useQuery({
    queryKey: [QueryKeys.ValidateWebLink, encodedWebLink],
    queryFn: () => validateWebLink(encodedWebLink),
  });
};
