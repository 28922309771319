import { rem } from 'polished';
import styled from 'styled-components/macro';

import { Spinner as SpinnerComponent } from 'components/Spinner';
import { getTheme } from 'styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Spinner = styled(SpinnerComponent)`
  color: ${getTheme('colors.green.base')};
  font-size: ${rem(32)};
`;
