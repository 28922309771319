import * as yup from 'yup';

const COGNITO_PASSWORD_SPECIAL_CHARACTERS =
  /^$\*\.\[\]{}\(\)?"!@#%&\/,><:;|_~`=+-\s'/;

const BLACKLISTED_PASSWORD_WORDS = ['password', '1234'];

const passwordValidation = yup
  .string()
  .matches(
    new RegExp(
      `^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[${COGNITO_PASSWORD_SPECIAL_CHARACTERS}])[\\da-zA-Z${COGNITO_PASSWORD_SPECIAL_CHARACTERS}]+$`
    ),
    'Password must contain at least one uppercase, lowercase, number and special case character.'
  );

const signUpPasswordValidation = passwordValidation.test(
  'blacklist',
  ({ path }) => `${path} must not contain blacklisted words`,
  (value) => {
    return !BLACKLISTED_PASSWORD_WORDS.some((word) =>
      value?.toLowerCase().includes(word)
    );
  }
);

export const loginPasswordValidation = passwordValidation.min(
  8,
  'Password must be at least 8 characters.'
);
export const userSignUpPasswordValidation = signUpPasswordValidation.min(14);
export const adminSignUpPasswordValidation = signUpPasswordValidation.min(20);
