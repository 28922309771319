import {
  List,
  ListItem,
  ListItemDescription,
  ListItemDetails,
  ListItemLink,
  ListItemTitle,
} from 'components/List';
import { useUser } from 'components/UserProvider';

export const Account = () => {
  const { isAdmin, user } = useUser();

  return (
    <List>
      <ListItem>
        <ListItemLink to="">
          <ListItemDetails>
            <ListItemTitle>Email address</ListItemTitle>
            <ListItemDescription>
              Your email address is {user.email}
            </ListItemDescription>
          </ListItemDetails>
        </ListItemLink>
      </ListItem>
      <ListItem>
        <ListItemLink to="">
          <ListItemDetails>
            <ListItemTitle>Password</ListItemTitle>
          </ListItemDetails>
        </ListItemLink>
      </ListItem>
      <ListItem>
        <ListItemLink to="">
          <ListItemDetails>
            <ListItemTitle>Account type</ListItemTitle>
            <ListItemDescription>
              You are {isAdmin && 'an Administrator'} {!isAdmin && 'a Member'}.
            </ListItemDescription>
          </ListItemDetails>
        </ListItemLink>
      </ListItem>
      <ListItem>
        <ListItemLink to="">
          <ListItemDetails>
            <ListItemTitle>Profile</ListItemTitle>
          </ListItemDetails>
        </ListItemLink>
      </ListItem>
    </List>
  );
};
