import { useEffect } from 'react';

import { useValidateWebLink } from 'api/hooks/useValidateWebLink';
import { Alert } from 'components/Alert';
import { AuthLayout } from 'components/AuthLayout';
import { useMultiStepView } from 'components/MultiStepView';
import { PageLoader } from 'components/PageLoader';
import { getErrorMessage } from 'helpers/error';

import * as SharedStyles from '../styles';
import * as Styles from './ValidateWebLink.styles';

type Props = {
  validateUrl: string;
  setOtpId: (data: string) => void;
  setWebLinkId: (data: string) => void;
  setValidationError: (data: string) => void;
};

export const ValidateWebLink = (props: Props) => {
  const { setOtpId, setValidationError, setWebLinkId, validateUrl } = props;

  const { setView } = useMultiStepView();

  const { data, error, isLoading } = useValidateWebLink(
    encodeURIComponent(validateUrl)
  );

  useEffect(() => {
    if (data && data.isValid && data.responseContent) {
      setOtpId(data.responseContent.otpId);
      setWebLinkId(data.responseContent.webLinkId);
      setView('enter-otp');
    } else if (data && !data.isValid && data.validationErrors) {
      const validationErrors = data.validationErrors.join(', ');

      setValidationError(validationErrors);
      setView('invalid-web-link');
    }
  }, [data, setOtpId, setView, setWebLinkId, setValidationError]);

  return (
    <AuthLayout>
      <SharedStyles.Card>
        <SharedStyles.Title>Validating link</SharedStyles.Title>
        {isLoading && (
          <>
            <Styles.Description>
              {
                "We're currently validating the provided link, this may take a moment."
              }
            </Styles.Description>
            <PageLoader />
          </>
        )}
        {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}
      </SharedStyles.Card>
    </AuthLayout>
  );
};
