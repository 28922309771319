import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteInvestorProviderLink } from 'api/client/dataVisibility';
import { QueryKeys } from 'api/client/types';

export const useDeleteProvider = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteInvestorProviderLink,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.DataVisibility] });
    },
  });
};
