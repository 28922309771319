import capitalize from 'lodash/capitalize';
import { toWords } from 'number-to-words';

import { currencyNames } from './currencies';

const DEFAULT_MONEY_CURRENCY = 'EUR';

const getMoneyMinorPart = (value: number) => {
  return (value * 100) % 100;
};

const isSingularValue = (value: number) => {
  return value % 10 === 1;
};

const formatCurrencyMajor = (currency: string, value: number) => {
  const names = currencyNames[currency];

  if (!names) {
    return '';
  }

  return isSingularValue(value) ? names.majorSingle : names.majorPlural;
};

const formatCurrencyMinor = (currency: string, value: number) => {
  const names = currencyNames[currency];

  if (!names) {
    return '';
  }

  return isSingularValue(value) ? names.minorSingle : names.minorPlural;
};

export const formatMoneyAmount = (
  value: string | number,
  options?: Intl.NumberFormatOptions
) => {
  return Intl.NumberFormat(navigator.language, {
    currency: DEFAULT_MONEY_CURRENCY,
    ...options,
    style: 'currency',
  }).format(Number(value));
};

export const formatMoneyAmountToCompact = (
  value: string | number,
  options?: Intl.NumberFormatOptions
) => {
  return formatMoneyAmount(value, {
    ...options,
    notation: 'compact',
  });
};

export const formatMoneyToWords = (
  value: string | number,
  currency = DEFAULT_MONEY_CURRENCY
) => {
  const valueAsNumber = Number(value);
  const majorPart = Math.trunc(valueAsNumber);
  const minorPart = getMoneyMinorPart(valueAsNumber);
  const formattedMajorPart = `${toWords(majorPart)} ${formatCurrencyMajor(
    currency,
    majorPart
  )}`;
  const formattedMinorPart = `${toWords(minorPart)} ${formatCurrencyMinor(
    currency,
    minorPart
  )}`;

  return `${capitalize(
    formattedMajorPart
  )} and ${formattedMinorPart.toLowerCase()}`;
};

export const getCurrencySymbolByLocale = (currencyLocale: string): string => {
  switch (currencyLocale) {
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    default:
      return '£';
  }
};
