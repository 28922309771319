import { ENDPOINT_OVERRIDE_MAP } from 'api/config/ClientConfiguration';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogText,
  DialogTitle,
} from 'components/Dialog';
import { getErrorMessage } from 'helpers/error';
import { Instrument } from 'types/instrument';

type Props = {
  instrument: Instrument;
  onClose: () => void;
};

export const RetireInstrumentDialog = (props: Props) => {
  const { instrument, onClose } = props;

  const endpointConfig = ENDPOINT_OVERRIDE_MAP[instrument.getInstrumentType()];
  const {
    error: retireInstrumentError,
    isLoading: isRetiringInstrument,
    mutateAsync: retireInstrument,
  } = endpointConfig.useRetireInstrument();

  const onRetire = async () => {
    await retireInstrument(instrument.id);
    onClose();
  };

  return (
    <Dialog>
      <DialogTitle>Retire this {instrument.title}?</DialogTitle>
      {Boolean(retireInstrumentError) && (
        <Alert>{getErrorMessage(retireInstrumentError)}</Alert>
      )}
      <DialogContent>
        <DialogText>Are you sure you want to proceed?</DialogText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          size="large"
          onClick={onClose}
          disabled={isRetiringInstrument}
        >
          Cancel
        </Button>
        <Button
          size="large"
          onClick={onRetire}
          isLoading={isRetiringInstrument}
        >
          Retire
        </Button>
      </DialogActions>
    </Dialog>
  );
};
