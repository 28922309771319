import { ReactNode } from 'react';

import { useMultiStepView } from './MultiStepView';

type Props = {
  id: string;
  children: ReactNode;
};

export const View = (props: Props) => {
  const { children, id } = props;
  const { view } = useMultiStepView();

  if (id !== view) {
    return null;
  }

  return <>{children}</>;
};
