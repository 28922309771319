import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useLogin } from 'api/hooks/useLogin';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { useMultiStepView } from 'components/MultiStepView';
import { getErrorMessage } from 'helpers/error';
import { silentSubmit } from 'helpers/form';

import * as Styles from './EnterVerificationCode.styles';
import * as SharedStyles from './styles';
import { EnterPasswordFormData } from './types';

type FormData = {
  code: string;
};

const validation: yup.ObjectSchema<FormData> = yup
  .object({
    code: yup.string().trim().required(),
  })
  .required();

type Props = {
  enterPasswordFormData: EnterPasswordFormData;
};

export const EnterVerificationCode = (props: Props) => {
  const { enterPasswordFormData } = props;
  const { setView } = useMultiStepView();

  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(validation),
    mode: 'onTouched',
  });

  const { error: loginError, mutateAsync: login } = useLogin();

  const onSubmit = async (data: FormData) => {
    await login({
      ...enterPasswordFormData,
      code: data.code,
    });
  };

  return (
    <>
      {Boolean(loginError) && <Alert>{getErrorMessage(loginError)}</Alert>}
      <SharedStyles.Title>Sign in</SharedStyles.Title>
      <Styles.Description>
        A 6-digit verification code has been sent to{' '}
        {enterPasswordFormData.email}
      </Styles.Description>
      <SharedStyles.Form onSubmit={handleSubmit(silentSubmit(onSubmit))}>
        <SharedStyles.FormControl>
          <SharedStyles.FormLabel htmlFor="verification-code">
            Please enter the verification code here.
          </SharedStyles.FormLabel>
          <Controller
            control={control}
            name="code"
            render={({ field }) => (
              <Styles.CodeInput
                id="verification-code"
                {...omit(field, 'ref')}
              />
            )}
          />
        </SharedStyles.FormControl>
        <Styles.Actions>
          <Button
            type="button"
            size="medium"
            variant="secondary"
            onClick={() => setView('enter-password')}
          >
            Back
          </Button>
          <Button
            type="submit"
            size="medium"
            disabled={!isValid || isSubmitting}
          >
            Submit
          </Button>
        </Styles.Actions>
      </SharedStyles.Form>
    </>
  );
};
