import { rem } from 'polished';
import styled from 'styled-components/macro';

import { Alert } from 'components/Alert';
import { TableCell as TableCellComponent } from 'components/Table';
import { getTheme } from 'styles';

export const TableCell = styled(TableCellComponent)`
  &:first-child {
    padding-left: 0;
  }
`;

export const UserPreview = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: ${getTheme('space.4')};
`;

export const UserSummary = styled.div``;

export const UserName = styled.h6`
  font-size: ${getTheme('fontSizes.xs')};
  font-weight: 400;
  line-height: ${getTheme('lineHeights.3')};
`;

export const UserEmail = styled.p`
  font-size: ${rem(10)};
  line-height: ${getTheme('lineHeights.1')};
  color: ${getTheme('colors.black.50')};
`;

export const ActionsButton = styled.button`
  display: inline-flex;
  font-size: ${rem(24)};
  color: ${getTheme('colors.green.base')};

  &:disabled {
    cursor: auto;
    opacity: ${getTheme('opacity.disabled')};
  }
`;

export const Error = styled(Alert)`
  margin-bottom: 0;
`;
