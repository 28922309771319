import { useMutation } from '@tanstack/react-query';

import { declineBillOfExchange } from 'api/client/billOfExchange';
import { useUpdateBillOfExchangeCache } from 'hooks/BillOfExchange/useUpdateBillOfExchangeCache';

export const useDeclineBillOfExchange = () => {
  const { updateBillOfExchange } = useUpdateBillOfExchangeCache();

  return useMutation({
    mutationFn: declineBillOfExchange,
    onSuccess: (_, id) => {
      updateBillOfExchange(id, { state: 'declined', archived: true });
    },
  });
};
