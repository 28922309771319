import { rem } from 'polished';
import styled from 'styled-components/macro';

import { ArrowLeftIcon } from 'components/Icon';
import { NavLink } from 'components/NavLink';
import { getTheme } from 'styles';

export const BackLink = styled(NavLink)`
  display: inline-flex;
  align-self: flex-start;
  color: ${getTheme('colors.black.70')};
  column-gap: ${rem(6)};
  align-items: center;
  font-size: ${getTheme('fontSizes.sm')};
  font-weight: 600;
`;

export const BackLinkIcon = styled(ArrowLeftIcon)`
  font-size: ${rem(24)};
`;
