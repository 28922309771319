import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { QueryKeys } from 'api/client/types';
import { Member } from 'types/member';

export const useUpdateMemberCache = () => {
  const queryClient = useQueryClient();

  const updateMember = useCallback(
    (id: number, data: Partial<Omit<Member, 'id'>>) => {
      queryClient.setQueryData<Member[]>([QueryKeys.Members], (members) => {
        return members?.map((member) => {
          if (member.id === id) {
            return { ...member, ...data };
          }

          return member;
        });
      });
    },
    [queryClient]
  );

  const invalidateMember = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKeys.Members] });
  }, [queryClient]);

  return {
    updateMember,
    invalidateMember,
  };
};
