import { User } from 'types/user';

import { get } from './client';
import { ListResponse } from './types';

export const getUser = async () => {
  const response = await get('/user');
  const data: ListResponse<User> = await response.json();

  return data.found[0];
};
