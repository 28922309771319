import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addMargin } from 'api/client/margins';
import { QueryKeys } from 'api/client/types';

export const useAddMargin = () => {
  const queryClient = useQueryClient();
  return useMutation({ 
    mutationFn: addMargin,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Margins]})
    }
  });
};
