import { deleteWithAuth, get, post } from './client';
import { InvestorProviderRequest } from './types';

export const getProviders = async (id: string): Promise<string[]> => {
  const response = await get(`/ts-credit-limit/api/v1/provider/investor/${id}`);

  return response.json();
};

export const linkInvestorToProvider = async (
  request: InvestorProviderRequest
) => {
  await post(`/ts-credit-limit/api/v1/provider`, { body: { ...request } });
};

export const deleteInvestorProviderLink = async (
  request: InvestorProviderRequest
) => {
  await deleteWithAuth(`/ts-credit-limit/api/v1/provider/investor`, {
    body: { ...request },
  });
};
