import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Description = styled.p`
  font-size: ${getTheme('fontSizes.base')};
  line-height: ${getTheme('lineHeights.3')};
  font-weight: 500;
  margin-bottom: ${getTheme('space.8')};
`;
