import { addDays, formatISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { DateTime } from 'luxon';

export const parseApiDate = (date: string) => {
  return new Date(date);
};

export const formatDate = (date: Date, includeHours?: boolean | false) => {
  if (includeHours) {
    const patternWithTimezone = 'd LLL yyyy HH:mm:ss z';
    return format(date, patternWithTimezone, { timeZone: 'UTC' });
  }
  const pattern = 'd LLL yyyy';
  return format(date, pattern, { timeZone: 'UTC' });
};

export const convertDateToUTC = (date: Date, timezone = 'Etc/UTC') => {
  const convertedDate = DateTime.fromJSDate(date, { zone: timezone }).toFormat(
    "yyyyMMdd'T'HHmmss'Z'"
  );
  return convertedDate;
};
export const formatToApiDate = (date: Date) => {
  return convertDateToUTC(date);
};

export const formatToISODate = (date: Date) => {
  return formatISO(date, { representation: 'date' });
};
export const convertMaturityToMin = (maturityDate: string) => {
  const newDate = addDays(parseApiDate(maturityDate), 1);
  const convertedUTCDate = convertDateToUTC(newDate);
  return convertedUTCDate;
};

export const formatInputDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};
