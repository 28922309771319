import isEmpty from 'lodash/isEmpty';

import { Instrument } from 'types/instrument';
import { PromissoryNote } from 'types/promissoryNote';

import * as Styles from './InstrumentDetails.styles';

type Props = {
  instrument: Instrument;
};

export const PromissoryNoteSignature = (props: Props) => {
  const { instrument } = props;
  return (
    <>
      {instrument instanceof PromissoryNote &&
        !isEmpty(instrument.signatures) && (
          <>
            <Styles.InstrumentDetailsKey>
              Issuer&apos;s signature
            </Styles.InstrumentDetailsKey>
            <Styles.InstrumentDetailsValue>
              <Styles.Signatures>
                {instrument.signatures?.map((signature, index) => (
                  <Styles.Signature key={index}>
                    <Styles.InstrumentSignature src={signature.signature} />
                  </Styles.Signature>
                ))}
              </Styles.Signatures>
            </Styles.InstrumentDetailsValue>
          </>
        )}
    </>
  );
};
