import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'api/client/types';
import { getUser } from 'api/client/user';

export const useUser = () => {
  return useQuery({
    queryKey: [QueryKeys.Me],
    queryFn: getUser,
  });
};
