import { useMutation } from '@tanstack/react-query';

import { enforcePromissoryNote } from 'api/client/promissoryNote';
import { formatToISODate } from 'helpers/date';
import { useUpdatePromissoryNoteCache } from 'hooks/PromissoryNotes/useUpdatePromissoryNoteCache';

export const useEnforcePromissoryNote = () => {
  const { updatePromissoryNote } = useUpdatePromissoryNoteCache();

  return useMutation({
    mutationFn: enforcePromissoryNote,
    onSuccess: (_, data) => {
      const { enforceableDate, id } = data;

      updatePromissoryNote(id, {
        state: 'enforced',
        archived: true,
        enforceableDate: formatToISODate(enforceableDate),
      });
    },
  });
};
