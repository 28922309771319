import { formatToApiDate } from 'helpers/date';
import { InstrumentHistoryRecord } from 'types/audit';
import { IBillOfExchange } from 'types/billOfExchange';

import { get, post } from './client';
import {
  ApproveBillOfExchangeRequest,
  EnforceInstrumentRequest,
  ListResponse,
  ProtestInstrumentRequest,
} from './types';

export const getBillsOfExchange = async (): Promise<IBillOfExchange[]> => {
  const response = await get('/boe');

  return response.json();
};

export const getBillOfExchangeById = async (
  id: string
): Promise<IBillOfExchange> => {
  const response = await get(`/boe/${id}`);

  return response.json();
};

export const retireBillOfExchange = async (id: string) => {
  await post(`/boe/${id}/retire`);
};

export const approveBillOfExchange = async (
  data: ApproveBillOfExchangeRequest
) => {
  const { disbursementDate, fundsDisbursed, id } = data;
  await post(`/boe/${id}/approve`, {
    body: {
      disbursement_date: disbursementDate,
      funds_disbursed: fundsDisbursed,
    },
  });
};

export const declineBillOfExchange = async (id: string) => {
  await post(`/boe/${id}/decline`);
};

export const markBillOfExchangeAsPaid = async (id: string) => {
  await post(`/boe/${id}/expire`);
};

export const checkOutBillOfExchange = async (id: string) => {
  await post(`/boe/${id}/check-out`);
};

export const protestBillOfExchange = async (data: ProtestInstrumentRequest) => {
  const { id, protestReferenceNumber } = data;

  await post(`/boe/${id}/protest`, {
    body: { protest_number: protestReferenceNumber },
  });
};

export const enforceBillOfExchange = async (data: EnforceInstrumentRequest) => {
  const { enforceableDate, id } = data;

  await post(`/boe/${id}/enforce`, {
    body: { enforceable: formatToApiDate(enforceableDate) },
  });
};

export const getBillOfExchangeHistory = async (id: string) => {
  const response = await get(`/boe/${id}/audit`);
  const data: ListResponse<InstrumentHistoryRecord> = await response.json();

  return data.found;
};
