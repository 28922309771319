import { rem } from 'polished';
import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Container = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${getTheme('colors.navy')};
  color: ${getTheme('colors.white.100')};
  height: ${rem(40)};
  width: ${rem(40)};
  border-radius: ${getTheme('radius.full')};
  font-size: ${getTheme('fontSizes.base')};
  font-weight: 500;
`;
