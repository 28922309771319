import { ENDPOINT_OVERRIDE_MAP } from 'api/config/ClientConfiguration';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogText,
  DialogTitle,
} from 'components/Dialog';
import { getErrorMessage } from 'helpers/error';
import { Instrument } from 'types/instrument';

type Props = {
  instrument: Instrument;
  onClose: () => void;
};

export const CheckOutInstrumentDialog = (props: Props) => {
  const { instrument, onClose } = props;

  const endpointConfig = ENDPOINT_OVERRIDE_MAP[instrument.getInstrumentType()];

  const {
    error: checkOutInstrumentError,
    isLoading: isCheckingOutInstrument,
    mutateAsync: checkOutInstrument,
  } = endpointConfig.useCheckOutInstrument();

  const onCheckOut = async () => {
    await checkOutInstrument(instrument.id);
    onClose();
  };

  return (
    <Dialog>
      <DialogTitle>Are you sure?</DialogTitle>
      {Boolean(checkOutInstrumentError) && (
        <Alert>{getErrorMessage(checkOutInstrumentError)}</Alert>
      )}
      <DialogContent>
        <DialogText>
          Checking out to paper will mean the instrument is no longer tracked in
          Trade Secure. As such, you should now treat this instrument as a paper
          instrument
        </DialogText>
        <DialogText>Are you sure you want to proceed?</DialogText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          size="large"
          onClick={onClose}
          disabled={isCheckingOutInstrument}
        >
          Cancel
        </Button>
        <Button
          size="large"
          onClick={onCheckOut}
          isLoading={isCheckingOutInstrument}
        >
          Check out to paper
        </Button>
      </DialogActions>
    </Dialog>
  );
};
