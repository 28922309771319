import { ReactNode } from 'react';
import { StyledComponentInnerOtherProps } from 'styled-components/macro';

import { DoneIcon, ErrorIcon } from 'components/Icon';

import * as Styles from './Alert.styles';

type Props = Partial<
  StyledComponentInnerOtherProps<typeof Styles.Container>
> & {
  children: ReactNode;
  className?: string;
};

export const Alert = (props: Props) => {
  const { children, className, severity } = props;

  return (
    <Styles.Container
      severity={severity || 'error'}
      role="alert"
      className={className}
    >
      {(() => {
        switch (severity) {
          case 'info':
            return (
              <Styles.Icon>
                <DoneIcon />
              </Styles.Icon>
            );
          case 'error':
            return (
              <Styles.Icon>
                <ErrorIcon />
              </Styles.Icon>
            );
          default:
            return (
              <Styles.Icon>
                <ErrorIcon />
              </Styles.Icon>
            );
        }
      })()}
      <Styles.Text>{children}</Styles.Text>
    </Styles.Container>
  );
};
