import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Page = styled.section`
  flex: 1;
  border: 1px solid ${getTheme('colors.greyBorder')};
  border-radius: ${getTheme('radius.lg')};
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${getTheme('space.6')};
`;

export const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${getTheme('space.1')};
`;

export const Title = styled.h2`
  font-size: ${getTheme('fontSizes.base')};
  font-weight: 600;
  line-height: ${getTheme('lineHeights.3')};
`;

export const Description = styled.p`
  font-size: ${getTheme('fontSizes.sm')};
  color: ${getTheme('colors.black.50')};
  line-height: ${getTheme('lineHeights.3')};
`;

export const Content = styled.div`
  padding: ${getTheme('space.6')};
  padding-top: 0;
`;
