export type ApiErrorData = { message: string } | string;

const getErrorMessage = (data: ApiErrorData) => {
  if (typeof data === 'string') {
    return data;
  }

  return data.message;
};

export class ApiError extends Error {
  constructor(public status: number, public data: ApiErrorData) {
    super(getErrorMessage(data));
  }
}
