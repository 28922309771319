import { yupResolver } from '@hookform/resolvers/yup';
import { currencyOptions } from 'data/currency';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CreditLimitRequest } from 'api/client/types';
import { useAddCreditLimit } from 'api/hooks/useAddCreditLimit';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Dialog, DialogActions, DialogTitle } from 'components/Dialog';
import { DropDownMenu } from 'components/DropDownMenu';
import { getErrorMessage } from 'helpers/error';
import { BuyerCompany } from 'types/financing';

import {
  BoldFormInput,
  Content,
  FormItem,
  FormLabel,
} from './AddCreditLimitDialog.styles';

type Props = {
  buyerCompanies: BuyerCompany[];
  id: string;
  onClose: () => void;
};

const creditLimitSchema = yup.object().shape({
  buyerId: yup.string().required('Buyer is required'),
  buyerCompanyName: yup
    .string()
    .max(250, 'Buyer Company Name must have length less than 250')
    .required(),
  creditLimit: yup
    .number()
    .typeError('Credit Limit is required')
    .required('Credit Limit is required')
    .min(0, 'Credit Limit must be positive number')
    .max(2147483647, 'Credit Limit is too large. Please enter a smaller value.')
    .test(
      'maxDecimals',
      'Credit Limit must have up to two decimal places',
      (number) => {
        if (number == null) {
          return true;
        }
        return /^\d+(\.\d{0,2})?$/.test(number.toString());
      }
    ),
  currency: yup
    .string()
    .oneOf(
      currencyOptions.map((currency) => currency.id),
      'Currency is required'
    )
    .required('Currency is required'),
  effectiveDate: yup
    .date()
    .required('Effective Date is required')
    .typeError('Effective Date is required')
    .min(new Date(), 'Effective Date must be in the future'),
});

export const AddCreditLimitDialog = (props: Props) => {
  const { buyerCompanies, id, onClose } = props;

  const {
    error,
    isLoading,
    isSuccess,
    mutateAsync: addCreditLimit,
  } = useAddCreditLimit();

  const {
    formState: { errors, isSubmitting, isValid },
    formState: { touchedFields },
    handleSubmit,
    register,
    resetField,
    setError,
    setValue,
    trigger,
  } = useForm<CreditLimitRequest>({
    resolver: yupResolver(creditLimitSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const handleSelectBuyer = (buyerId: string) => {
    const buyer = buyerCompanies.find((buyer) => buyer.buyer_id === buyerId);
    if (buyer) {
      setValue('buyerId', buyer.buyer_id, {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue('buyerCompanyName', buyer.trading_name, {
        shouldValidate: true,
      });
    } else {
      resetField('buyerId', { keepTouched: true });
      setValue('buyerCompanyName', '');
      setError('buyerId', { type: 'required', message: 'Buyer is required' });
    }
  };

  const getFutureDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  };

  const onSubmit = async (data: CreditLimitRequest) => {
    const creditLimit: CreditLimitRequest = {
      buyerId: data.buyerId,
      investorId: id,
      buyerCompanyName: data.buyerCompanyName,
      creditLimit: data.creditLimit,
      currency: data.currency,
      effectiveDate: data.effectiveDate,
    };
    await addCreditLimit(creditLimit);
    onClose();
  };

  return (
    <Dialog>
      <DialogTitle>Set Buyer Credit Limit</DialogTitle>
      {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}
      {isSuccess && (
        <Alert severity={'info'}>{'Credit Limit added successfully'}</Alert>
      )}
      <Content>
        <FormItem>
          <FormLabel>Buyer Company</FormLabel>
          <DropDownMenu
            placeholder="Select a Buyer"
            options={buyerCompanies}
            valueKey="buyer_id"
            labelKey="trading_name"
            {...register('buyerId')}
            onChange={(value) => handleSelectBuyer(value)}
          />
        </FormItem>
        <FormItem>
          <FormLabel>Credit Limit Value</FormLabel>
          <BoldFormInput
            type="number"
            min={0}
            step={0.01}
            placeholder="Enter a Credit Limit"
            {...register('creditLimit')}
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
          />
        </FormItem>
        <FormItem>
          <FormLabel>Currency</FormLabel>
          <DropDownMenu
            placeholder="Select Currency"
            options={currencyOptions}
            valueKey="id"
            labelKey="name"
            onChange={(value) => {
              setValue('currency', value, {
                shouldValidate: true,
                shouldTouch: true,
              });
            }}
          />
        </FormItem>
        <FormItem>
          <FormLabel>Effective Date</FormLabel>
          <BoldFormInput
            type="date"
            min={getFutureDate()}
            {...register('effectiveDate')}
          />
        </FormItem>
      </Content>
      {!(Object.keys(touchedFields).length === 0) && errors.buyerId && (
        <Alert>{errors.buyerId.message}</Alert>
      )}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.buyerId &&
        errors.creditLimit && <Alert>{errors.creditLimit.message}</Alert>}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.buyerId &&
        !errors.creditLimit &&
        errors.currency && <Alert>{errors.currency.message}</Alert>}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.buyerId &&
        !errors.creditLimit &&
        !errors.currency &&
        errors.effectiveDate && <Alert>{errors.effectiveDate.message}</Alert>}
      <DialogActions>
        <Button variant="secondary" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading || isSubmitting}
          disabled={!isValid || isLoading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
