import { rem } from 'polished';
import styled, { css } from 'styled-components/macro';

import { getTheme } from 'styles';

type TabProps = {
  active: boolean;
};

export const TabContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 ${getTheme('space.6')} 0 ${getTheme('space.6')};
`;

export const TabItem = styled.div<TabProps>`
  padding: ${getTheme('space.4')} 0;
  cursor: pointer;
  font-size: ${getTheme('fontSizes.lg')};

  font-weight: ${(props) => (props.active ? '500' : '400')};
  margin-right: ${getTheme('space.6')};
  border-bottom: 3px solid
    ${(props) =>
      props.active ? getTheme('colors.green.light01') : 'transparent'};
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getTheme('space.6')};
  max-width: ${rem(1590)};
  flex: 1;
`;

export const TabContent = styled.div<TabProps>`
  display: none;
  justify-content: center;
  border: 1px solid ${getTheme('colors.greyBorder')};
  border-radius: ${getTheme('radius.lg')};
  padding: ${getTheme('space.2')};
  border-radius: ${getTheme('radius.md')};
  transition-duration: 1s;
  min-height: 100%;
  ${(props) =>
    props.active &&
    css`
      display: flex;
    `}
`;
