import { ENDPOINT_OVERRIDE_MAP } from 'api/config/ClientConfiguration';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogText,
  DialogTitle,
} from 'components/Dialog';
import { getErrorMessage } from 'helpers/error';
import { Instrument } from 'types/instrument';

type Props = {
  onClose: () => void;
  instrument: Instrument;
  referenceNumber: string;
};

export const ProtestInstrumentDialog = (props: Props) => {
  const { instrument, onClose, referenceNumber } = props;

  const endpointConfig = ENDPOINT_OVERRIDE_MAP[instrument.getInstrumentType()];

  const {
    error: protestInstrumentError,
    isLoading: isProtestingInstrument,
    mutateAsync: protestInstrument,
  } = endpointConfig.useProtestInstrument();

  const onConfirm = async () => {
    await protestInstrument({
      id: instrument.id,
      protestReferenceNumber: referenceNumber,
    });
    onClose();
  };

  return (
    <Dialog>
      <DialogTitle>Protest this {instrument.title}?</DialogTitle>
      {Boolean(protestInstrumentError) && (
        <Alert>{getErrorMessage(protestInstrumentError)}</Alert>
      )}
      <DialogContent>
        <DialogText>
          By clicking &apos;Confirm&apos;, you are protesting this{' '}
          {instrument.title} with Protest Reference Number{' '}
          <strong>{referenceNumber}</strong>.
        </DialogText>
        <DialogText>Are you sure you want to proceed?</DialogText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          size="large"
          onClick={onClose}
          disabled={isProtestingInstrument}
        >
          Cancel
        </Button>
        <Button
          size="large"
          onClick={onConfirm}
          isLoading={isProtestingInstrument}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
