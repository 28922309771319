import { deleteWithAuth, get, post } from './client';
import { MarginRequest, MarginResponse } from './types';

export const getMargins = async (
  id: string
): Promise<MarginResponse> => {
  const response = await get(
    `/ts-credit-limit/api/v1/margins/investor/${id}`
  );
  return response.json();
};

export const addMargin = async (
  request: MarginRequest
) => {
  await post(`/ts-credit-limit/api/v1/margins`, { body: { ...request } });
};

export const deleteMargin = async (
  id: string
) => {
  await deleteWithAuth(`/ts-credit-limit/api/v1/margins/${id}`);
}