import pluralize from 'pluralize';

import { formatMoneyAmountToCompact } from 'helpers/money';

import * as Styles from './InstrumentsSummary.styles';
import { InstrumentsSummary as InstrumentsSummaryType } from './useInstrumentsSummary';

type Props = {
  summary: InstrumentsSummaryType;
};

export const InstrumentsSummary = (props: Props) => {
  const { numberOfBuyers, numberOfInstruments, totalAmount } = props.summary;

  return (
    <Styles.Stats>
      <Styles.Stat>
        <Styles.Stat>{numberOfInstruments} DNI</Styles.Stat>
      </Styles.Stat>
      <Styles.Stat>{formatMoneyAmountToCompact(totalAmount)}</Styles.Stat>
      <Styles.Stat>
        {numberOfBuyers} {pluralize('Buyer', numberOfBuyers)}
      </Styles.Stat>
    </Styles.Stats>
  );
};
