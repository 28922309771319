import { NoDataIcon } from 'components/Icon';

import * as Styles from './NothingToSee.styles';

export const NothingToSee = () => {
  return (
    <Styles.Container>
      <Styles.Icon>
        <NoDataIcon />
      </Styles.Icon>
      <Styles.Title>Nothing to see here at the moment...</Styles.Title>
    </Styles.Container>
  );
};
