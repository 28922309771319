import { useDeleteMargin } from 'api/hooks/useDeleteMargin';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogText,
  DialogTitle,
} from 'components/Dialog';
import { getErrorMessage } from 'helpers/error';

import * as Styles from './DeleteMarginsDialog.styles';

type Props = {
  id: string;
  onClose: () => void;
};

export const DeleteMarginsDialog = (props: Props) => {
  const {
    id,
    onClose,
  } = props;

  const {
    error,
    isLoading,
    isSuccess,
    mutateAsync: deleteMargin,
  } = useDeleteMargin();

  const onAccept = async () => {
    await deleteMargin(id);
  };

  return (
    <Dialog>
      <DialogTitle>Delete this Margin?</DialogTitle>
      {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}
      <Styles.Content>
        <DialogText>Are you sure you want to proceed?</DialogText>
      </Styles.Content>
      {isSuccess && (
        <Alert severity={'info'}>
          {'Margin has been removed'}
        </Alert>
      )}
      <DialogActions>
        <Button
          variant="secondary"
          size="large"
          onClick={onClose}
          disabled={isLoading}
        >
          Close
        </Button>
        <Button
          size="large"
          onClick={onAccept}
          isLoading={isLoading}
          disabled={isLoading || isSuccess || Boolean(error)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
