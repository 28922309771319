import { useQuery } from '@tanstack/react-query';

import { getDigitalTwin } from 'api/client/digitalTwin';
import { QueryKeys } from 'api/client/types';

export const useDigitalTwin = (id: string) => {
  return useQuery({
    queryKey: [QueryKeys.DigitalTwin, id],
    queryFn: () => getDigitalTwin(id),
  });
};
