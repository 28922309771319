import { useMutation } from '@tanstack/react-query';

import { protestBillOfExchange } from 'api/client/billOfExchange';
import { useUpdateBillOfExchangeCache } from 'hooks/BillOfExchange/useUpdateBillOfExchangeCache';

export const useProtestBillOfExchange = () => {
  const { updateBillOfExchange } = useUpdateBillOfExchangeCache();

  return useMutation({
    mutationFn: protestBillOfExchange,
    onSuccess: (_, data) => {
      const { id, protestReferenceNumber } = data;

      updateBillOfExchange(id, {
        state: 'protested',
        archived: false,
        protestNumber: protestReferenceNumber,
      });
    },
  });
};
