import range from 'lodash/range';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';

import { AcceptBillOfExchangeDialog } from 'components/AcceptBillOfExchangeDialog';
import { Alert } from 'components/Alert';
import { NoteIcon } from 'components/Icon';
import { HoverOverlay } from 'components/Overlay';
import { PageContent } from 'components/PageContent';
import { PageHeaderDetails, PageHeaderIcon } from 'components/PageHeader';
import { RejectBillOfExchangeDialog } from 'components/RejectBillOfExchangeDialog';

import { FetchedInstrument } from '../types';
import * as Styles from './ViewInstrument.styles';

type Props = {
  fetchedInstrument?: FetchedInstrument;
  webLinkId: string;
};

export const ViewInstrument = (props: Props) => {
  const { fetchedInstrument, webLinkId } = props;
  const [
    isRejectBillOfExchangeDialogDisplayed,
    setIsRejectBillOfExchangeDialogDisplayed,
  ] = useState(false);
  const [
    isAcceptBillOfExchangeDialogDisplayed,
    setIsAcceptInstrumentDialogDisplayed,
  ] = useState(false);
  const [isActionable, setIsActionable] = useState(true);

  const [numberOfPages, setNumberOfPages] = useState(0);

  const onLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setNumberOfPages(pdf.numPages);
  };

  const handleIsActionable = (bool: boolean) => {
    setIsActionable(bool);
  };

  useEffect(() => {
    if (
      (fetchedInstrument &&
        fetchedInstrument.billOfExchange.state !== 'minted') ||
      !fetchedInstrument
    ) {
      setIsActionable(false);
    }
  }, [fetchedInstrument, setIsActionable]);

  return (
    <>
      <Styles.Page>
        <Styles.Body>
          {fetchedInstrument && fetchedInstrument.billOfExchange && (
            <>
              <Styles.PageHeader>
                <PageHeaderDetails>
                  <PageHeaderIcon>
                    <NoteIcon />
                  </PageHeaderIcon>
                  <Styles.PageHeaderTitle>
                    {fetchedInstrument.billOfExchange.title}
                  </Styles.PageHeaderTitle>
                </PageHeaderDetails>
              </Styles.PageHeader>

              <PageContent>
                <Styles.PdfContainer>
                  <Styles.Preview>
                    <Document
                      file={fetchedInstrument.billOfExchange.urlToDboe}
                      loading={<Styles.Loader />}
                      onLoadSuccess={onLoadSuccess}
                    >
                      {range(numberOfPages).map((pageNumber) => (
                        <Page
                          key={pageNumber}
                          pageNumber={pageNumber + 1}
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                        />
                      ))}
                    </Document>
                  </Styles.Preview>
                </Styles.PdfContainer>
              </PageContent>
            </>
          )}
          {!fetchedInstrument && (
            <Styles.AlertContainer>
              <Alert>{'Instrument not found'}</Alert>
            </Styles.AlertContainer>
          )}
        </Styles.Body>

        <Styles.PageFooter>
          <HoverOverlay
            disabled={
              (fetchedInstrument &&
                fetchedInstrument.billOfExchange.state === 'minted') ||
              !fetchedInstrument ||
              (!isActionable &&
                fetchedInstrument.billOfExchange.state === 'minted')
            }
            closeOnOverlayClick
            content={
              <Alert>
                {'Instrument is not in a state which can be rejected'}
              </Alert>
            }
          >
            <Styles.CardPreview>
              <Styles.ActionButton
                size="large"
                variant="secondary"
                disabled={!isActionable}
                onClick={() => setIsRejectBillOfExchangeDialogDisplayed(true)}
              >
                Reject
              </Styles.ActionButton>
            </Styles.CardPreview>
          </HoverOverlay>

          <HoverOverlay
            disabled={
              (fetchedInstrument &&
                fetchedInstrument.billOfExchange.state === 'minted') ||
              !fetchedInstrument ||
              (!isActionable &&
                fetchedInstrument.billOfExchange.state === 'minted')
            }
            closeOnOverlayClick
            content={
              <Alert>
                {'Instrument is not in a state which can be accepted'}
              </Alert>
            }
          >
            <Styles.CardPreview>
              <Styles.ActionButton
                size="large"
                disabled={!isActionable}
                onClick={() => setIsAcceptInstrumentDialogDisplayed(true)}
              >
                Accept
              </Styles.ActionButton>
            </Styles.CardPreview>
          </HoverOverlay>
        </Styles.PageFooter>
      </Styles.Page>

      {fetchedInstrument && fetchedInstrument.billOfExchange && (
        <>
          {isAcceptBillOfExchangeDialogDisplayed && (
            <AcceptBillOfExchangeDialog
              onClose={() => setIsAcceptInstrumentDialogDisplayed(false)}
              id={fetchedInstrument.billOfExchange.id}
              accessToken={fetchedInstrument.accessToken}
              buyerContact={fetchedInstrument.buyerContact}
              webLinkId={webLinkId}
              onAction={handleIsActionable}
            />
          )}

          {isRejectBillOfExchangeDialogDisplayed && (
            <RejectBillOfExchangeDialog
              onClose={() => setIsRejectBillOfExchangeDialogDisplayed(false)}
              id={fetchedInstrument.billOfExchange.id}
              accessToken={fetchedInstrument.accessToken}
              buyerContact={fetchedInstrument.buyerContact}
              webLinkId={webLinkId}
              onAction={handleIsActionable}
            />
          )}
        </>
      )}
    </>
  );
};
