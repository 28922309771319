import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useLogin } from 'api/hooks/useLogin';
import { Alert } from 'components/Alert';
import { FormInput } from 'components/Form';
import { useMultiStepView } from 'components/MultiStepView';
import { NavLink } from 'components/NavLink';
import { getErrorMessage } from 'helpers/error';
import { silentSubmit } from 'helpers/form';
import { loginPasswordValidation } from 'helpers/validations';

import * as Styles from './EnterPassword.styles';
import * as SharedStyles from './styles';
import { EnterPasswordFormData } from './types';

const validation: yup.ObjectSchema<EnterPasswordFormData> = yup
  .object({
    email: yup.string().email().required(),
    password: loginPasswordValidation.required(),
  })
  .required();

type Props = {
  initialFormData?: EnterPasswordFormData;
  onSuccess: (data: EnterPasswordFormData) => void;
};

export const EnterPassword = (props: Props) => {
  const { setView } = useMultiStepView();
  const { initialFormData, onSuccess } = props;
  const {
    formState: { isSubmitSuccessful, isSubmitting, isValid },
    handleSubmit,
    register,
    watch,
  } = useForm<EnterPasswordFormData>({
    resolver: yupResolver(validation),
    defaultValues: initialFormData,
  });

  const { error: loginError, isSuccess, mutateAsync: login } = useLogin();

  const data = watch();

  useEffect(() => {
    if (isSubmitSuccessful && isSuccess) {
      onSuccess(data);
      setView('enter-verification-code');
    }
  }, [isSubmitSuccessful, setView, onSuccess, data, isSuccess]);

  const onSubmit = (data: EnterPasswordFormData) => login(data);

  return (
    <>
      {Boolean(loginError) && <Alert>{getErrorMessage(loginError)}</Alert>}
      <SharedStyles.Title>Sign in</SharedStyles.Title>
      <SharedStyles.Form onSubmit={handleSubmit(silentSubmit(onSubmit))}>
        <SharedStyles.FormControl>
          <SharedStyles.FormLabel htmlFor="email">Email</SharedStyles.FormLabel>
          <FormInput
            type="email"
            id="email"
            placeholder="Your email address"
            autoComplete="email"
            autoFocus
            size="medium"
            {...register('email')}
          />
        </SharedStyles.FormControl>

        <SharedStyles.FormControl>
          <SharedStyles.FormLabel htmlFor="password">
            Password
          </SharedStyles.FormLabel>
          <FormInput
            type="password"
            id="password"
            placeholder="Your password"
            autoComplete="current-password"
            size="medium"
            {...register('password')}
          />
        </SharedStyles.FormControl>

        <Styles.LoginButton
          fullWidth
          size="large"
          disabled={!isValid}
          isLoading={isSubmitting}
        >
          Log in
        </Styles.LoginButton>

        <Styles.ForgotPassword>
          <NavLink to="/forgot-password">Forgot your password?</NavLink>
        </Styles.ForgotPassword>
      </SharedStyles.Form>

      {/* TODO: Re-enable registration back whenever it's needed */}
      {/* <Styles.SignUpDescription>
        New to TradeSecure&reg;? <NavLink to="/sign-up">Get started</NavLink>
      </Styles.SignUpDescription> */}
    </>
  );
};
