import { yupResolver } from '@hookform/resolvers/yup';
import { parse } from 'date-fns';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useApproveBillOfExchange } from 'api/hooks/BillOfExchange/useApproveBillOfExchange';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Dialog, DialogContent, DialogTitle } from 'components/Dialog';
import { FormInput } from 'components/Form';
import {
  formatDate,
  formatInputDate,
  formatToApiDate,
  parseApiDate,
} from 'helpers/date';
import { getErrorMessage } from 'helpers/error';
import { silentSubmit } from 'helpers/form';
import { formatMoneyAmount, getCurrencySymbolByLocale } from 'helpers/money';

import * as Styles from './ApproveDBoEDialog.styles';

type Props = {
  instrumentId: string;
  amount: string;
  maturity: string;
  issued: string;
  currency: string;
  onClose: () => void;
};

type FormData = {
  fundsDisbursed: string;
  disbursementDate: string;
};

const constructMidnightUTCDate = (date: Date): Date => {
  // We POST to API a disbursement_date at midnight UTC of the date entered (No timezone offset calculations should be performed)

  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
};

export const ApproveDBoEDialog = (props: Props) => {
  const { amount, currency, instrumentId, issued, maturity, onClose } = props;
  const MINIMUM_DISBURSEMENT_DATE = new Date(parseApiDate(issued));
  MINIMUM_DISBURSEMENT_DATE.setDate(MINIMUM_DISBURSEMENT_DATE.getDate() + 1);
  const minDisbursmentDateString = formatInputDate(MINIMUM_DISBURSEMENT_DATE);

  const MAXIMUM_DISBURSEMENT_DATE = new Date(parseApiDate(maturity));
  MAXIMUM_DISBURSEMENT_DATE.setDate(MAXIMUM_DISBURSEMENT_DATE.getDate() - 1);
  const mmaxDisbursmentDateString = formatInputDate(MAXIMUM_DISBURSEMENT_DATE);

  const validation: yup.ObjectSchema<FormData> = yup
    .object({
      fundsDisbursed: yup
        .string()
        .required('Funds disbursed is required')
        .matches(
          /^\d+(?:\.\d{0,2})?$/,
          'The funds disbursed must be a positive whole number with zero or two decimal points.'
        )
        .test(
          'lessThanAmount',
          'Funds disbursed must be less than total amount.',
          function (value) {
            const result = Number(value) < Number(amount);
            return result;
          }
        ),
      disbursementDate: yup
        .string()
        .required('Disbursement date is required')
        .test(
          'dateBetween',
          `Date must be after ${formatDate(
            constructMidnightUTCDate(parseApiDate(issued))
          )} UTC and before ${formatDate(
            constructMidnightUTCDate(parseApiDate(maturity))
          )} UTC.`,
          function (value) {
            const maturityDate = parseApiDate(maturity);
            const issuedDate = parseApiDate(issued);
            const disbursementDate = parse(value, 'yyyy-MM-dd', new Date());

            const apiDate = constructMidnightUTCDate(disbursementDate);

            const result = apiDate > issuedDate && apiDate < maturityDate;
            return result;
          }
        ),
    })
    .required();

  const {
    formState: { errors, isSubmitSuccessful, isSubmitting, isValid },
    handleSubmit,
    register,
  } = useForm<FormData>({
    resolver: yupResolver(validation),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    error: approveInstrumentError,
    isLoading: isApprovingInstrument,
    mutateAsync: approveInstrument,
  } = useApproveBillOfExchange();

  const onApprove = async (data: FormData) => {
    const { disbursementDate, fundsDisbursed } = data;
    const disbursementDateWithTimezone = parse(
      disbursementDate,
      'yyyy-MM-dd',
      new Date()
    );
    const formattedDisbursementDate = formatToApiDate(
      constructMidnightUTCDate(disbursementDateWithTimezone)
    );

    const fundsDisbursedNumber = Number(fundsDisbursed);
    await approveInstrument({
      id: instrumentId,
      disbursementDate: formattedDisbursementDate,
      fundsDisbursed: fundsDisbursedNumber,
    });
    onClose();
  };

  const hasValidMaturity = () => {
    if (maturity) {
      const maturityDate = parseApiDate(maturity);
      const now = new Date();
      return maturityDate >= now;
    }
    return false;
  };
  const isValidMaturity = hasValidMaturity();

  return (
    <Dialog>
      <DialogTitle>Approve this Bill of Exchange?</DialogTitle>
      {Boolean(approveInstrumentError) && (
        <Alert>{getErrorMessage(approveInstrumentError)}</Alert>
      )}

      <Styles.Form onSubmit={handleSubmit(silentSubmit(onApprove))}>
        <DialogContent>
          <Styles.Description>
            Approving this Bill of Exchange means that you will be assuming an
            investment of {formatMoneyAmount(amount, { currency })}
          </Styles.Description>
          <Styles.FormControl>
            <Styles.FormLabel htmlFor="disbursement-date">
              Please provide the instrument&apos;s disbursement date:
            </Styles.FormLabel>
            <Styles.InputContainer>
              <Styles.DisbursementDateInput
                type="date"
                size="medium"
                id="disbursement-date"
                placeholder={'dd/mm/yyyy'}
                min={minDisbursmentDateString}
                max={mmaxDisbursmentDateString}
                {...register('disbursementDate')}
              />
              <Styles.InputLabel>{'UTC'}</Styles.InputLabel>
            </Styles.InputContainer>
          </Styles.FormControl>
          <Styles.FormControl>
            <Styles.FormLabel htmlFor="funds-disbursed">
              Please provide the amount of funds disbursed:
            </Styles.FormLabel>
            <Styles.InputContainer>
              <Styles.InputLabel htmlFor="currency-disbursed">
                {getCurrencySymbolByLocale(currency)}
              </Styles.InputLabel>
              <FormInput
                id="funds-disbursed"
                autoComplete="funds-disbursed"
                placeholder={'0.00'}
                size="medium"
                {...register('fundsDisbursed')}
              />
            </Styles.InputContainer>
          </Styles.FormControl>
          {!isValidMaturity && (
            <Alert>
              This instrument cannot be approved as the maturity date has
              passed.
            </Alert>
          )}
          {isValidMaturity && Boolean(errors.disbursementDate) && (
            <Alert>{getErrorMessage(errors.disbursementDate?.message)}</Alert>
          )}
          {isValidMaturity && Boolean(errors.fundsDisbursed) && (
            <Alert>{getErrorMessage(errors.fundsDisbursed?.message)}</Alert>
          )}
        </DialogContent>
        {isSubmitSuccessful && (
          <Alert severity={'info'}>
            {'Success! Bill of Exchange has been approved'}
          </Alert>
        )}
        <Styles.Actions>
          <Button
            type="button"
            size="medium"
            variant="secondary"
            onClick={onClose}
          >
            Back
          </Button>
          <Button
            type="submit"
            size="medium"
            isLoading={isApprovingInstrument || isSubmitting}
            disabled={!isValid || isSubmitting || !isValidMaturity}
          >
            Approve
          </Button>
        </Styles.Actions>
      </Styles.Form>
    </Dialog>
  );
};
