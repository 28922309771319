import { NoDataIcon } from 'components/Icon';

import * as Styles from './NothingToSeeHere.styles';

export const NothingToSeeHere = () => {
  return (
    <Styles.Container>
      <Styles.Icon>
        <NoDataIcon />
      </Styles.Icon>
      <Styles.Title>Instrument cannot be found.</Styles.Title>
    </Styles.Container>
  );
};
