import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addCreditLimit } from 'api/client/creditLimits';
import { QueryKeys } from 'api/client/types';

export const useAddCreditLimit = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addCreditLimit,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.CreditLimits] });
    },
  });
};
