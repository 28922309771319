import { deleteWithAuth, get, post } from './client';
import { CreditLimitRequest, CreditLimitResponse } from './types';

export const getCreditLimits = async (
  id: string
): Promise<CreditLimitResponse> => {
  const response = await get(
    `/ts-credit-limit/api/v1/credit-limit/investor/${id}`
  );
  return response.json();
};

export const addCreditLimit = async (request: CreditLimitRequest) => {
  await post(`/ts-credit-limit/api/v1/credit-limit`, { body: { ...request } });
};

export const deleteCreditLimit = async (id: string) => {
  await deleteWithAuth(`/ts-credit-limit/api/v1/credit-limit/${id}`);
};
