import { useMutation } from '@tanstack/react-query';

import { rejectPromissoryNote } from 'api/client/promissoryNote';
import { useUpdatePromissoryNoteCache } from 'hooks/PromissoryNotes/useUpdatePromissoryNoteCache';

export const useRejectPromissoryNote = () => {
  const { updatePromissoryNote } = useUpdatePromissoryNoteCache();

  return useMutation({
    mutationFn: rejectPromissoryNote,
    onSuccess: (_, id) => {
      updatePromissoryNote(id, { state: 'rejected', archived: true });
    },
  });
};
