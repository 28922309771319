import { ComponentProps, forwardRef } from 'react';

import { InputErrorIcon, InputSuccessIcon, LockIcon } from 'components/Icon';
import { Input, InputIcon } from 'components/Input';

import * as Styles from './FormInput.styles';

type Props = Omit<ComponentProps<typeof Input>, 'isInvalid'> & {
  touched?: boolean;
  error?: boolean;
};

export const FormInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { disabled, error, touched } = props;

  const endAdornment = (
    <>
      {disabled && (
        <InputIcon>
          <LockIcon />
        </InputIcon>
      )}

      {!disabled && touched && (
        <>
          {error && (
            <Styles.InvalidIcon>
              <InputErrorIcon />
            </Styles.InvalidIcon>
          )}

          {!error && (
            <Styles.ValidIcon>
              <InputSuccessIcon />
            </Styles.ValidIcon>
          )}
        </>
      )}
    </>
  );

  return (
    <Input ref={ref} {...props} endAdornment={endAdornment} isInvalid={error} />
  );
});

FormInput.displayName = 'FormInput';
