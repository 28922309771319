import { rem } from 'polished';
import styled from 'styled-components/macro';

import { Alert } from 'components/Alert';
import { PageHeaderDetails } from 'components/PageHeader';
import { Spinner } from 'components/Spinner';
import { getTheme } from 'styles';

import TradeSecureImage from './assets/trade-secure.png';

export const AuditContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${getTheme('radius.lg')};
  border: 1px;
  background-color: ${getTheme('colors.white.100')};
  height: fit-content;
  max-width: 80%;
  overflow: auto;
`;

export const Background = styled.div`
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-rows: auto 1fr;
  background-color: ${getTheme('colors.green.dark04')};
  background-size: cover;
  overflow: auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  @media (min-width: 1481px) {
    flex-direction: row;
  }
`;

export const Card = styled.div`
  max-width: ${rem(448)};
  background-color: ${getTheme('colors.white.100')};
  border: 1px solid ${getTheme('colors.greyBorder')};
  border-radius: ${getTheme('radius.lg')};
  padding: ${getTheme('space.4')};
`;

export const Content = styled.main`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomPageHeaderDetails = styled(PageHeaderDetails)`
  margin-top: 0;
`;

export const Error = styled(Alert)`
  margin-bottom: 0;
`;

export const Header = styled.div`
  display: grid;
  min-height: ${rem(140)};
  background-color: ${getTheme('colors.green.dark04')};

  &::after {
    content: '';
    background-image: url(${TradeSecureImage});
    background-repeat: no-repeat;
    background-position: left;
    background-size: ${rem(257)};
    margin-left: ${rem(25)};
  }
`;

export const HistoryContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${getTheme('space.6')};

  @media (min-width: 1481px) {
    width: 40%;
    justify-content: flex-start;
  }
`;

export const Loader = styled(Spinner)`
  color: ${getTheme('colors.green.base')};
  font-size: ${rem(32)};
  margin: ${getTheme('space.5')};
`;

export const PdfContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${getTheme('space.6')};

  @media (min-width: 1481px) {
    width: 60%;
    justify-content: flex-end;
  }
`;

export const Page = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
  overflow-y: scroll;
`;

export const Preview = styled.div`
  overflow: auto;

  .react-pdf__Page__canvas {
    min-width: 50%;
  }
`;
