import { BaseSyntheticEvent } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';

export const silentSubmit =
  <TFieldValues extends FieldValues>(onSubmit: SubmitHandler<TFieldValues>) =>
  async (data: TFieldValues, event?: BaseSyntheticEvent) => {
    try {
      await onSubmit(data, event);
    } catch {
      /* empty */
    }
  };
