import { rem } from 'polished';
import styled, { css } from 'styled-components/macro';

import { Button } from 'components/Button';
import { NavLink } from 'components/NavLink';
import { getTheme } from 'styles';

import TradeSecureImage from './assets/trade-secure.png';

export const Sidebar = styled.aside`
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr;
  width: ${rem(330)};
  background-color: ${getTheme('colors.green.dark04')};
  overflow-y: auto;
`;

export const Header = styled.div`
  display: grid;
  min-height: ${rem(140)};
  background-color: ${getTheme('colors.green.dark03')};

  &::after {
    content: '';
    background-image: url(${TradeSecureImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${rem(257)};
  }
`;

export const Title = styled.h2`
  font-size: ${rem(15)};
  font-weight: 400;
  color: ${getTheme('colors.white.100')};
  padding: ${getTheme('space.5')} 0 ${getTheme('space.3')};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${getTheme('space.4')};
`;

export const ContentSection = styled.div`
  padding-bottom: ${getTheme('space.3')};
`;

export const ToolsSection = styled(ContentSection)`
  @media (max-height: ${rem(730)}) {
    display: none;
  }
`;

export const QuantumSafetySection = styled(ContentSection)`
  @media (max-height: ${rem(880)}) {
    display: none;
  }
`;

export const InstrumentCategories = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${getTheme('space.2')};
`;

export const InstrumentCategory = styled.li``;

const cardStyles = css`
  display: grid;
  padding: ${getTheme('space.4')};
  place-content: center;
  background-color: ${getTheme('colors.cardBackground')};
  border-radius: ${getTheme('radius.md')};
  color: ${getTheme('colors.white.100')};
`;

const largeCardStyles = css`
  display: grid;
  grid-template-columns: auto 1fr;
`;

export const Card = styled.div`
  ${cardStyles}
`;

export const CardLink = styled(NavLink)`
  ${cardStyles}
  ${largeCardStyles}

  &:hover {
    background-color: ${getTheme('colors.green.dark02')};
  }

  &:not(.active) {
    --primary-color: ${getTheme('colors.green.base')};
  }

  &.active {
    background-color: ${getTheme('colors.green.base')};
  }
`;

export const ItemLink = styled(NavLink)`
  flex: 1;
  border-radius: ${getTheme('radius.md')};
  color: ${getTheme('colors.white.100')};

  &:hover {
    background-color: ${getTheme('colors.green.dark02')};
  }

  &:not(.active) {
    --primary-color: ${getTheme('colors.green.base')};
  }

  &.active {
    background-color: ${getTheme('colors.green.base')};
  }
`;

export const CardPreview = styled.span`
  display: inline-flex;
  padding: ${rem(2)} ${getTheme('space.4')} ${rem(2)} 0;
  align-items: center;
  justify-content: center;
  font-size: ${rem(44)};
  border-right: 1px solid ${getTheme('colors.cardBorder')};
`;

export const CardSummary = styled.span`
  display: grid;
  grid-template-rows: repeat(auto-fill, 1fr);
  align-items: center;
  justify-items: start;
  padding-left: ${getTheme('space.4')};
`;

export const CardSummaryTitle = styled.h6`
  font-weight: 500;
  font-size: ${getTheme('fontSizes.sm')};
  line-height: ${getTheme('lineHeights.3')};
`;

export const Tools = styled.div`
  display: flex;
  column-gap: ${getTheme('space.2')};
`;

export const Tool = styled(Card)`
  flex: 1;
  padding: ${getTheme('space.2')} ${getTheme('space.4')};
  row-gap: ${getTheme('space.2')};
`;

export const ToolIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  padding: 0 ${getTheme('space.2')};
  font-size: ${rem(44)};

  --primary-color: ${getTheme('colors.green.base')};
`;

export const ToolTitle = styled.h6`
  font-size: ${getTheme('fontSizes.xs')};
  font-weight: 500;
  text-align: center;
  padding-bottom: ${rem(2)};
`;

export const FinancingDataSection = styled(ContentSection)`
  @media (max-height: ${rem(730)}) {
    display: none;
  }
`;

export const FinancingDataItems = styled.div`
  display: flex;
  column-gap: ${getTheme('space.2')};
`;

export const FinancingDataItem = styled(Card)`
  flex: 1;
  padding: ${getTheme('space.2')} ${getTheme('space.4')};
  row-gap: ${getTheme('space.2')};
  min-height: 100px;
`;

export const FinancingDataIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  padding: 0 ${getTheme('space.2')};
  font-size: ${rem(44)};

  --primary-color: ${getTheme('colors.green.base')};
`;

export const FinancingDataItemTitle = styled.h6`
  font-size: ${getTheme('fontSizes.xs')};
  font-weight: 500;
  text-align: center;
  padding-bottom: ${rem(2)};
`;

export const QuantumSafety = styled(Card)`
  ${largeCardStyles}

  padding: ${getTheme('space.2')} ${getTheme('space.4')};
`;

export const QuantumSafetyPreview = styled(CardPreview)`
  border-right-color: ${getTheme('colors.cardBorderDark')};
  font-size: ${rem(56)};

  --primary-color: ${getTheme('colors.green.base')};
`;

export const QuantumSafetySummary = styled.span`
  padding: 0 ${getTheme('space.4')};
  font-size: ${getTheme('fontSizes.xs')};
  line-height: ${rem(17)};
`;

export const UserSection = styled.div`
  margin-top: auto;
  border-top: 1px solid ${getTheme('colors.cardBorderDark')};
  padding: ${getTheme('space.6')} ${getTheme('space.4')};
`;

type UserCardProps = {
  isActive: boolean;
};

export const UserCard = styled(Card)<UserCardProps>`
  ${largeCardStyles}

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${getTheme('colors.green.dark01')};
    `}
`;

export const UserTitle = styled.h6`
  font-size: ${getTheme('fontSizes.xs')};
  font-weight: 200;
  color: ${getTheme('colors.white.70')};
`;

export const UserActions = styled.div`
  display: flex;
  column-gap: ${rem(10)};
  margin-top: ${getTheme('space.2')};
`;

export const UserAction = styled(Button)`
  flex: 1;
  background-color: ${getTheme('colors.cardBackground')};
  border-color: ${getTheme('colors.cardBackground')};
  min-height: ${rem(50)};
`;

export const PendingCount = styled.span`
  display: inline-flex;
  align-items: center;
  padding: ${getTheme('space.1')} ${getTheme('space.2')};
  margin-top: ${getTheme('space.2')};
  background-color: ${getTheme('colors.black.10')};
  border-radius: ${getTheme('radius.full')};
  font-size: ${getTheme('fontSizes.xs')};
  line-height: ${getTheme('lineHeights.1')};
  font-weight: 500;

  &::before {
    content: '';
    margin-right: ${rem(5)};
    height: ${rem(10)};
    width: ${rem(10)};
    border-radius: ${getTheme('radius.full')};
    background-color: ${getTheme('colors.status.darkRed')};
    border: 2px solid ${getTheme('colors.white.100')};
  }
`;
