import { ReactNode } from 'react';

import LogoImage from './assets/logo.png';
import * as Styles from './AuthLayout.styles';

type Props = {
  children: ReactNode;
};

export const AuthLayout = (props: Props) => {
  return (
    <Styles.Container>
      <Styles.Logo src={LogoImage} alt="TradeSecure logo" />
      <Styles.Content>{props.children}</Styles.Content>
    </Styles.Container>
  );
};
