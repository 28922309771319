import pluralize from 'pluralize';

import { useMembers } from 'api/hooks/useMembers';
import { Alert } from 'components/Alert';
import {
  List,
  ListItem,
  ListItemDescription,
  ListItemDetails,
  ListItemLink,
  ListItemTitle,
} from 'components/List';
import { PageLoader } from 'components/PageLoader';
import { useUser } from 'components/UserProvider';
import { displayNonFunctionalComponent } from 'config';
import { getErrorMessage } from 'helpers/error';
import { getAdminMembers, getRegularMembers } from 'helpers/members';

export const Workspace = () => {
  const {
    data: members = [],
    error: membersError,
    isLoading: isLoadingMembers,
  } = useMembers();
  const { user } = useUser();

  const adminMembers = getAdminMembers(members);
  const regularMembers = getRegularMembers(members);

  if (isLoadingMembers) {
    return <PageLoader />;
  }

  return (
    <>
      {Boolean(membersError) && <Alert>{getErrorMessage(membersError)}</Alert>}

      <List>
        <ListItem>
          <ListItemLink to="members">
            <ListItemDetails>
              <ListItemTitle>Members</ListItemTitle>

              {!membersError && (
                <ListItemDescription>
                  You have {pluralize('member', regularMembers.length, true)}{' '}
                  and {pluralize('Administrator', adminMembers.length, true)}
                </ListItemDescription>
              )}
            </ListItemDetails>
          </ListItemLink>
        </ListItem>
        {displayNonFunctionalComponent && (
          <ListItem>
            <ListItemLink to="">
              <ListItemDetails>
                <ListItemTitle>Deactivate account</ListItemTitle>
                <ListItemDescription>
                  As an Administrator, you cannot delete your own account. You
                  can transfer ownership to another user or ask another
                  Administrator to change your Account Type.
                </ListItemDescription>
              </ListItemDetails>
            </ListItemLink>
          </ListItem>
        )}
        {displayNonFunctionalComponent && (
          <ListItem>
            <ListItemLink to="">
              <ListItemDetails>
                <ListItemTitle>Relinquish Administrator rights</ListItemTitle>
                <ListItemDescription>
                  Relinquishing Administrator rights is a one-way street: you
                  cannot undo it. Doing this means you cede all control to
                  another named Administrator.
                </ListItemDescription>
              </ListItemDetails>
            </ListItemLink>
          </ListItem>
        )}
        {displayNonFunctionalComponent && (
          <ListItem>
            <ListItemLink to="">
              <ListItemDetails>
                <ListItemTitle>Onboarding code</ListItemTitle>
                <ListItemDescription>
                  Your onboarding code for new users is {user.company_number}
                </ListItemDescription>
              </ListItemDetails>
            </ListItemLink>
          </ListItem>
        )}
      </List>
    </>
  );
};
