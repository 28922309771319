import { InstrumentType, InstrumentTypeAbbreviation } from './instrument';
import { Instrument } from './instrument';
import { Invoice } from './invoice';
import { SupplierBankAccount } from './supplierBankAccount';

export type PromissoryNoteState =
  | 'minted'
  | 'active'
  | 'rejected'
  | 'cancelled'
  | 'retired'
  | 'matured'
  | 'expired'
  | 'protested'
  | 'enforced';

export interface IPromissoryNote extends Instrument {
  state: PromissoryNoteState;
  signatures?: InstrumentSignature[];
}

export type InstrumentSignature = {
  signature_name: string;
  signature: string;
};

export class PromissoryNote implements IPromissoryNote {
  id!: string;
  clientPrefix!: string;
  buyerCompanyProfileId!: string;
  buyerUserProfileId?: string;
  investorCompanyProfileId!: string;
  investorUserProfileId?: string;
  supplierCompanyProfileId!: string;
  supplierUserProfileId?: string;
  state!: PromissoryNoteState;
  archived!: boolean;
  remitId!: string;
  reference!: string;
  title!: string;
  buyerName!: string;
  investorName!: string;
  maturity!: string;
  amount!: string;
  currency!: string;
  jurisdiction!: string;
  urlToDboe!: string;
  thumbnail!: null;
  type!: string;
  issuedDate!: string;
  hash!: string;
  pdfHash!: string;
  investmentDate!: string;
  issuerName!: string;
  issuancePlace!: string;
  paymentPlace!: string;
  checkedOut!: boolean;
  protestNumber!: null | string;
  enforceableDate!: null | string;
  hasApproved!: boolean;
  fundBy?: string;
  invoices!: Invoice[];
  supplierBankAccount!: SupplierBankAccount;
  signatures?: InstrumentSignature[] | undefined;

  constructor(promissoryNote: IPromissoryNote) {
    Object.assign(this, promissoryNote);
  }

  isPending(): boolean {
    switch (this.state) {
      case 'minted':
        return true;
      default:
        return false;
    }
  }

  isDarkStyle(): boolean {
    switch (this.state) {
      case 'expired':
        return true;
      case 'cancelled':
        return true;
      case 'retired':
        return true;
      case 'rejected':
        return true;
      default:
        return this.checkedOut;
    }
  }

  getInstrumentType(): InstrumentType {
    return InstrumentType.PROMISSORY_NOTE;
  }

  getInstrumentTypeAbbreviation(): InstrumentTypeAbbreviation {
    return InstrumentTypeAbbreviation.dpn;
  }
}
