import { forwardRef, ReactNode } from 'react';
import {
  StyledComponentInnerOtherProps,
  StyledComponentPropsWithRef,
} from 'styled-components/macro';

import * as Styles from './Input.styles';

type Props = Omit<StyledComponentPropsWithRef<typeof Styles.Input>, 'size'> &
  Partial<
    Omit<
      StyledComponentInnerOtherProps<typeof Styles.Input>,
      '$size' | 'hasEndAdornment'
    >
  > & {
    size?: StyledComponentInnerOtherProps<typeof Styles.Input>['$size'];
    endAdornment?: ReactNode;
  };

export const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { endAdornment, size = 'large', ...otherProps } = props;

  return (
    <Styles.Container>
      <Styles.Input
        ref={ref}
        {...otherProps}
        $size={size}
        hasEndAdornment={Boolean(endAdornment)}
        aria-invalid={props.isInvalid}
      />

      {endAdornment && (
        <Styles.EndAdornment>{endAdornment}</Styles.EndAdornment>
      )}
    </Styles.Container>
  );
});

Input.displayName = 'Input';
