import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components/macro';

import { getTheme } from 'styles';
import { InstumentState } from 'types/instrument';

type StatusDotProps = {
  status?: InstumentState;
  isCheckedOut?: boolean;
  isDarkStyle?: boolean;
  isPending?: boolean;
};

export const StatusDot = styled.span<StatusDotProps>`
  border-radius: ${getTheme('radius.full')};
  height: ${rem(10)};
  width: ${rem(10)};
  border: 1.5px solid transparent;

  ${(props) =>
    props.isPending &&
    css`
      background-color: ${rgba(getTheme('colors.status.yellow')(props), 0.5)};
      border-color: ${getTheme('colors.status.yellow')};
    `}

  ${(props) =>
    props.status === 'active' &&
    css`
      background-color: ${getTheme('colors.green.light02')};
      border-color: ${getTheme('colors.green.light01')};
    `}

  ${(props) =>
    (props.status === 'protested' || props.status === 'enforced') &&
    css`
      background-color: ${rgba(getTheme('colors.status.darkRed')(props), 0.5)};
      border-color: ${getTheme('colors.status.darkRed')};
    `}

  ${(props) =>
    props.status === 'matured' &&
    css`
      background-color: ${rgba(getTheme('colors.status.gold')(props), 0.5)};
      border-color: ${getTheme('colors.status.gold')};
    `}

  ${(props) =>
    (props.isDarkStyle || props.isCheckedOut) &&
    css`
      background-color: ${getTheme('colors.black.70')};
      border-color: ${getTheme('colors.black.70')};
    `}
`;
