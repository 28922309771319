import { useState } from 'react';

import { MultiStepView, View } from 'components/MultiStepView';
import { PageNotFound } from 'components/PageNotFound';

import { FetchedInstrument } from '../types';
import { EnterOTP } from './EnterOTP';
import { ValidateWebLink } from './ValidateWebLink';
import { ViewInstrument } from './ViewInstrument';

type Props = {
  url: string;
};

export const BuyerAcceptance = (props: Props) => {
  const [validationError, setValidationError] = useState<string | undefined>();
  const [fetchedInstrument, setFetchedInstrument] = useState<
    FetchedInstrument | undefined
  >();
  const [otpId, setOtpId] = useState<string | undefined>();
  const [webLinkId, setWebLinkId] = useState<string | undefined>();
  const { url } = props;

  return (
    <MultiStepView initialView="validate-web-link">
      {url && (
        <View id="validate-web-link">
          <ValidateWebLink
            validateUrl={url}
            setOtpId={setOtpId}
            setWebLinkId={setWebLinkId}
            setValidationError={setValidationError}
          />
        </View>
      )}
      {otpId && webLinkId && (
        <View id="enter-otp">
          <EnterOTP
            otpId={otpId}
            webLinkId={webLinkId}
            onSuccess={setFetchedInstrument}
          />
        </View>
      )}
      {webLinkId && (
        <View id="view-instrument">
          <ViewInstrument
            fetchedInstrument={fetchedInstrument}
            webLinkId={webLinkId}
          />
        </View>
      )}
      <View id="invalid-web-link">
        <PageNotFound error={validationError} />
      </View>
    </MultiStepView>
  );
};
