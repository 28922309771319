import { useQueryClient } from '@tanstack/react-query';
import { ReactNode } from 'react';

import { isBadRequest, isNotFound, isUnauthorized } from 'helpers/error';
import { useAuthentication } from 'hooks/useAuthentication';

const MAXIMUM_NUMBER_OF_RETRIES = 2;

type Props = {
  children: ReactNode;
};

// Note: The reason for a separate component that provides api client configuration is
// to make sure that the some of the error handling logic is executed inside of the QueryClientProvider
// so that it could use queryClient when needed.
export const ApiClientConfiguration = (props: Props) => {
  const { logout } = useAuthentication();
  const queryClient = useQueryClient();
  const defaultOptions = queryClient.getDefaultOptions();

  const onError = (error: unknown) => {
    if (isUnauthorized(error)) {
      logout();
    }
  };

  const retry = (count: number, error: unknown) => {
    if (isUnauthorized(error) || isNotFound(error) || isBadRequest(error)) {
      return false;
    }

    return count < MAXIMUM_NUMBER_OF_RETRIES;
  };

  queryClient.setDefaultOptions({
    queries: { ...defaultOptions.queries, onError, retry },
    mutations: { ...defaultOptions.mutations, onError },
  });

  return <>{props.children}</>;
};
