import { useQueryClient } from '@tanstack/react-query';
import identity from 'lodash/identity';
import { useCallback } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { isTokenValid } from 'helpers/authentication';

export const useAuthentication = () => {
  const [accessToken, setAccessToken, { removeItem: removeAccessToken }] =
    useLocalStorageState<string>('accessToken', {
      serializer: {
        stringify: identity,
        parse: identity,
      },
    });
  const isAccessTokenValid = isTokenValid(accessToken);
  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    removeAccessToken();
    queryClient.clear();
  }, [removeAccessToken, queryClient]);

  return {
    accessToken,
    setAccessToken,
    isAccessTokenValid,
    logout,
  };
};
