import { rem } from 'polished';
import styled from 'styled-components/macro';

import FullPageBackgroundImage from 'assets/full-page-background.jpg';
import { getTheme } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url(${FullPageBackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: ${getTheme('space.8')};
  padding-bottom: 0;
`;

export const Logo = styled.img`
  margin-right: auto;
  height: ${rem(60)};
`;

export const Content = styled.main`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(92)} 0;
  margin-top: -${rem(92)};
`;
