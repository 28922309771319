import { providers } from 'data/providers';

import { useAddProvider } from 'api/hooks/useAddProvider';
import { useDeleteProvider } from 'api/hooks/useDeleteProvider';
import { useProviders } from 'api/hooks/useProviders';
import { Alert } from 'components/Alert';
import { Checkbox } from 'components/Checkbox';
import { DataIcon } from 'components/Icon';
import { PageContent } from 'components/PageContent';
import {
  PageHeader,
  PageHeaderDetails,
  PageHeaderIcon,
  PageHeaderTitle,
} from 'components/PageHeader';
import { PageLoader } from 'components/PageLoader';
import {
  PageSubHeader,
  PageSubHeaderDescription,
} from 'components/PageSubHeader';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table';
import { useUser } from 'components/UserProvider';
import { getErrorMessage } from 'helpers/error';

export const DataVisibility = () => {
  const { user } = useUser();
  const investorId = user.company_profile_id;
  const {
    error: linkProviderError,
    isLoading: isLoadingLinkProvider,
    mutateAsync: linkInvestorToProvider,
  } = useAddProvider();
  const {
    error: deleteProviderError,
    isLoading: isLoadingDeleteProvider,
    mutateAsync: deleteInvestorProviderLink,
  } = useDeleteProvider();
  const {
    data: providerList,
    error: getProvidersError,
    isLoading: isLoadingProviders,
  } = useProviders(user);

  const handleProviderAccessChange = async (
    clientPrefix: string,
    isChecked: boolean
  ) => {
    if (isChecked) {
      await linkInvestorToProvider({ investorId, clientPrefix });
    } else {
      await deleteInvestorProviderLink({ investorId, clientPrefix });
    }
  };

  if (isLoadingProviders || isLoadingDeleteProvider || isLoadingLinkProvider) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderDetails>
          <PageHeaderIcon>
            <DataIcon />
          </PageHeaderIcon>
          <PageHeaderTitle>Data Visibility</PageHeaderTitle>
        </PageHeaderDetails>
        <PageSubHeader>
          <PageSubHeaderDescription>
            Kindly indicate which Providers you choose to grant access to your
            Financing Data. Be advised that the Buyer Credit Limits you have
            established will be accessible to any Provider to whom you have
            specifically assigned access rights. This selection ensures that
            only authorised Providers can view sensitive financial thresholds.
          </PageSubHeaderDescription>
        </PageSubHeader>
      </PageHeader>
      <PageContent>
        {linkProviderError ? (
          <Alert>{getErrorMessage(linkProviderError)}</Alert>
        ) : deleteProviderError ? (
          <Alert>{getErrorMessage(deleteProviderError)}</Alert>
        ) : getProvidersError ? (
          <Alert>{getErrorMessage(getProvidersError)}</Alert>
        ) : null}
        <Table>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>Provider Name</TableHeaderCell>
              <TableHeaderCell>Allow Access</TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {providers.map((provider) => (
              <TableRow key={provider.clientPrefix}>
                <TableCell isTopBorderVisible={true}>{provider.name}</TableCell>
                <TableCell isTopBorderVisible={true}>
                  <Checkbox
                    checked={providerList?.includes(provider.clientPrefix)}
                    onChange={(e) =>
                      handleProviderAccessChange(
                        provider.clientPrefix,
                        e.target.checked
                      )
                    }
                    disabled={isLoadingLinkProvider || isLoadingDeleteProvider}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PageContent>
    </>
  );
};
