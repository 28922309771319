import { ComponentPropsWithRef, ComponentType, ReactNode } from 'react';
import {
  StyledComponentInnerOtherProps,
  StyledComponentPropsWithRef,
} from 'styled-components/macro';

import { Spinner } from 'components/Spinner';

import * as Styles from './Button.styles';

type AsComponentProps<AsComponent> = {
  as?: AsComponent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & (AsComponent extends keyof JSX.IntrinsicElements | ComponentType<any>
  ? ComponentPropsWithRef<AsComponent>
  : unknown);

type Props<AsComponent> = StyledComponentPropsWithRef<typeof Styles.Button> &
  AsComponentProps<AsComponent> &
  Omit<
    Partial<StyledComponentInnerOtherProps<typeof Styles.Button>>,
    '$fullWidth' | '$isLoading'
  > & {
    children: ReactNode;
    startIcon?: ReactNode;
    isLoading?: boolean;
    fullWidth?: boolean;
  };

export const Button = <
  AsComponent extends
    | JSX.IntrinsicElements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ComponentType<any>
    | undefined = undefined
>(
  props: Props<AsComponent>
) => {
  const {
    children,
    disabled,
    fullWidth,
    isLoading,
    size = 'small',
    startIcon,
    variant = 'primary',
    ...otherProps
  } = props;

  return (
    <Styles.Button
      {...otherProps}
      $fullWidth={fullWidth}
      size={size}
      variant={variant}
      disabled={disabled || isLoading}
      $isLoading={isLoading}
    >
      {isLoading && <Spinner />}

      {!isLoading && (
        <>
          {startIcon && <Styles.Icon>{startIcon}</Styles.Icon>}
          {children}
        </>
      )}
    </Styles.Button>
  );
};
