import {
  isDarkRedStyleInstrument,
  isGoldStyleInstrument,
} from 'helpers/instrument';
import { Instrument } from 'types/instrument';

import InstrumentedActiveImage from './assets/instrument-active.png';
import InstrumentedEnforcedImage from './assets/instrument-enforced.png';
import InstrumentedExpiredImage from './assets/instrument-expired.png';
import InstrumentedMaturedImage from './assets/instrument-matured.png';
import InstrumentedMintedImage from './assets/instrument-minted.png';

const getImageForInstrument = (instrument: Instrument) => {
  if (instrument.isDarkStyle()) {
    return InstrumentedExpiredImage;
  }

  if (isGoldStyleInstrument(instrument)) {
    return InstrumentedMaturedImage;
  }

  if (instrument.isPending()) {
    return InstrumentedEnforcedImage;
  }

  if (isDarkRedStyleInstrument(instrument)) {
    return InstrumentedMintedImage;
  }

  return InstrumentedActiveImage;
};

type Props = {
  className?: string;
  instrument: Instrument;
  onClick?: () => void;
};

export const InstrumentPreview = (props: Props) => {
  const { instrument, ...otherProps } = props;

  return (
    <img
      src={getImageForInstrument(instrument)}
      alt={`Instrument ${instrument.state} preview`}
      {...otherProps}
    />
  );
};
