import { useQuery } from '@tanstack/react-query';

import { getPromissoryNoteHistory } from 'api/client/promissoryNote';
import { QueryKeys } from 'api/client/types';

export const usePromissoryNoteHistory = (id: string) => {
  return useQuery({
    queryKey: [QueryKeys.PromissoryNoteHistory, id],
    queryFn: () => getPromissoryNoteHistory(id),
  });
};
