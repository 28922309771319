import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteMargin } from 'api/client/margins';
import { QueryKeys } from 'api/client/types';

export const useDeleteMargin = () => {
    const queryClient = useQueryClient();
    return useMutation({ 
        mutationFn: deleteMargin,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.Margins]})
        }
    });
};