import { useMutation } from '@tanstack/react-query';

import { checkOutPromissoryNote } from 'api/client/promissoryNote';
import { useUpdatePromissoryNoteCache } from 'hooks/PromissoryNotes/useUpdatePromissoryNoteCache';

export const useCheckOutPromissoryNote = () => {
  const { updatePromissoryNote } = useUpdatePromissoryNoteCache();

  return useMutation({
    mutationFn: checkOutPromissoryNote,
    onSuccess: (_, id) => {
      updatePromissoryNote(id, { checkedOut: true });
    },
  });
};
