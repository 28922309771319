import { rem } from 'polished';
import styled from 'styled-components/macro';

import { getTheme } from 'styles';

import { BackLink } from './BackLink.styles';

export const PageHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding: ${getTheme('space.6')};
`;

export const PageHeaderDetails = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${getTheme('space.4')};
  margin-top: ${getTheme('space.8')};

  ${BackLink} + & {
    margin-top: ${getTheme('space.2')};
  }
`;

export const PageHeaderTitle = styled.h1`
  font-size: ${getTheme('fontSizes.2xl')};
  font-weight: 600;
`;

export const PageHeaderIcon = styled.span`
  display: inline-flex;
  font-size: ${rem(32)};
  color: ${getTheme('colors.navy')};
`;
