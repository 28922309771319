import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table';
import { formatDate, parseApiDate } from 'helpers/date';
import { InstrumentHistoryRecord } from 'types/audit';

type Props = {
  instrumentHistory: InstrumentHistoryRecord[];
};

export const InstrumentHistoryTable = (props: Props) => {
  const { instrumentHistory } = props;

  return (
    <Table>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Transaction type </TableHeaderCell>
          <TableHeaderCell> Name </TableHeaderCell>
          <TableHeaderCell> Email </TableHeaderCell>
          <TableHeaderCell> Transaction date </TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>

      <TableBody>
        {instrumentHistory.map((record) => (
          <React.Fragment key={`${record.state}_${record.transaction_time}`}>
            {record.users ? (
              record.users.map((user, userIndex) => (
                <TableRow
                  key={`${record.state}_${record.transaction_time}_${userIndex}`}
                >
                  {userIndex === 0 && (
                    <TableCell
                      isTopBorderVisible={userIndex === 0 ? true : false}
                      rowSpan={record.users ? record.users.length : 1}
                    >
                      {' '}
                      {record.description}{' '}
                    </TableCell>
                  )}
                  <TableCell
                    isTopBorderVisible={userIndex === 0 ? true : false}
                  >
                    {user && user.name ? user.name : ''}
                  </TableCell>
                  <TableCell
                    isTopBorderVisible={userIndex === 0 ? true : false}
                  >
                    {user ? user.email : ''}
                  </TableCell>
                  {userIndex === 0 && (
                    <TableCell
                      isTopBorderVisible={userIndex === 0 ? true : false}
                      rowSpan={record.users ? record.users.length : 1}
                    >
                      {formatDate(parseApiDate(record.transaction_time), true)}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow key={`${record.state}_${record.transaction_time}`}>
                <TableCell>{record.description} </TableCell>
                <TableCell> {'N/A'} </TableCell>
                <TableCell> {'N/A'} </TableCell>
                <TableCell>
                  {formatDate(parseApiDate(record.transaction_time), true)}
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};
