import { useMutation } from '@tanstack/react-query';

import { checkOutBillOfExchange } from 'api/client/billOfExchange';
import { useUpdateBillOfExchangeCache } from 'hooks/BillOfExchange/useUpdateBillOfExchangeCache';

export const useCheckOutBillOfExchange = () => {
  const { updateBillOfExchange } = useUpdateBillOfExchangeCache();

  return useMutation({
    mutationFn: checkOutBillOfExchange,
    onSuccess: (_, id) => {
      updateBillOfExchange(id, { checkedOut: true });
    },
  });
};
