import { useMemo } from 'react';

import { useBillsOfExchange } from 'api/hooks/BillOfExchange/useBillsOfExchange';
import { isArchivedInstrument } from 'helpers/instrument';
import { BillOfExchange } from 'types/billOfExchange';

export const useArchivedBillsOfExchange = () => {
  const { data = [], ...query } = useBillsOfExchange();

  const instruments = useMemo(() => {
    return data
      .map((billOfExchange) => new BillOfExchange(billOfExchange))
      .filter(isArchivedInstrument);
  }, [data]);

  return { ...query, instruments };
};
