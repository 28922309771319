import isEmpty from 'lodash/isEmpty';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import { useParams } from 'react-router-dom';

import { useDigitalTwin } from 'api/hooks/useDigitalTwin';
import { AlarmIcon } from 'components/Icon';
import { InstrumentHistoryTable } from 'components/InstrumentHistoryTable';
import { PageContent } from 'components/PageContent';
import {
  PageHeader,
  PageHeaderIcon,
  PageHeaderTitle,
} from 'components/PageHeader';
import { PageLoader } from 'components/PageLoader';
import { getErrorMessage, isNotFound } from 'helpers/error';

import * as Styles from './DigitalTwin.styles';
import { NothingToSeeHere } from './NothingToSeeHere';

type RouterParams = {
  id: string;
};

export const DigitalTwin = () => {
  const { id = '' } = useParams<RouterParams>();
  const {
    data: digitalTwin = { pdfUrl: '', instrumentHistory: [] },
    error: digitalTwinError,
    isLoading,
  } = useDigitalTwin(id);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const sortedHistory = useMemo(
    () => sortBy(digitalTwin.instrumentHistory, ['transaction_time']),
    [digitalTwin.instrumentHistory]
  );
  const hasHistory = !isEmpty(sortedHistory);
  const [scale, setScale] = useState(1);

  const onLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setNumberOfPages(pdf.numPages);
  };

  useEffect(() => {
    const updateScale = () => {
      const container = document?.querySelector(
        '.pdf-container'
      ) as HTMLElement;

      if (container) {
        const containerWidth = container.offsetWidth;
        const baseScale = containerWidth / 1000;
        setScale(baseScale);
      }
    };

    updateScale();
    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [scale]);

  if (isLoading) {
    return (
      <Styles.Page>
        <PageLoader />
      </Styles.Page>
    );
  }

  return (
    <Styles.Background>
      <Styles.Header />
      <Styles.Content>
        {Boolean(digitalTwinError) &&
          (isNotFound(digitalTwinError) ? (
            <NothingToSeeHere />
          ) : (
            <Styles.Card>
              <Styles.Error>{getErrorMessage(digitalTwinError)}</Styles.Error>
            </Styles.Card>
          ))}

        {!digitalTwinError && (!hasHistory || !digitalTwin.pdfUrl) && (
          <NothingToSeeHere />
        )}

        {!digitalTwinError && hasHistory && digitalTwin.pdfUrl && (
          <Styles.Body>
            <Styles.PdfContainer className="pdf-container">
              <Styles.Preview>
                <Document
                  file={digitalTwin.pdfUrl}
                  loading={<Styles.Loader />}
                  onLoadSuccess={onLoadSuccess}
                >
                  {range(numberOfPages).map((pageNumber) => (
                    <Page
                      key={pageNumber}
                      pageNumber={pageNumber + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      scale={scale}
                    />
                  ))}
                </Document>
              </Styles.Preview>
            </Styles.PdfContainer>
            <Styles.HistoryContainer>
              <Styles.AuditContainer>
                <PageHeader>
                  <Styles.CustomPageHeaderDetails>
                    <PageHeaderIcon>
                      <AlarmIcon />
                    </PageHeaderIcon>
                    <PageHeaderTitle>Instrument History</PageHeaderTitle>
                  </Styles.CustomPageHeaderDetails>
                </PageHeader>
                <PageContent>
                  {hasHistory && (
                    <InstrumentHistoryTable instrumentHistory={sortedHistory} />
                  )}
                </PageContent>
              </Styles.AuditContainer>
            </Styles.HistoryContainer>
          </Styles.Body>
        )}
      </Styles.Content>
    </Styles.Background>
  );
};
