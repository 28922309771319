import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components/macro';

import { getTheme } from './theme';

export const GlobalStyles = createGlobalStyle`
  ${normalize()}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    font-size: 100%;
    font-family: 'Poppins';
    font-weight: 400;
    color: ${getTheme('colors.black.100')}
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  button {
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
  }
`;
