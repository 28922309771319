import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getTheme('colors.backdrop')};
`;
