import { Outlet } from 'react-router-dom';

import * as Styles from './Dashboard.styles';
import { Sidebar } from './Sidebar';

export const Dashboard = () => {
  return (
    <Styles.Container>
      <Sidebar />

      <Styles.Content>
        <Outlet />
      </Styles.Content>
    </Styles.Container>
  );
};
