import styled from 'styled-components/macro';

import { DialogContent } from 'components/Dialog';
import { getTheme } from 'styles';

export const Content = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${getTheme('space.8')};
  row-gap: ${getTheme('space.4')};
`;

export const DialogInput = styled.input`
  align-items: center;
  justify-content: center;
  line-height: ${getTheme('lineHeights.3')};
  font-weight: 500;
  border-radius: ${getTheme('radius.md')};
  border: 1px solid ${getTheme('colors.green.base')};
  padding: ${getTheme('space.2')};
`;

export const FormLabel = styled.label`
  display: block;
  font-weight: 500;
  font-size: ${getTheme('fontSizes.base')};
  line-height: ${getTheme('lineHeights.3')};
  padding-bottom: ${getTheme('space.2')};
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
`;
