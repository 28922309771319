import { rem } from 'polished';
import styled, { css } from 'styled-components/macro';

import { getTheme } from 'styles';

type ContainerProps = {
  size: 'small' | 'large';
};

export const Container = styled.span<ContainerProps>`
  display: inline-flex;
  align-items: center;
  column-gap: ${getTheme('space.2')};
  border: 1px solid ${getTheme('colors.greyBorder')};
  border-radius: ${getTheme('radius.md')};
  line-height: ${getTheme('lineHeights.1')};
  background-color: ${getTheme('colors.white.100')};

  strong {
    font-weight: 600;
  }

  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${getTheme('fontSizes.xs')};
      padding: ${rem(3)} ${getTheme('space.2')};
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      font-size: ${getTheme('fontSizes.base')};
      font-weight: 500;
      padding: ${rem(7)} ${getTheme('space.3')};
    `}
`;
