import get from 'lodash/get';
import { DefaultTheme, ThemeProps } from 'styled-components/macro';

import { Paths, PathValue, PathValues } from 'types/util';

import { Theme } from './types';

export const getTheme =
  <Path extends Paths<PathValues<Theme>>>(path: Path) =>
  (props: ThemeProps<DefaultTheme>): PathValue<Path, PathValues<Theme>> => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return get(props.theme, path)!;
  };
