import styled, { css } from 'styled-components/macro';

import { getTheme } from 'styles';

export const Table = styled.table`
  border-spacing: 0;
  border: 1px solid ${getTheme('colors.black.10')};
  border-radius: ${getTheme('radius.lg')};
`;

export const TableHeader = styled.thead``;

export const TableHeaderRow = styled.tr``;

export const TableHeaderCell = styled.th`
  font-size: ${getTheme('fontSizes.xs')};
  line-height: ${getTheme('lineHeights.1')};
  font-weight: 500;
  color: ${getTheme('colors.green.base')};
  text-align: left;
  padding: ${getTheme('space.3')};
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

type TableCellProps = {
  isTopBorderVisible?: boolean;
};

export const TableCell = styled.td<TableCellProps>`
  ${(props) =>
    (props.isTopBorderVisible == null || props.isTopBorderVisible === true) &&
    css`
      border-top: 1px solid ${getTheme('colors.black.10')};
    `}

  font-size: ${getTheme('fontSizes.xs')};
  line-height: ${getTheme('lineHeights.1')};
  padding: ${getTheme('space.3')};
`;
