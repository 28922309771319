import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const InstrumentListTitle = styled.h2`
  padding: ${getTheme('space.4')} 0;
  font-weight: 500;
  font-size: ${getTheme('fontSizes.base')};
  color: ${getTheme('colors.black.70')};
`;

export const InstrumentListCount = styled.span`
  color: ${getTheme('colors.green.light01')};
`;

export const InstrumentList = styled.ul`
  margin-bottom: ${getTheme('space.4')};
`;

export const InstrumentListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${getTheme('space.6')};
  }
`;
