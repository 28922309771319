import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthLayout } from 'components/AuthLayout';
import { MultiStepView, View } from 'components/MultiStepView';
import { useAuthentication } from 'hooks/useAuthentication';

import { EnterPassword } from './EnterPassword';
import { EnterVerificationCode } from './EnterVerificationCode';
import * as Styles from './Login.styles';
import { EnterPasswordFormData } from './types';

export const Login = () => {
  const [enterPasswordFormData, setEnterPasswordFormData] = useState<
    EnterPasswordFormData | undefined
  >();

  const location = useLocation();

  const { isAccessTokenValid } = useAuthentication();

  if (isAccessTokenValid) {
    return <Navigate to={location.state?.redirectTo ?? '/'} replace />;
  }

  return (
    <AuthLayout>
      <Styles.Card>
        <MultiStepView initialView="enter-password">
          <View id="enter-password">
            <EnterPassword
              initialFormData={enterPasswordFormData}
              onSuccess={setEnterPasswordFormData}
            />
          </View>

          {enterPasswordFormData && (
            <View id="enter-verification-code">
              <EnterVerificationCode
                enterPasswordFormData={enterPasswordFormData}
              />
            </View>
          )}
        </MultiStepView>
      </Styles.Card>
    </AuthLayout>
  );
};
