import { ReactNode } from 'react';
import { StyledComponentInnerOtherProps } from 'styled-components/macro';

import * as Styles from './Tag.styles';

type Props = Partial<
  StyledComponentInnerOtherProps<typeof Styles.Container>
> & {
  className?: string;
  children: ReactNode;
};

export const Tag = (props: Props) => {
  const { size = 'small', ...otherProps } = props;

  return <Styles.Container {...otherProps} size={size} />;
};
