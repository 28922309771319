import { yupResolver } from '@hookform/resolvers/yup';
import { currencyOptions } from 'data/currency';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CreditLimitItem, CreditLimitRequest } from 'api/client/types';
import { useAddCreditLimit } from 'api/hooks/useAddCreditLimit';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Dialog, DialogActions, DialogTitle } from 'components/Dialog';
import { DropDownMenu } from 'components/DropDownMenu';
import { FormInput } from 'components/Form';
import { getErrorMessage } from 'helpers/error';

import { Content, FormItem, FormLabel } from './EditCreditLimitDialog.styles';

type Props = {
  id: string;
  creditLimitData: CreditLimitItem;
  onClose: () => void;
};

const creditLimitSchema = yup.object().shape({
  buyerId: yup.string().required('Buyer is required'),
  buyerCompanyName: yup
    .string()
    .max(250, 'Buyer Company Name must have length less than 250')
    .required(),
  creditLimit: yup
    .number()
    .typeError('Credit Limit is required')
    .required('Credit Limit is required')
    .min(0, 'Credit Limit must be positive number')
    .max(2147483647, 'Credit Limit is too large. Please enter a smaller value.')
    .test(
      'maxDecimals',
      'Credit Limit must have up to two decimal places',
      (number) => {
        if (number == null) {
          return true;
        }
        return /^\d+(\.\d{0,2})?$/.test(number.toString());
      }
    ),
  currency: yup
    .string()
    .oneOf(
      currencyOptions.map((currency) => currency.id),
      'Currency is required'
    )
    .required('Currency is required'),
  effectiveDate: yup
    .date()
    .required('Effective Date is required')
    .typeError('Effective Date is required')
    .min(new Date(), 'Effective Date must be in the future'),
});

function getLocalDateString(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const EditCreditLimitDialog = (props: Props) => {
  const { creditLimitData, id, onClose } = props;

  const {
    error,
    isLoading,
    isSuccess,
    mutateAsync: addCreditLimit,
  } = useAddCreditLimit();

  const initialFormData: CreditLimitRequest = {
    creditLimit: creditLimitData.creditLimit,
    investorId: id,
    buyerId: creditLimitData.buyerId,
    buyerCompanyName: creditLimitData.buyerCompanyName,
    currency: creditLimitData.currency,
    effectiveDate: getLocalDateString(
      new Date(creditLimitData.effectiveDate * 1000)
    ),
  };

  const {
    formState: { errors, isSubmitting, isValid, touchedFields },
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useForm<CreditLimitRequest>({
    resolver: yupResolver(creditLimitSchema),
    defaultValues: initialFormData,
    mode: 'onChange',
  });

  const getFutureDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  };

  const onSubmit = async (data: CreditLimitRequest) => {
    const creditLimit: CreditLimitRequest = {
      creditLimit: data.creditLimit,
      investorId: id,
      buyerId: data.buyerId,
      buyerCompanyName: data.buyerCompanyName,
      currency: data.currency,
      effectiveDate: data.effectiveDate,
    };

    await addCreditLimit(creditLimit);
    onClose();
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  const defaultCurrency =
    currencyOptions.find((option) => option.id === creditLimitData.currency)
      ?.name || '';

  return (
    <Dialog>
      <DialogTitle>Update Credit Limit for a Buyer</DialogTitle>
      {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}
      {isSuccess && (
        <Alert severity={'info'}>{'Credit Limit updated successfully'}</Alert>
      )}
      <Content>
        <FormItem>
          <FormLabel>Buyer Company</FormLabel>
          <FormInput
            type="string"
            {...register('buyerId')}
            value={creditLimitData.buyerCompanyName}
            readOnly
            disabled
          />
        </FormItem>
        <FormItem>
          <FormLabel>Credit Limit Value</FormLabel>
          <FormInput
            type="number"
            min={0}
            step={0.01}
            placeholder="Enter a Credit Limit"
            {...register('creditLimit')}
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
          />
        </FormItem>
        <FormItem>
          <FormLabel>Currency</FormLabel>
          <DropDownMenu
            options={currencyOptions}
            valueKey="id"
            labelKey="name"
            {...register('currency')}
            onChange={(value) =>
              setValue('currency', value, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }
            defaultValue={defaultCurrency}
          />
        </FormItem>
        <FormItem>
          <FormLabel>Effective Date</FormLabel>
          <FormInput
            type="date"
            min={getFutureDate()}
            {...register('effectiveDate')}
          />
        </FormItem>
      </Content>
      {!(Object.keys(touchedFields).length === 0) && errors.buyerId && (
        <Alert>{errors.buyerId.message}</Alert>
      )}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.buyerId &&
        errors.creditLimit && <Alert>{errors.creditLimit.message}</Alert>}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.buyerId &&
        !errors.creditLimit &&
        errors.currency && <Alert>{errors.currency.message}</Alert>}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.buyerId &&
        !errors.creditLimit &&
        !errors.currency &&
        errors.effectiveDate && <Alert>{errors.effectiveDate.message}</Alert>}
      <DialogActions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading || isSubmitting}
          disabled={!isValid || isLoading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
