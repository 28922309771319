import { useMutation } from '@tanstack/react-query';

import { approveBuyerMember, approveInvestorMember } from 'api/client/members';
import { useUser } from 'components/UserProvider';
import { useUpdateMemberCache } from 'hooks/useUpdateMemberCache';

export const useApproveMember = () => {
  const { updateMember } = useUpdateMemberCache();
  const { isBuyer } = useUser();

  return useMutation({
    mutationFn: (id: number) => {
      if (isBuyer) {
        return approveBuyerMember(id);
      }

      return approveInvestorMember(id);
    },
    onSuccess: (_, id) => {
      updateMember(id, { state: 'Approved' });
    },
  });
};
