type CurrencyName = {
  majorSingle: string;
  majorPlural: string;
  minorSingle: string;
  minorPlural: string;
};

export const currencyNames: Record<string, CurrencyName> = {
  AED: {
    majorSingle: 'Dirham',
    majorPlural: 'Dirhams',
    minorSingle: 'Fils',
    minorPlural: 'Fils',
  },
  AFN: {
    majorSingle: 'Afghani',
    majorPlural: 'Afghani',
    minorSingle: 'Pul',
    minorPlural: 'Pul',
  },
  ALL: {
    majorSingle: 'Lek',
    majorPlural: 'Lekë',
    minorSingle: 'Qindarka',
    minorPlural: 'Qindarka',
  },
  AMD: {
    majorSingle: 'Dram',
    majorPlural: 'Dram',
    minorSingle: 'Luma',
    minorPlural: 'Luma',
  },
  ANG: {
    majorSingle: 'Guilder',
    majorPlural: 'Guilders',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  AOA: {
    majorSingle: 'Kwanza',
    majorPlural: 'Kwanza',
    minorSingle: 'Centimo',
    minorPlural: 'Centimos',
  },
  ARS: {
    majorSingle: 'Peso',
    majorPlural: 'Pesos',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  AUD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  AWG: {
    majorSingle: 'Florin',
    majorPlural: 'Florin',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  AZN: {
    majorSingle: 'Manat',
    majorPlural: 'Manat',
    minorSingle: 'Qapik',
    minorPlural: 'Qapik',
  },
  BAM: {
    majorSingle: 'Convertible Mark',
    majorPlural: 'Marks',
    minorSingle: 'Fening',
    minorPlural: 'Fening',
  },
  BBD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  BDT: {
    majorSingle: 'Taka',
    majorPlural: 'Taka',
    minorSingle: 'Poisha',
    minorPlural: 'Poisha',
  },
  BGN: {
    majorSingle: 'Lev',
    majorPlural: 'Leva',
    minorSingle: 'Stotinka',
    minorPlural: 'Stotinki',
  },
  BHD: {
    majorSingle: 'Dinar',
    majorPlural: 'Dinars',
    minorSingle: 'Fils',
    minorPlural: 'Fils',
  },
  BIF: {
    majorSingle: 'Franc',
    majorPlural: 'Francs',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  BMD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  BND: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  BOB: {
    majorSingle: 'Boliviano',
    majorPlural: 'Bolivianos',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  BRL: {
    majorSingle: 'Real',
    majorPlural: 'Reais',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  BSD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  BTN: {
    majorSingle: 'Ngultrum',
    majorPlural: 'Ngultrums',
    minorSingle: 'Chetrum',
    minorPlural: 'Chetrums',
  },
  BWP: {
    majorSingle: 'Pula',
    majorPlural: 'Pula',
    minorSingle: 'Thebe',
    minorPlural: 'Thebe',
  },
  BYN: {
    majorSingle: 'Ruble',
    majorPlural: 'Rubles',
    minorSingle: 'Kapiejka',
    minorPlural: 'Kapiejka',
  },
  BZD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  CAD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  CDF: {
    majorSingle: 'Franc',
    majorPlural: 'Francs',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  CHF: {
    majorSingle: 'Franc',
    majorPlural: 'Francs',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  CKD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  CLP: {
    majorSingle: 'Peso',
    majorPlural: 'Pesos',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  CNY: {
    majorSingle: 'Yuan',
    majorPlural: 'Yuan',
    minorSingle: 'Fen',
    minorPlural: 'Fen',
  },
  COP: {
    majorSingle: 'Peso',
    majorPlural: 'Pesos',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  CRC: {
    majorSingle: 'Colón',
    majorPlural: 'Colones',
    minorSingle: 'Centimo',
    minorPlural: 'Centimos',
  },
  CUC: {
    majorSingle: 'Peso',
    majorPlural: 'Pesos',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  CUP: {
    majorSingle: 'Peso',
    majorPlural: 'Pesos',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  CVE: {
    majorSingle: 'Escudo',
    majorPlural: 'Escudo',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  CZK: {
    majorSingle: 'Koruna',
    majorPlural: 'Koruny',
    minorSingle: 'Haléř',
    minorPlural: 'Haléř',
  },
  DJF: {
    majorSingle: 'Franc',
    majorPlural: 'Francs',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  DKK: {
    majorSingle: 'Krone',
    majorPlural: 'Kroner',
    minorSingle: 'Øre',
    minorPlural: 'Øre',
  },
  DOP: {
    majorSingle: 'Peso',
    majorPlural: 'Pesos',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  DZD: {
    majorSingle: 'Dinar',
    majorPlural: 'Dinars',
    minorSingle: 'Santeem',
    minorPlural: 'Santeems',
  },
  EGP: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Qirsh',
    minorPlural: 'Qirsh',
  },
  EHP: {
    majorSingle: 'Peseta',
    majorPlural: 'Pesetas',
    minorSingle: 'Céntimo',
    minorPlural: 'Céntimos',
  },
  ERN: {
    majorSingle: 'Nakfa',
    majorPlural: 'Nakfa',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  ETB: {
    majorSingle: 'Birr',
    majorPlural: 'Birr',
    minorSingle: 'Santim',
    minorPlural: 'Santim',
  },
  EUR: {
    majorSingle: 'Euro',
    majorPlural: 'Euros',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  FJD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  FKP: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Penny',
    minorPlural: 'Pence',
  },
  FOK: {
    majorSingle: 'Króna',
    majorPlural: 'Krónas',
    minorSingle: 'Oyra',
    minorPlural: 'Oyra',
  },
  GBP: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Penny',
    minorPlural: 'Pence',
  },
  GEL: {
    majorSingle: 'Lari',
    majorPlural: 'Lari',
    minorSingle: 'Tetri',
    minorPlural: 'Tetri',
  },
  GGP: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Penny',
    minorPlural: 'Pence',
  },
  GHS: {
    majorSingle: 'Cedi',
    majorPlural: 'Cedis',
    minorSingle: 'Pesewa',
    minorPlural: 'Pesewas',
  },
  GIP: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Penny',
    minorPlural: 'Pence',
  },
  GMD: {
    majorSingle: 'Dalasi',
    majorPlural: 'Dalasis',
    minorSingle: 'Butut',
    minorPlural: 'Bututs',
  },
  GNF: {
    majorSingle: 'Franc',
    majorPlural: 'Francs',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  GTQ: {
    majorSingle: 'Quetzal',
    majorPlural: 'Quetzales',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  GYD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  HKD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  HNL: {
    majorSingle: 'Lempira',
    majorPlural: 'Lempiras',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  HRK: {
    majorSingle: 'Kuna',
    majorPlural: 'Kuna',
    minorSingle: 'Lipa',
    minorPlural: 'Lipa',
  },
  HTG: {
    majorSingle: 'Gourde',
    majorPlural: 'Gourdes',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  HUF: {
    majorSingle: 'Forint',
    majorPlural: 'Forint',
    minorSingle: 'fillér',
    minorPlural: 'fillér',
  },
  IDR: {
    majorSingle: 'Rupiah',
    majorPlural: 'Rupiah',
    minorSingle: 'Sen',
    minorPlural: 'Sen',
  },
  ILS: {
    majorSingle: 'Shekel',
    majorPlural: 'Shekels',
    minorSingle: 'Agora',
    minorPlural: 'Agoras',
  },
  IMP: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Penny',
    minorPlural: 'Pence',
  },
  INR: {
    majorSingle: 'Rupee',
    majorPlural: 'Rupees',
    minorSingle: 'Paisa',
    minorPlural: 'Paise',
  },
  IQD: {
    majorSingle: 'Dinar',
    majorPlural: 'Dinars',
    minorSingle: 'Fils',
    minorPlural: 'Fils',
  },
  IRR: {
    majorSingle: 'Rial',
    majorPlural: 'Rials',
    minorSingle: 'Dinar',
    minorPlural: 'Dinars',
  },
  ISK: {
    majorSingle: 'Krona',
    majorPlural: 'Krónur',
    minorSingle: 'Aurar',
    minorPlural: 'Aurar',
  },
  JEP: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Penny',
    minorPlural: 'Pence',
  },
  JMD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  JOD: {
    majorSingle: 'Dinar',
    majorPlural: 'Dinars',
    minorSingle: 'Fils',
    minorPlural: 'Fils',
  },
  JPY: {
    majorSingle: 'Yen',
    majorPlural: 'Yen',
    minorSingle: 'Sen',
    minorPlural: 'Sen',
  },
  KES: {
    majorSingle: 'Shilling',
    majorPlural: 'Shillings',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  KGS: {
    majorSingle: 'Som',
    majorPlural: 'Som',
    minorSingle: 'Tyiyn',
    minorPlural: 'Tyiyn',
  },
  KHR: {
    majorSingle: 'Riel',
    majorPlural: 'Riels',
    minorSingle: 'Sen',
    minorPlural: 'Sen',
  },
  KID: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  KMF: {
    majorSingle: 'Franc',
    majorPlural: 'Francs',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  KPW: {
    majorSingle: 'Won',
    majorPlural: 'Won',
    minorSingle: 'Chon',
    minorPlural: 'Chon',
  },
  KRW: {
    majorSingle: 'Won',
    majorPlural: 'Won',
    minorSingle: 'Jeon',
    minorPlural: 'Jeon',
  },
  KWD: {
    majorSingle: 'Dinar',
    majorPlural: 'Dinars',
    minorSingle: 'Fils',
    minorPlural: 'Fils',
  },
  KYD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  KZT: {
    majorSingle: 'Tenge',
    majorPlural: 'Tenge',
    minorSingle: 'Tıyn',
    minorPlural: 'Tıyn',
  },
  LAK: {
    majorSingle: 'Kip',
    majorPlural: 'Kip',
    minorSingle: 'Att',
    minorPlural: 'Att',
  },
  LBP: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Qirsh',
    minorPlural: 'Qirsh',
  },
  LKR: {
    majorSingle: 'Rupee',
    majorPlural: 'Rupees',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  LRD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  LSL: {
    majorSingle: 'Loti',
    majorPlural: 'maLoti',
    minorSingle: 'Sente',
    minorPlural: 'Lisente',
  },
  LYD: {
    majorSingle: 'Dinar',
    majorPlural: 'Dinars',
    minorSingle: 'Dirham',
    minorPlural: 'Dirhams',
  },
  MAD: {
    majorSingle: 'Dirham',
    majorPlural: 'Dirhams',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  MDL: {
    majorSingle: 'Leu',
    majorPlural: 'Lei',
    minorSingle: 'Ban',
    minorPlural: 'Bani',
  },
  MGA: {
    majorSingle: 'Ariary',
    majorPlural: 'Ariary',
    minorSingle: 'Iraimbilanja',
    minorPlural: 'Iraimbilanja',
  },
  MKD: {
    majorSingle: 'Denar',
    majorPlural: 'Denars',
    minorSingle: 'Deni',
    minorPlural: 'Deni',
  },
  MMK: {
    majorSingle: 'Kyat',
    majorPlural: 'Kyat',
    minorSingle: 'Pya',
    minorPlural: 'Pya',
  },
  MNT: {
    majorSingle: 'Tögrög',
    majorPlural: 'Tögrög',
    minorSingle: 'möngö',
    minorPlural: 'möngö',
  },
  MOP: {
    majorSingle: 'Pataca',
    majorPlural: 'Patacas',
    minorSingle: 'Avo',
    minorPlural: 'Avos',
  },
  MRU: {
    majorSingle: 'Ouguiya',
    majorPlural: 'Ouguiya',
    minorSingle: 'Khoums',
    minorPlural: 'Khoums',
  },
  MUR: {
    majorSingle: 'Rupee',
    majorPlural: 'Rupees',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  MVR: {
    majorSingle: 'Rufiyaa',
    majorPlural: 'Rufiyaa',
    minorSingle: 'laari',
    minorPlural: 'laari',
  },
  MWK: {
    majorSingle: 'Kwacha',
    majorPlural: 'Kwacha',
    minorSingle: 'Tambala',
    minorPlural: 'Tambala',
  },
  MXN: {
    majorSingle: 'Peso',
    majorPlural: 'Pesos',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  MYR: {
    majorSingle: 'Ringgit',
    majorPlural: 'Ringgit',
    minorSingle: 'Sen',
    minorPlural: 'Sen',
  },
  MZN: {
    majorSingle: 'Metical',
    majorPlural: 'Meticais',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  NAD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  NGN: {
    majorSingle: 'Naira',
    majorPlural: 'Naira',
    minorSingle: 'Kobo',
    minorPlural: 'Kobo',
  },
  NIO: {
    majorSingle: 'Córdoba Oro',
    majorPlural: 'Córdoba Oro',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  NOK: {
    majorSingle: 'Krone',
    majorPlural: 'Kroner',
    minorSingle: 'øre',
    minorPlural: 'øre',
  },
  NPR: {
    majorSingle: 'Rupee',
    majorPlural: 'Rupees',
    minorSingle: 'Paisa',
    minorPlural: 'Paise',
  },
  NZD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  OMR: {
    majorSingle: 'Rial',
    majorPlural: 'Rials',
    minorSingle: 'Baisa',
    minorPlural: 'Baisa',
  },
  PAB: {
    majorSingle: 'Balboa',
    majorPlural: 'Balboa',
    minorSingle: 'Centésimo',
    minorPlural: 'Centésimos',
  },
  PEN: {
    majorSingle: 'Sol',
    majorPlural: 'Soles',
    minorSingle: 'Céntimo',
    minorPlural: 'Céntimos',
  },
  PGK: {
    majorSingle: 'Kina',
    majorPlural: 'Kina',
    minorSingle: 'Toea',
    minorPlural: 'Toea',
  },
  PHP: {
    majorSingle: 'Peso',
    majorPlural: 'Pesos',
    minorSingle: 'Sentimo',
    minorPlural: 'Sentimo',
  },
  PKR: {
    majorSingle: 'Rupee',
    majorPlural: 'Rupees',
    minorSingle: 'Paisa',
    minorPlural: 'Paise',
  },
  PLN: {
    majorSingle: 'Zloty',
    majorPlural: 'Zlotys',
    minorSingle: 'Grosz',
    minorPlural: 'Groszy',
  },
  PND: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  PRB: {
    majorSingle: 'Ruble',
    majorPlural: 'Rubles',
    minorSingle: 'Kopek',
    minorPlural: 'Kopeks',
  },
  PYG: {
    majorSingle: 'Guaraní',
    majorPlural: 'Guaraníes',
    minorSingle: 'Centimo',
    minorPlural: 'Centimos',
  },
  QAR: {
    majorSingle: 'Riyal',
    majorPlural: 'Riyals',
    minorSingle: 'Dirham',
    minorPlural: 'Dirhams',
  },
  RON: {
    majorSingle: 'Leu',
    majorPlural: 'Lei',
    minorSingle: 'Ban',
    minorPlural: 'Bani',
  },
  RSD: {
    majorSingle: 'Dinar',
    majorPlural: 'Dinars',
    minorSingle: 'Para',
    minorPlural: 'Para',
  },
  RUB: {
    majorSingle: 'Ruble',
    majorPlural: 'Rubles',
    minorSingle: 'Kopek',
    minorPlural: 'Kopeks',
  },
  RWF: {
    majorSingle: 'Franc',
    majorPlural: 'Francs',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  SAR: {
    majorSingle: 'Riyal',
    majorPlural: 'Riyals',
    minorSingle: 'Halalah',
    minorPlural: 'Halalahs',
  },
  SBD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  SCR: {
    majorSingle: 'Rupee',
    majorPlural: 'Rupees',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  SDG: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Qirsh',
    minorPlural: 'Qirsh',
  },
  SEK: {
    majorSingle: 'Krona',
    majorPlural: 'Kronor',
    minorSingle: 'Öre',
    minorPlural: 'Öre',
  },
  SGD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  SHP: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Penny',
    minorPlural: 'Pence',
  },
  SLL: {
    majorSingle: 'Leone',
    majorPlural: 'Leones',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  SLS: {
    majorSingle: 'Shilling',
    majorPlural: 'Shillings',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  SOS: {
    majorSingle: 'Shilling',
    majorPlural: 'Shillings',
    minorSingle: 'Senti',
    minorPlural: 'Senti',
  },
  SRD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  SSP: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Qirsh',
    minorPlural: 'Qirsh',
  },
  STN: {
    majorSingle: 'Dobra',
    majorPlural: 'Dobras',
    minorSingle: 'Centimo',
    minorPlural: 'Centimos',
  },
  SVC: {
    majorSingle: 'Colón',
    majorPlural: 'Colones',
    minorSingle: 'Centavo',
    minorPlural: 'Centavos',
  },
  SYP: {
    majorSingle: 'Pound',
    majorPlural: 'Pounds',
    minorSingle: 'Qirsh',
    minorPlural: 'Qirsh',
  },
  SZL: {
    majorSingle: 'Lilangeni',
    majorPlural: 'Emalangeni',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  THB: {
    majorSingle: 'Baht',
    majorPlural: 'Baht',
    minorSingle: 'Satang',
    minorPlural: 'Satang',
  },
  TJS: {
    majorSingle: 'Somoni',
    majorPlural: 'Somoni',
    minorSingle: 'Diram',
    minorPlural: 'Diram',
  },
  TMT: {
    majorSingle: 'Manat',
    majorPlural: 'Manat',
    minorSingle: 'Tenge',
    minorPlural: 'Tenge',
  },
  TND: {
    majorSingle: 'Dinar',
    majorPlural: 'Dinars',
    minorSingle: 'Millime',
    minorPlural: 'Millime',
  },
  TOP: {
    majorSingle: "Pa'anga",
    majorPlural: "Pa'anga",
    minorSingle: 'Seniti',
    minorPlural: 'Seniti',
  },
  TRY: {
    majorSingle: 'Lira',
    majorPlural: 'Lira',
    minorSingle: 'Kuruş',
    minorPlural: 'Kuruş',
  },
  TTD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  TVD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  TWD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  TZS: {
    majorSingle: 'Shilling',
    majorPlural: 'Shillings',
    minorSingle: 'Senti',
    minorPlural: 'Senti',
  },
  UAH: {
    majorSingle: 'Hryvnia',
    majorPlural: 'Hryvnias',
    minorSingle: 'Kopiyka',
    minorPlural: 'kopiyky',
  },
  UGX: {
    majorSingle: 'Shilling',
    majorPlural: 'Shillings',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  USD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  UYU: {
    majorSingle: 'Peso',
    majorPlural: 'Pesos',
    minorSingle: 'Centésimo',
    minorPlural: 'Centésimos',
  },
  UZS: {
    majorSingle: 'Som',
    majorPlural: 'Som',
    minorSingle: 'Tiyin',
    minorPlural: 'Tiyin',
  },
  VED: {
    majorSingle: 'Bolívar Digital',
    majorPlural: 'Bolívars Digital',
    minorSingle: 'Céntimo',
    minorPlural: 'Céntimos',
  },
  VES: {
    majorSingle: 'Bolívar',
    majorPlural: 'Bolívares',
    minorSingle: 'Centimo',
    minorPlural: 'Centimos',
  },
  VND: {
    majorSingle: 'Dong',
    majorPlural: 'Dong',
    minorSingle: 'Hào',
    minorPlural: 'Hào',
  },
  VUV: {
    majorSingle: 'Vatu',
    majorPlural: 'Vatu',
    minorSingle: '',
    minorPlural: '',
  },
  WST: {
    majorSingle: 'Tala',
    majorPlural: 'Tala',
    minorSingle: 'Sene',
    minorPlural: 'Sene',
  },
  XAF: {
    majorSingle: 'Franc',
    majorPlural: 'Francs',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  XCD: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  XOF: {
    majorSingle: 'Franc',
    majorPlural: 'Francs',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  XPF: {
    majorSingle: 'Franc',
    majorPlural: 'Francs',
    minorSingle: 'Centime',
    minorPlural: 'Centimes',
  },
  YER: {
    majorSingle: 'Rial',
    majorPlural: 'Rials',
    minorSingle: 'Fils',
    minorPlural: 'Fils',
  },
  ZAR: {
    majorSingle: 'Rand',
    majorPlural: 'Rand',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  ZMW: {
    majorSingle: 'Kwacha',
    majorPlural: 'Kwacha',
    minorSingle: 'Ngwee',
    minorPlural: 'Ngwee',
  },
  ZWB: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: '',
    minorPlural: '',
  },
  ZWL: {
    majorSingle: 'Dollar',
    majorPlural: 'Dollars',
    minorSingle: 'Cent',
    minorPlural: 'Cents',
  },
  Abkhazia: {
    majorSingle: 'Apsar',
    majorPlural: 'Apsark',
    minorSingle: '',
    minorPlural: '',
  },
  Artsakh: {
    majorSingle: 'Dram',
    majorPlural: 'Dram',
    minorSingle: 'Luma',
    minorPlural: 'Luma',
  },
};
