import { rem } from 'polished';
import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${getTheme('space.6')} ${getTheme('space.6')};
  max-width: ${rem(1590)};
  flex: 1;
`;
