import {
  autoUpdate,
  flip,
  useFloating as useFloatingUI,
  UseFloatingProps,
} from '@floating-ui/react';

import { mergeObjects } from 'helpers/data';

export const useFloating = (props: Partial<UseFloatingProps>) => {
  const options = mergeObjects(props, {
    strategy: 'fixed',
    placement: 'top-start',
    middleware: [flip({ fallbackAxisSideDirection: 'start' })],
    whileElementsMounted: autoUpdate,
  });

  return useFloatingUI(options);
};
