import { useQuery } from '@tanstack/react-query';

import { getPromissoryNotes } from 'api/client/promissoryNote';
import { QueryKeys } from 'api/client/types';

export const usePromissoryNotes = () => {
  return useQuery({
    queryKey: [QueryKeys.PromissoryNotes],
    queryFn: getPromissoryNotes,
  });
};
