import { ReactNode } from 'react';
import useLockBodyScroll from 'react-use/lib/useLockBodyScroll';

import { Backdrop } from 'components/Backdrop';
import { Portal } from 'components/Portal';

import * as Styles from './Dialog.styles';

type Props = {
  children: ReactNode;
};

export const Dialog = (props: Props) => {
  useLockBodyScroll();

  return (
    <Portal target="dialogs">
      <Backdrop>
        <Styles.Container role="dialog">{props.children}</Styles.Container>
      </Backdrop>
    </Portal>
  );
};
