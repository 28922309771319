import { yupResolver } from '@hookform/resolvers/yup';
import { currencyOptions } from 'data/currency';
import { tenorDaysOptions } from 'data/tenorDays';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { MarginItem, MarginRequest } from 'api/client/types';
import { useAddMargin } from 'api/hooks/useAddMargin';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Dialog, DialogActions, DialogTitle } from 'components/Dialog';
import { DropDownMenu } from 'components/DropDownMenu';
import { FormInput } from 'components/Form';
import { getErrorMessage } from 'helpers/error';

import { Content, FormItem, FormLabel } from './EditMarginsDialog.styles';

type Props = {
  investorCompanyProfileId: string;
  marginsData: MarginItem;
  clientPrefix: string;
  onClose: () => void;
};

const marginsSchema = yup.object().shape({
  supplierId: yup.string().required('Supplier is required'),
  supplierCompanyName: yup.string().required(),
  margin: yup
    .number()
    .typeError('Margin is required')
    .integer('Margin must be an integer')
    .min(0, 'Margin must be a valid positive number')
    .max(2147483647, 'Margin must be of a smaller value')
    .required('Margin is required'),
  currency: yup
    .string()
    .oneOf(
      currencyOptions.map((currency) => currency.id),
      'Currency is required'
    )
    .required('Currency is required'),
  tenorDays: yup.string().required('Tenor Days are required'),
});

export const EditMarginsDialog = (props: Props) => {
  const {
    clientPrefix,
    investorCompanyProfileId: id,
    marginsData,
    onClose,
  } = props;

  const {
    error,
    isLoading,
    isSuccess,
    mutateAsync: addMargin,
  } = useAddMargin();

  const initialFormData: MarginRequest = {
    margin: marginsData.margin,
    investorId: marginsData.investorId,
    supplierId: marginsData.supplierId,
    supplierCompanyName: marginsData.supplierCompanyName,
    currency: marginsData.currency,
    tenorDays: marginsData.tenorDays,
    clientPrefix: clientPrefix,
  };

  const {
    formState: { errors, isSubmitting, isValid, touchedFields },
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useForm<MarginRequest>({
    resolver: yupResolver(marginsSchema),
    defaultValues: initialFormData,
    mode: 'onChange',
  });

  const onSubmit = async (data: MarginRequest) => {
    const margin: MarginRequest = {
      supplierId: data.supplierId,
      investorId: id,
      supplierCompanyName: data.supplierCompanyName,
      margin: data.margin,
      currency: data.currency,
      tenorDays: data.tenorDays,
      clientPrefix: data.clientPrefix,
    };

    await addMargin(margin);
    onClose();
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  const defaultCurrency =
    currencyOptions.find((option) => option.id === marginsData.currency)
      ?.name || '';

  return (
    <Dialog>
      <DialogTitle>Update Margin for a Supplier</DialogTitle>
      {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}
      {isSuccess && (
        <Alert severity={'info'}>{'Margin updated successfully'}</Alert>
      )}
      <Content>
        <FormItem>
          <FormLabel>Supplier Company</FormLabel>
          <FormInput
            type="string"
            {...register('supplierId')}
            value={marginsData.supplierCompanyName}
            readOnly
            disabled
          />
        </FormItem>
        <FormItem>
          <FormLabel>Margin Value</FormLabel>
          <FormInput
            type="number"
            min={0}
            placeholder="Enter a Margin"
            {...register('margin')}
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
          />
        </FormItem>
        <FormItem>
          <FormLabel>Currency</FormLabel>
          <DropDownMenu
            options={currencyOptions}
            valueKey="id"
            labelKey="name"
            {...register('currency')}
            onChange={(value) =>
              setValue('currency', value, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }
            defaultValue={defaultCurrency}
          />
        </FormItem>
        <FormItem>
          <FormLabel>Tenor Days</FormLabel>
          <DropDownMenu
            options={tenorDaysOptions}
            valueKey="id"
            labelKey="name"
            onChange={(value) =>
              setValue('tenorDays', value, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }
            defaultValue={marginsData.tenorDays}
          />
        </FormItem>
      </Content>
      {!(Object.keys(touchedFields).length === 0) && errors.supplierId && (
        <Alert>{errors.supplierId.message}</Alert>
      )}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.supplierId &&
        errors.margin && <Alert>{errors.margin.message}</Alert>}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.supplierId &&
        !errors.margin &&
        errors.currency && <Alert>{errors.currency.message}</Alert>}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.supplierId &&
        !errors.margin &&
        !errors.currency &&
        errors.tenorDays && <Alert>{errors.tenorDays.message}</Alert>}
      <DialogActions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading || isSubmitting}
          disabled={!isValid || isLoading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
