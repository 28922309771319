import { ENDPOINT_OVERRIDE_MAP } from 'api/config/ClientConfiguration';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogText,
  DialogTitle,
} from 'components/Dialog';
import { getErrorMessage } from 'helpers/error';
import { Instrument } from 'types/instrument';

type Props = {
  onClose: () => void;
  instrument: Instrument;
  enforceableDate: Date;
};

export const EnforceableTitleObtainedDialog = (props: Props) => {
  const { enforceableDate, instrument, onClose } = props;

  const endpointConfig = ENDPOINT_OVERRIDE_MAP[instrument.getInstrumentType()];
  const {
    error: enforceInstrumentError,
    isLoading: isEnforcingInstrument,
    mutateAsync: enforceInstrument,
  } = endpointConfig.useEnforceInstrument();

  const onConfirm = async () => {
    await enforceInstrument({
      id: instrument.id,
      enforceableDate,
    });
    onClose();
  };

  return (
    <Dialog>
      <DialogTitle>Enforceable Title Obtained</DialogTitle>
      {Boolean(enforceInstrumentError) && (
        <Alert>{getErrorMessage(enforceInstrumentError)}</Alert>
      )}
      <DialogContent>
        <DialogText>
          By clicking Confirm, you are acknowledging date of receipt of an
          Enforceable Title by the Court. Are you sure you want to proceed?
        </DialogText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          size="large"
          onClick={onClose}
          disabled={isEnforcingInstrument}
        >
          Cancel
        </Button>
        <Button
          size="large"
          onClick={onConfirm}
          isLoading={isEnforcingInstrument}
          disabled={Boolean(enforceInstrumentError)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
