import { formatToApiDate } from 'helpers/date';
import { InstrumentHistoryRecord } from 'types/audit';
import { IPromissoryNote } from 'types/promissoryNote';

import { get, post } from './client';
import {
  EnforceInstrumentRequest,
  ListResponse,
  ProtestInstrumentRequest,
} from './types';

export const getPromissoryNotes = async (): Promise<IPromissoryNote[]> => {
  const response = await get('/instruments');

  return response.json();
};

export const getInstrumentById = async (
  id: string
): Promise<IPromissoryNote> => {
  const response = await get(`/instruments/${id}`);

  return response.json();
};

export const retirePromissoryNote = async (id: string) => {
  await post(`/instruments/${id}/retire`);
};

export const approvePromissoryNote = async (id: string) => {
  await post(`/instruments/${id}/approve`);
};

export const rejectPromissoryNote = async (id: string) => {
  await post(`/instruments/${id}/reject`);
};

export const markPromissoryNoteAsPaid = async (id: string) => {
  await post(`/instruments/${id}/expire`);
};

export const checkOutPromissoryNote = async (id: string) => {
  await post(`/instruments/${id}/check-out`);
};

export const checkInPromissoryNote = async (id: string) => {
  await post(`/instruments/${id}/check-in`);
};

export const protestPromissoryNote = async (data: ProtestInstrumentRequest) => {
  const { id, protestReferenceNumber } = data;

  await post(`/instruments/${id}/protest`, {
    body: { protest_number: protestReferenceNumber },
  });
};

export const enforcePromissoryNote = async (data: EnforceInstrumentRequest) => {
  const { enforceableDate, id } = data;

  await post(`/instruments/${id}/enforce`, {
    body: { enforceable: formatToApiDate(enforceableDate) },
  });
};

export const getPromissoryNoteHistory = async (id: string) => {
  const response = await get(`/instruments/${id}/audit`);
  const data: ListResponse<InstrumentHistoryRecord> = await response.json();

  return data.found;
};
