import { rem } from 'polished';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100vh;
  min-width: ${rem(1280)};
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
