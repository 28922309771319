import { ApiError } from 'api/client/ApiError';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong.';

const isGenericError = (error: unknown): error is Error => {
  return error instanceof Error;
};

const isApiError = (error: unknown): error is ApiError => {
  return error instanceof ApiError;
};

export const isBadRequest = (error: unknown) => {
  return isApiError(error) && error.status === 400;
};

export const isUnauthorized = (error: unknown) => {
  return isApiError(error) && error.status === 401;
};

export const isNotFound = (error: unknown) => {
  return isApiError(error) && error.status === 404;
};

export const getErrorMessage = (error?: unknown) => {
  if (!error) {
    return '';
  }

  if (isApiError(error) || isGenericError(error)) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  return DEFAULT_ERROR_MESSAGE;
};
