import styled, { keyframes } from 'styled-components/macro';

import { getTheme } from 'styles';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span`
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 0.125em solid currentColor;
  border-bottom-color: transparent;
  border-radius: ${getTheme('radius.full')};
  animation: ${rotate} 1s linear infinite;
`;
