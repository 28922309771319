import { rem } from 'polished';
import styled from 'styled-components/macro';

import { Input } from 'components/Input';
import { getTheme } from 'styles';

export const InputLabel = styled.label`
  display: block;
  font-weight: 500;
  font-size: ${getTheme('fontSizes.base')};
  line-height: ${getTheme('lineHeights.3')};
  padding: 0 ${getTheme('space.2')};
`;

export const Description = styled.p`
  font-size: ${getTheme('fontSizes.base')};
  line-height: ${getTheme('lineHeights.3')};
  font-weight: 500;
  margin-bottom: ${getTheme('space.8')};
`;

export const Form = styled.form``;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const FormControl = styled.div`
  margin-bottom: ${getTheme('space.4')};
`;

export const FormLabel = styled.label`
  display: block;
  font-weight: 500;
  font-size: ${getTheme('fontSizes.base')};
  line-height: ${getTheme('lineHeights.3')};
  padding-bottom: ${getTheme('space.2')};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DisbursementDateInput = styled(Input)`
  width: ${rem(170)};
  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  &::placeholder {
    font-weight: 100;
    color: ${getTheme('colors.black.10')};
  }
`;
