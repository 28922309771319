import { Invoice } from './invoice';
import { SupplierBankAccount } from './supplierBankAccount';

export type InstumentState =
  | 'minted'
  | 'endorsed'
  | 'active'
  | 'declined'
  | 'rejected'
  | 'cancelled'
  | 'retired'
  | 'matured'
  | 'expired'
  | 'protested'
  | 'enforced';

export enum InstrumentType {
  BILL_OF_EXCHANGE = 'Bill of Exchange',
  PROMISSORY_NOTE = 'Promissory Note',
}

export enum InstrumentTypeAbbreviation {
  dboe = 'dboe',
  dpn = 'dpn',
}

export interface Instrument {
  id: string;
  clientPrefix: string;
  buyerCompanyProfileId: string;
  buyerUserProfileId?: string;
  investorCompanyProfileId: string;
  investorUserProfileId?: string;
  supplierCompanyProfileId?: string;
  supplierUserProfileId?: string;
  state: InstumentState;
  archived: boolean;
  remitId: string;
  reference: string;
  title: string;
  buyerName: string;
  investorName: string;
  maturity: string;
  amount: string;
  currency: string;
  jurisdiction: string;
  urlToDboe: null | string;
  thumbnail: null;
  type: string;
  issuedDate: string;
  hash: string;
  pdfHash: string;
  investmentDate: string;
  issuerName: string;
  issuancePlace: string;
  paymentPlace: string;
  checkedOut: boolean;
  protestNumber: null | string;
  enforceableDate: null | string;
  hasApproved: boolean;
  fundBy?: string;
  invoices: Invoice[];
  supplierBankAccount: SupplierBankAccount;

  isPending: () => boolean;
  isDarkStyle: () => boolean;

  getInstrumentType: () => InstrumentType;
  getInstrumentTypeAbbreviation: () => InstrumentTypeAbbreviation;
}
