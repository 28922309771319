import { useQuery } from '@tanstack/react-query';

import { getBillOfExchangeHistory } from 'api/client/billOfExchange';
import { QueryKeys } from 'api/client/types';

export const useBillOfExchangeHistory = (id: string) => {
  return useQuery({
    queryKey: [QueryKeys.PromissoryNoteHistory, id],
    queryFn: () => getBillOfExchangeHistory(id),
  });
};
