import { ReactNode } from 'react';

import * as Styles from './BackLink.styles';

type Props = {
  children?: ReactNode;
};

export const BackLink = (props: Props) => {
  return (
    <Styles.BackLink to="..">
      <Styles.BackLinkIcon />
      {props.children ?? 'Back'}
    </Styles.BackLink>
  );
};
