import { rem } from 'polished';
import styled from 'styled-components/macro';

import { NavLink } from 'components/NavLink';
import { getTheme } from 'styles';

export const Items = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${getTheme('space.6')};
`;

export const Item = styled.li``;

export const ItemLink = styled(NavLink)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: ${getTheme('space.6')};
  padding: ${getTheme('space.6')};
  border-radius: ${getTheme('radius.lg')};
  border: 1px solid ${getTheme('colors.greyBorder')};
`;

export const ItemIcon = styled.span`
  display: inline-flex;
  font-size: ${rem(48)};
  padding: ${getTheme('space.1')};
  background-color: ${getTheme('colors.black.5')};
  border: 1px solid ${getTheme('colors.greyBorder')};
  border-radius: ${getTheme('radius.full')};
`;

export const ItemDetails = styled.div``;

export const ItemTitle = styled.h2`
  font-weight: 600;
  font-size: ${getTheme('fontSizes.xl')};
  line-height: ${getTheme('lineHeights.3')};
`;

export const ItemDescription = styled.p`
  padding-top: ${getTheme('space.2')};
  color: ${getTheme('colors.black.50')};
  line-height: ${getTheme('lineHeights.3')};
`;

export const ChevronIcon = styled.span`
  display: inline-flex;
  font-size: ${rem(32)};
  color: ${getTheme('colors.black.50')};
`;
