import { CloseIcon } from 'components/Icon';

import * as Styles from './CloseButton.styles';

export const CloseButton = () => {
  return (
    <Styles.CloseLink to="..">
      <CloseIcon />
    </Styles.CloseLink>
  );
};
