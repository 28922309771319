import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';

import { useActiveBillsOfExchange } from 'hooks/BillOfExchange/useActiveBillsOfExchange';
import { useArchivedBillsOfExchange } from 'hooks/BillOfExchange/useArchivedBillsOfExchange';
import { usePendingBillsOfExchange } from 'hooks/BillOfExchange/usePendingBillsOfExchange';
import { useActivePromissoryNotes } from 'hooks/PromissoryNotes/useActivePromissoryNotes';
import { useArchivedPromissoryNotes } from 'hooks/PromissoryNotes/useArchivedPromissoryNotes';
import { usePendingPromissoryNotes } from 'hooks/PromissoryNotes/usePendingPromissoryNotes';
import { Instrument } from 'types/instrument';

export type InstrumentsSummary = {
  numberOfInstruments: number;
  totalAmount: number;
  numberOfBuyers: number;
};

const getTotalInstrumentAmount = (instruments: Instrument[]) => {
  return sumBy(instruments, (instrument) => parseFloat(instrument.amount));
};

const getTotalNumberOfInstrumentBuyers = (instruments: Instrument[]) => {
  return uniqBy(instruments, (instrument) => instrument.buyerUserProfileId)
    .length;
};

const getSummaryOfInstruments = (
  instruments: Instrument[]
): InstrumentsSummary => {
  return {
    numberOfInstruments: instruments.length,
    totalAmount: getTotalInstrumentAmount(instruments),
    numberOfBuyers: getTotalNumberOfInstrumentBuyers(instruments),
  };
};

export const useInstrumentsSummary = () => {
  const { instruments: pendingPromissoryNotes } = usePendingPromissoryNotes();
  const { instruments: activePromissoryNotes } = useActivePromissoryNotes();
  const { instruments: archivedPromissoryNotes } = useArchivedPromissoryNotes();

  const { instruments: pendingBillsOfExchange } = usePendingBillsOfExchange();
  const { instruments: activeBillsOfExchange } = useActiveBillsOfExchange();
  const { instruments: archivedBillsOfExchange } = useArchivedBillsOfExchange();

  const summary = useMemo(() => {
    return {
      numberOfPendingInstruments: [
        ...pendingPromissoryNotes,
        ...pendingBillsOfExchange,
      ].length,
      active: getSummaryOfInstruments([
        ...activePromissoryNotes,
        ...activeBillsOfExchange,
      ]),
      archived: getSummaryOfInstruments([
        ...archivedPromissoryNotes,
        ...archivedBillsOfExchange,
      ]),
    };
  }, [
    pendingPromissoryNotes,
    activePromissoryNotes,
    archivedPromissoryNotes,
    pendingBillsOfExchange,
    activeBillsOfExchange,
    archivedBillsOfExchange,
  ]);

  return { summary };
};
