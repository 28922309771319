import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const List = styled.ul`
  border: 1px solid ${getTheme('colors.greyBorder')};
  border-radius: ${getTheme('radius.lg')};
  padding-left: ${getTheme('space.6')};
`;

export const ListItem = styled.li`
  :not(:last-child) {
    border-bottom: 1px solid ${getTheme('colors.greyBorder')};
  }
`;

export const ListItemDetails = styled.div``;

export const ListItemTitle = styled.h3`
  font-size: ${getTheme('fontSizes.base')};
  font-weight: 600;
  line-height: ${getTheme('lineHeights.3')};
`;

export const ListItemDescription = styled.p`
  color: ${getTheme('colors.black.50')};
  padding-top: ${getTheme('space.1')};
  font-size: ${getTheme('fontSizes.sm')};
  line-height: ${getTheme('lineHeights.3')};
`;
