import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useConfirmPassword } from 'api/hooks/useConfirmPassword';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { FormInput } from 'components/Form/FormInput';
import { useMultiStepView } from 'components/MultiStepView';
import { getErrorMessage } from 'helpers/error';
import { silentSubmit } from 'helpers/form';
import { loginPasswordValidation } from 'helpers/validations';

import * as Styles from './ConfirmPassword.styles';
import * as SharedStyles from './styles';
import { EnterEmailFormData } from './types';

type FormData = {
  code: string;
  newPassword: string;
  confirmPassword: string;
};

const validation: yup.ObjectSchema<FormData> = yup
  .object({
    code: yup.string().trim().required(),
    newPassword: loginPasswordValidation.required('New password is missing.'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword')], 'Passwords do not match.')
      .required(),
  })
  .required('New password is missing.');

type Props = {
  enterEmailFormData: EnterEmailFormData;
};

export const ConfirmPassword = (props: Props) => {
  const { enterEmailFormData } = props;
  const { setView } = useMultiStepView();

  const {
    control,
    formState: { errors, isSubmitSuccessful, isSubmitting, isValid },
    handleSubmit,
    register,
  } = useForm<FormData>({
    resolver: yupResolver(validation),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    error: confirmPasswordError,
    isSuccess,
    mutateAsync: confirmPassword,
  } = useConfirmPassword();

  useEffect(() => {
    if (isSubmitSuccessful && isSuccess) {
      setView('confirm-success');
    }
  }, [isSubmitSuccessful, setView, isSuccess]);

  const onSubmit = async (data: FormData) => {
    await confirmPassword({
      ...enterEmailFormData,
      code: data.code,
      password: data.confirmPassword,
    });
  };

  return (
    <>
      {Boolean(confirmPasswordError) && (
        <Alert>{getErrorMessage(confirmPasswordError)}</Alert>
      )}
      <SharedStyles.Title>Create a new password</SharedStyles.Title>
      <Styles.Description>
        A 6-digit one-time passcode has been sent to {enterEmailFormData.email}
      </Styles.Description>
      <SharedStyles.Form onSubmit={handleSubmit(silentSubmit(onSubmit))}>
        <SharedStyles.FormControl>
          <SharedStyles.FormLabel htmlFor="verification-code">
            Please enter the one-time passcode here.
          </SharedStyles.FormLabel>
          <Controller
            control={control}
            name="code"
            render={({ field }) => (
              <Styles.CodeInput
                id="verification-code"
                {...omit(field, 'ref')}
              />
            )}
          />
        </SharedStyles.FormControl>

        <SharedStyles.FormControl>
          <SharedStyles.FormLabel htmlFor="new-password">
            New Password
          </SharedStyles.FormLabel>
          <FormInput
            type="password"
            id="new-password"
            placeholder="Enter new password"
            autoComplete="new-password"
            size="medium"
            {...register('newPassword')}
          />
        </SharedStyles.FormControl>

        <SharedStyles.FormControl>
          <SharedStyles.FormLabel htmlFor="confirm-password">
            Confirm Password
          </SharedStyles.FormLabel>
          <FormInput
            type="password"
            id="confirm-password"
            placeholder="Confirm new password"
            autoComplete="confirm-password"
            size="medium"
            {...register('confirmPassword')}
          />
        </SharedStyles.FormControl>

        {Boolean(errors.newPassword) && (
          <Alert>{getErrorMessage(errors.newPassword?.message)}</Alert>
        )}

        {errors.newPassword === undefined &&
          Boolean(errors.confirmPassword) && (
            <Alert>{getErrorMessage(errors.confirmPassword?.message)}</Alert>
          )}

        <Styles.Actions>
          <Button
            type="button"
            size="medium"
            variant="secondary"
            onClick={() => setView('reset-password')}
          >
            Back
          </Button>
          <Button
            type="submit"
            size="medium"
            disabled={!isValid || isSubmitting}
          >
            Submit
          </Button>
        </Styles.Actions>
      </SharedStyles.Form>
    </>
  );
};
