import { rem } from 'polished';
import styled, { css } from 'styled-components/macro';

import { Spinner } from 'components/Spinner';
import { getTheme } from 'styles';

type ButtonProps = {
  variant: 'primary' | 'secondary';
  size: 'small' | 'medium' | 'large';
  $fullWidth?: boolean;
  $isLoading: boolean;
};

export const Button = styled.button<ButtonProps>`
  display: inline-flex;
  column-gap: ${getTheme('space.2')};
  align-items: center;
  justify-content: center;
  line-height: ${getTheme('lineHeights.3')};
  font-weight: 500;
  border-radius: ${getTheme('radius.md')};
  border: 1px solid ${getTheme('colors.green.base')};

  &:disabled {
    pointer-events: none;

    ${(props) =>
      !props.$isLoading &&
      css`
        opacity: 0.25;
      `}
  }

  ${Spinner} {
    font-size: ${rem(24)};
  }

  ${(props) =>
    props.variant === 'primary' &&
    css`
      background-color: ${getTheme('colors.green.base')};

      && {
        color: ${getTheme('colors.white.100')};
      }

      &:hover {
        background-color: ${getTheme('colors.green.dark02')};
      }
    `}

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      background-color: ${getTheme('colors.white.100')};

      && {
        color: ${getTheme('colors.green.base')};
      }

      &:hover {
        background-color: ${getTheme('colors.greyBorder')};
      }
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${getTheme('fontSizes.sm')};
      min-height: ${rem(40)};
      padding: 0 ${getTheme('space.2')};
    `}

  ${(props) =>
    props.size === 'medium' &&
    css`
      font-size: ${getTheme('fontSizes.sm')};
      min-height: ${rem(40)};
      padding: 0 ${getTheme('space.6')};
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      font-size: ${getTheme('fontSizes.base')};
      min-height: ${rem(56)};
      padding: 0 ${getTheme('space.3')};
    `}

  ${(props) =>
    props.$fullWidth &&
    css`
      width: 100%;
    `}
`;

export const Icon = styled.span`
  display: inline-flex;
  font-size: ${rem(24)};
`;
