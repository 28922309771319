import { useQuery } from '@tanstack/react-query';

import { getMargins } from 'api/client/margins';
import { QueryKeys } from 'api/client/types';
import { User } from 'types/user';

export const useMargins = (profile: User) => {
  return useQuery({
    queryKey: [QueryKeys.Margins],
    queryFn: () => getMargins(profile.company_profile_id),
  });
};
