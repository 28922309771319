import capitalize from 'lodash/capitalize';

import { Tag } from 'components/Tag';
import { isCheckedOut } from 'helpers/instrument';
import { Instrument, InstumentState } from 'types/instrument';

import * as Styles from './InstrumentStatus.styles';

const getStatusLabel = (status: InstumentState) => {
  if (status === 'enforced') {
    return 'Enforceable Title Obtained';
  }

  return capitalize(status);
};

type Props = {
  instrument: Instrument;
};

export const InstrumentStatus = (props: Props) => {
  const { checkedOut = false, state } = props.instrument;

  if (isCheckedOut(props.instrument)) {
    return (
      <Tag size="large">
        <Styles.StatusDot
          isCheckedOut={Boolean(checkedOut)}
          isDarkStyle={props.instrument.isDarkStyle()}
        />
        Checked out
      </Tag>
    );
  }

  return (
    <Tag size="large">
      <Styles.StatusDot
        status={state}
        isDarkStyle={props.instrument.isDarkStyle()}
        isPending={props.instrument.isPending()}
      />
      {getStatusLabel(state)}
    </Tag>
  );
};
