import { useQuery } from '@tanstack/react-query';

import { getProviders } from 'api/client/dataVisibility';
import { QueryKeys } from 'api/client/types';
import { User } from 'types/user';

export const useProviders = (profile: User) => {
  return useQuery({
    queryKey: [QueryKeys.DataVisibility],
    queryFn: () => getProviders(profile.company_profile_id),
  });
};
