import isEmpty from 'lodash/isEmpty';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ENDPOINT_OVERRIDE_MAP } from 'api/config/ClientConfiguration';
import { Alert } from 'components/Alert';
import { ApproveDBoEDialog } from 'components/ApproveDBoEDialog';
import { ApproveDPNDialog } from 'components/ApproveDPNDialog';
import { CheckOutInstrumentDialog } from 'components/CheckOutInstrumentDialog';
import { NoteIcon } from 'components/Icon';
import { ChevronDownIcon, ChevronRightIcon } from 'components/Icon';
import { InstrumentStatus } from 'components/InstrumentStatus';
import { PageContent } from 'components/PageContent';
import {
  BackLink,
  PageHeaderDetails,
  PageHeaderIcon,
} from 'components/PageHeader';
import { PageLoader } from 'components/PageLoader';
import { RejectInstrumentDialog } from 'components/RejectInstrumentDialog';
import { RetireInstrumentDialog } from 'components/RetireInstrumentDialog';
import { Tag } from 'components/Tag';
import { useUser } from 'components/UserProvider';
import { convertMaturityToMin, formatDate, parseApiDate } from 'helpers/date';
import { downloadExternalFile } from 'helpers/download';
import { getErrorMessage } from 'helpers/error';
import { isCheckedOut, isExpiredDate } from 'helpers/instrument';
import { formatMoneyAmount, formatMoneyToWords } from 'helpers/money';
import { InstrumentType } from 'types/instrument';

import { EnforceableTitleObtainedDialog } from './EnforceableTitleObtainedDialog';
import * as Styles from './InstrumentDetails.styles';
import { NotePdfPreviewDialog } from './NotePdfPreviewDialog';
import { ProtestInstrumentDialog } from './ProtestInstrumentDialog';
import TypeSpecificDetails from './TypeSpecificDetails';

const MAXIMUM_ENFORCEABLE_TITLE_DATE = '2100-01-01';

type RouterParams = {
  id: string;
};

type Props = {
  mode: InstrumentType;
};

export const InstrumentDetails = (props: Props) => {
  const { id = '' } = useParams<RouterParams>();

  const { mode } = props;
  const endpointConfig = ENDPOINT_OVERRIDE_MAP[mode];

  const {
    data: instrument,
    error: instrumentError,
    isLoading: instrumentLoading,
  } = endpointConfig.useInstrument(id);

  const {
    error: markInstrumentAsPaidError,
    isLoading: isMarkingInstrumentAsPaid,
    mutate: markInstrumentAsPaid,
  } = endpointConfig.useMarkInstrumentAsPaid();

  const [
    isApproveInstrumentDialogDisplayed,
    setIsApproveInstrumentDialogDisplayed,
  ] = useState(false);
  const [
    isRetireInstrumentDialogDisplayed,
    setIsRetireInstrumentDialogDisplayed,
  ] = useState(false);
  const [
    isRejectInstrumentDialogDisplayed,
    setIsRejectInstrumentDialogDisplayed,
  ] = useState(false);
  const [
    isCheckOutInstrumentDialogDisplayed,
    setIsCheckOutInstrumentDialogDisplayed,
  ] = useState(false);
  const [
    isProtestInstrumentDialogDisplayed,
    setIsProtestInstrumentDialogDisplayed,
  ] = useState(false);
  const [
    isEnforceableTitleObtainedDialogDisplayed,
    setIsEnforceableTitleObtainedDialogDisplayed,
  ] = useState(false);
  const [isNotePdfPreviewDialogDisplayed, setIsNotePdfPreviewDialogDisplayed] =
    useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [enforceableTitleDate, setEnforceableTitleDate] = useState('');
  const [openSupplierBankAccount, setOpenSupplierBankAccount] = useState(false);
  const handleOpenSupplierBankAccount = () => {
    setOpenSupplierBankAccount(!openSupplierBankAccount);
  };
  const { isInvestor } = useUser();
  const [isConfirmed, setInputEnforcementDateConfirmed] = useState(false);

  const error = instrumentError || markInstrumentAsPaidError;
  const isActingOnInstrument = isMarkingInstrumentAsPaid;
  const isLoading = instrumentLoading;
  const isEnforceableTitleDateValid = (date: string, maturityDate: string) => {
    return (
      new Date(parseApiDate(maturityDate)) < new Date(date) &&
      new Date(date) < new Date(MAXIMUM_ENFORCEABLE_TITLE_DATE)
    );
  };

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <Styles.Page instrument={instrument}>
        <Styles.PageHeader>
          <BackLink />
          {instrument && (
            <>
              <PageHeaderDetails>
                <PageHeaderIcon>
                  <NoteIcon />
                </PageHeaderIcon>
                <Styles.PageHeaderTitle>
                  {instrument.title}
                </Styles.PageHeaderTitle>
                <InstrumentStatus instrument={instrument} />

                <Styles.HistoryLink to="history">
                  View history
                </Styles.HistoryLink>
              </PageHeaderDetails>
            </>
          )}
        </Styles.PageHeader>

        <PageContent>
          {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}

          {instrument && (
            <Styles.ContentContainer>
              <Styles.PreviewContainer>
                {isInvestor && (
                  <>
                    <Styles.InstrumentPreview
                      instrument={instrument}
                      $isClickable={Boolean(instrument.urlToDboe)}
                      onClick={() => setIsNotePdfPreviewDialogDisplayed(true)}
                    />

                    {instrument.urlToDboe && (
                      <>
                        <Styles.DownloadCopyButton
                          size="large"
                          variant="secondary"
                          fullWidth
                          onClick={() =>
                            instrument.urlToDboe &&
                            downloadExternalFile(instrument.urlToDboe)
                          }
                        >
                          Download copy
                        </Styles.DownloadCopyButton>
                      </>
                    )}
                  </>
                )}

                {!isInvestor && (
                  <Styles.InstrumentPreview instrument={instrument} />
                )}
              </Styles.PreviewContainer>

              <Styles.InstrumentContent isCheckedOut={isCheckedOut(instrument)}>
                {instrument.state === 'protested' && (
                  <Tag>
                    Reference number:{' '}
                    <strong>{instrument.protestNumber}</strong>
                  </Tag>
                )}

                <Styles.InstrumentDetailsContainer>
                  <Styles.InstrumentDetails>
                    {!isCheckedOut(instrument) && instrument.isPending() && (
                      <>
                        <Styles.InstrumentDetailsApprovalKey>
                          Approval
                        </Styles.InstrumentDetailsApprovalKey>
                        <Styles.InstrumentDetailsApprovalValue>
                          <Styles.ApprovalTag>
                            Pending Approval
                          </Styles.ApprovalTag>
                        </Styles.InstrumentDetailsApprovalValue>
                      </>
                    )}
                    <Styles.InstrumentDetailsKey>
                      Name of Issuer
                    </Styles.InstrumentDetailsKey>
                    <Styles.InstrumentDetailsValue>
                      {instrument.issuerName}
                    </Styles.InstrumentDetailsValue>
                    <Styles.InstrumentDetailsKey>
                      Date of Issuance
                    </Styles.InstrumentDetailsKey>
                    <Styles.InstrumentDetailsValue>
                      {formatDate(parseApiDate(instrument.issuedDate))}
                    </Styles.InstrumentDetailsValue>
                    <Styles.InstrumentDetailsKey>
                      Principal amount
                    </Styles.InstrumentDetailsKey>
                    <Styles.InstrumentDetailsValue>
                      {formatMoneyAmount(instrument.amount, {
                        currency: instrument.currency,
                      })}
                      <br />{' '}
                      <Styles.InstrumentFormattedAmount>
                        {formatMoneyToWords(
                          instrument.amount,
                          instrument.currency
                        )}
                      </Styles.InstrumentFormattedAmount>
                    </Styles.InstrumentDetailsValue>
                    <Styles.InstrumentDetailsKey>
                      Maturity date
                    </Styles.InstrumentDetailsKey>
                    <Styles.InstrumentDetailsValue>
                      {formatDate(parseApiDate(instrument.maturity))}
                    </Styles.InstrumentDetailsValue>
                    <Styles.InstrumentDetailsKey>
                      Fund by date
                    </Styles.InstrumentDetailsKey>
                    <Styles.InstrumentDetailsValue>
                      {instrument.fundBy
                        ? formatDate(parseApiDate(instrument.fundBy))
                        : '-'}
                    </Styles.InstrumentDetailsValue>
                    <Styles.InstrumentDetailsKey>
                      Maker
                    </Styles.InstrumentDetailsKey>
                    <Styles.InstrumentDetailsValue>
                      {instrument.buyerName}
                    </Styles.InstrumentDetailsValue>
                    <Styles.InstrumentDetailsKey>
                      Investor name
                    </Styles.InstrumentDetailsKey>
                    <Styles.InstrumentDetailsValue>
                      {instrument.investorName}
                    </Styles.InstrumentDetailsValue>
                    <Styles.InstrumentDetailsKey>
                      Place of issuance
                    </Styles.InstrumentDetailsKey>
                    <Styles.InstrumentDetailsValue>
                      {instrument.issuancePlace}
                    </Styles.InstrumentDetailsValue>
                    <Styles.InstrumentDetailsKey>
                      Place for payment
                    </Styles.InstrumentDetailsKey>
                    <Styles.InstrumentDetailsValue>
                      {instrument.paymentPlace}
                    </Styles.InstrumentDetailsValue>
                    <Styles.InstrumentDetailsKey>
                      Reference
                    </Styles.InstrumentDetailsKey>
                    <Styles.InstrumentDetailsValue>
                      {instrument.reference}
                    </Styles.InstrumentDetailsValue>

                    {TypeSpecificDetails(instrument)}

                    {instrument.state === 'enforced' && (
                      <>
                        <Styles.InstrumentDetailsSecondaryKey>
                          Enforceable Title
                        </Styles.InstrumentDetailsSecondaryKey>
                        <Styles.InstrumentDetailsEnforceableTitleValue>
                          <Styles.InstrumentNestedDetails>
                            <Styles.InstrumentNestedDetailsKey>
                              Date obtained
                            </Styles.InstrumentNestedDetailsKey>
                            <Styles.InstrumentNestedDetailsValue>
                              {instrument.enforceableDate &&
                                formatDate(
                                  parseApiDate(instrument.enforceableDate)
                                )}
                            </Styles.InstrumentNestedDetailsValue>
                          </Styles.InstrumentNestedDetails>
                        </Styles.InstrumentDetailsEnforceableTitleValue>
                      </>
                    )}

                    {!isEmpty(instrument.invoices) && (
                      <>
                        <Styles.InstrumentDetailsSecondaryKey>
                          Composing invoice(s)
                        </Styles.InstrumentDetailsSecondaryKey>
                        <Styles.InstrumentDetailsInvoicesValue>
                          <Styles.InstrumentNestedDetails>
                            <Styles.InvoicesTitle>Name</Styles.InvoicesTitle>
                            <Styles.InvoicesTitle>Value</Styles.InvoicesTitle>

                            {instrument.invoices.map((invoice, index) => (
                              <Fragment key={`${invoice.name}-${index}`}>
                                <Styles.InvoiceName>
                                  {invoice.name}
                                </Styles.InvoiceName>
                                <Styles.InvoiceAmount>
                                  {formatMoneyAmount(invoice.amount, {
                                    currency: invoice.currency,
                                  })}
                                </Styles.InvoiceAmount>
                              </Fragment>
                            ))}
                          </Styles.InstrumentNestedDetails>
                        </Styles.InstrumentDetailsInvoicesValue>
                      </>
                    )}

                    {!Object.values(instrument.supplierBankAccount).every(
                      (field) => field.trim() === ''
                    ) && (
                      <>
                        <Styles.InstrumentDetailsKey>
                          <Styles.SupplierBankAccountTitle
                            onClick={handleOpenSupplierBankAccount}
                          >
                            View Supplier Bank Account
                            <Styles.ChevronIcon>
                              {openSupplierBankAccount ? (
                                <ChevronDownIcon />
                              ) : (
                                <ChevronRightIcon />
                              )}
                            </Styles.ChevronIcon>
                          </Styles.SupplierBankAccountTitle>
                        </Styles.InstrumentDetailsKey>

                        {openSupplierBankAccount && (
                          <Styles.InstrumentNestedDetails>
                            <Styles.SupplierBankAccountKey>
                              Bank name
                            </Styles.SupplierBankAccountKey>
                            <Styles.SupplierBankAccountValue>
                              {instrument.supplierBankAccount
                                .supplierBankName || '-'}
                            </Styles.SupplierBankAccountValue>
                            <Styles.SupplierBankAccountKey>
                              Account
                            </Styles.SupplierBankAccountKey>
                            <Styles.SupplierBankAccountValue>
                              {instrument.supplierBankAccount.supplierAccount ||
                                '-'}
                            </Styles.SupplierBankAccountValue>
                            <Styles.SupplierBankAccountKey>
                              Sort code
                            </Styles.SupplierBankAccountKey>
                            <Styles.SupplierBankAccountValue>
                              {instrument.supplierBankAccount
                                .supplierSortCode || '-'}
                            </Styles.SupplierBankAccountValue>
                            <Styles.SupplierBankAccountKey>
                              IBAN
                            </Styles.SupplierBankAccountKey>
                            <Styles.SupplierBankAccountValue>
                              {instrument.supplierBankAccount.supplierIban ||
                                '-'}
                            </Styles.SupplierBankAccountValue>
                            <Styles.SupplierBankAccountKey>
                              BIC
                            </Styles.SupplierBankAccountKey>
                            <Styles.SupplierBankAccountValue>
                              {instrument.supplierBankAccount.supplierBic ||
                                '-'}
                            </Styles.SupplierBankAccountValue>
                          </Styles.InstrumentNestedDetails>
                        )}
                      </>
                    )}
                  </Styles.InstrumentDetails>
                </Styles.InstrumentDetailsContainer>
              </Styles.InstrumentContent>
            </Styles.ContentContainer>
          )}
        </PageContent>

        {instrument && isInvestor && (
          <>
            {!isCheckedOut(instrument) && (
              <>
                {instrument.isPending() && (
                  <Styles.PageFooter>
                    <Styles.ActionButton
                      size="large"
                      variant="secondary"
                      disabled={isActingOnInstrument}
                      onClick={() => setIsRejectInstrumentDialogDisplayed(true)}
                    >
                      Reject
                    </Styles.ActionButton>
                    <Styles.ActionButton
                      size="large"
                      disabled={isActingOnInstrument}
                      onClick={() =>
                        setIsApproveInstrumentDialogDisplayed(true)
                      }
                    >
                      Approve
                    </Styles.ActionButton>
                  </Styles.PageFooter>
                )}

                {instrument.state === 'active' && (
                  <Styles.PageFooter>
                    {/*
                    // Temporarily removing this test until check out to paper functionality is finalised
                    <Styles.ActionButton
                      size="large"
                      onClick={() =>
                        setIsCheckOutInstrumentDialogDisplayed(true)
                      }
                      disabled={isActingOnInstrument}
                    >
                      Check out to Paper
                    </Styles.ActionButton> */}
                    <Styles.ActionButton
                      size="large"
                      onClick={() => setIsRetireInstrumentDialogDisplayed(true)}
                      disabled={isActingOnInstrument}
                    >
                      Retire
                    </Styles.ActionButton>
                  </Styles.PageFooter>
                )}

                {instrument.state === 'matured' && (
                  <Styles.PageFooter>
                    {!isExpiredDate(parseApiDate(instrument.maturity)) && (
                      <Styles.ActionForm
                        onSubmit={(event) => {
                          event.preventDefault();
                          setIsProtestInstrumentDialogDisplayed(true);
                        }}
                      >
                        <Styles.ReferenceNumberInput
                          placeholder="Enter protest reference number"
                          onChange={(event) =>
                            setReferenceNumber(event.target.value)
                          }
                          value={referenceNumber}
                        />
                        <Styles.ActionButton
                          size="large"
                          disabled={
                            isActingOnInstrument || !referenceNumber.trim()
                          }
                        >
                          Protest
                        </Styles.ActionButton>
                      </Styles.ActionForm>
                    )}

                    <Styles.ActionButton
                      size="large"
                      disabled={isActingOnInstrument}
                      isLoading={isMarkingInstrumentAsPaid}
                      onClick={() => markInstrumentAsPaid(instrument.id)}
                    >
                      Mark as paid
                    </Styles.ActionButton>
                  </Styles.PageFooter>
                )}

                {instrument.state === 'protested' && (
                  <Styles.PageFooter>
                    <Styles.ActionFormContainer>
                      <Styles.ActionFormHelperText>
                        Enforceable Title obtained by court on
                      </Styles.ActionFormHelperText>
                      <Styles.ActionForm
                        onSubmit={(event) => {
                          event.preventDefault();

                          if (
                            isEnforceableTitleDateValid(
                              enforceableTitleDate,
                              instrument.maturity
                            )
                          ) {
                            setIsEnforceableTitleObtainedDialogDisplayed(true);
                            setInputEnforcementDateConfirmed(true);
                          }
                        }}
                      >
                        <Styles.EnforceableTitleDateInput
                          type="date"
                          required
                          onChange={(event) => {
                            setEnforceableTitleDate(event.target.value);
                            setInputEnforcementDateConfirmed(false);
                          }}
                          value={enforceableTitleDate}
                          min={
                            isConfirmed
                              ? convertMaturityToMin(instrument.maturity)
                              : undefined
                          }
                          max={MAXIMUM_ENFORCEABLE_TITLE_DATE}
                          placeholder="Enforceable title obtained on"
                        />
                        <Styles.ActionButton
                          size="large"
                          disabled={
                            isActingOnInstrument ||
                            !enforceableTitleDate ||
                            !isEnforceableTitleDateValid(
                              enforceableTitleDate,
                              instrument.maturity
                            )
                          }
                        >
                          Confirm
                        </Styles.ActionButton>
                      </Styles.ActionForm>
                    </Styles.ActionFormContainer>
                    <Styles.ActionButton
                      size="large"
                      disabled={isActingOnInstrument}
                      isLoading={isMarkingInstrumentAsPaid}
                      onClick={() => markInstrumentAsPaid(instrument.id)}
                    >
                      Mark as paid
                    </Styles.ActionButton>
                  </Styles.PageFooter>
                )}
              </>
            )}

            {/* TODO: Re-enable check in when correctly defined*/}
            {/* {isCheckedOut(instrument) && (
              <Styles.PageFooter>
                <Styles.ActionButton
                  size="large"
                  disabled={isActingOnInstrument}
                  isLoading={isCheckingInInstrument}
                  onClick={() => checkInInstrument(instrument.id)}
                >
                  Check back in
                </Styles.ActionButton>
              </Styles.PageFooter>
            )} */}
          </>
        )}
      </Styles.Page>

      {instrument && (
        <>
          {isApproveInstrumentDialogDisplayed &&
            (instrument.getInstrumentType() ===
            InstrumentType.PROMISSORY_NOTE ? (
              <ApproveDPNDialog
                instrumentId={instrument.id}
                onClose={() => setIsApproveInstrumentDialogDisplayed(false)}
                amount={instrument.amount}
                currency={instrument.currency}
                maturity={instrument.maturity}
              />
            ) : (
              <ApproveDBoEDialog
                instrumentId={instrument.id}
                onClose={() => setIsApproveInstrumentDialogDisplayed(false)}
                amount={instrument.amount}
                currency={instrument.currency}
                maturity={instrument.maturity}
                issued={instrument.issuedDate}
              />
            ))}
          {isRetireInstrumentDialogDisplayed && (
            <RetireInstrumentDialog
              onClose={() => setIsRetireInstrumentDialogDisplayed(false)}
              instrument={instrument}
            />
          )}

          {isRejectInstrumentDialogDisplayed && (
            <RejectInstrumentDialog
              onClose={() => setIsRejectInstrumentDialogDisplayed(false)}
              instrument={instrument}
            />
          )}

          {isCheckOutInstrumentDialogDisplayed && (
            <CheckOutInstrumentDialog
              onClose={() => setIsCheckOutInstrumentDialogDisplayed(false)}
              instrument={instrument}
            />
          )}

          {isProtestInstrumentDialogDisplayed && (
            <ProtestInstrumentDialog
              onClose={() => setIsProtestInstrumentDialogDisplayed(false)}
              referenceNumber={referenceNumber}
              instrument={instrument}
            />
          )}

          {isEnforceableTitleObtainedDialogDisplayed && (
            <EnforceableTitleObtainedDialog
              onClose={() => {
                setIsEnforceableTitleObtainedDialogDisplayed(false);
              }}
              instrument={instrument}
              enforceableDate={new Date(enforceableTitleDate)}
            />
          )}

          {instrument.urlToDboe && isNotePdfPreviewDialogDisplayed && (
            <NotePdfPreviewDialog
              pdfUrl={instrument.urlToDboe}
              onClose={() => setIsNotePdfPreviewDialogDisplayed(false)}
            />
          )}
        </>
      )}
    </>
  );
};
