import { useQuery } from '@tanstack/react-query';

import { getCreditLimits } from 'api/client/creditLimits';
import { QueryKeys } from 'api/client/types';
import { User } from 'types/user';

export const useCreditLimits = (profile: User) => {
  return useQuery({
    queryKey: [QueryKeys.CreditLimits],
    queryFn: () => getCreditLimits(profile.company_profile_id),
  });
};
