import { differenceInCalendarDays, differenceInMilliseconds } from 'date-fns';
import addHours from 'date-fns/addHours';

import { Instrument } from 'types/instrument';

const TIME_BETWEEN_MATURE_AND_EXPIRED_IN_HOURS = 48;

export const isArchivedInstrument = (instrument: Instrument) => {
  return instrument.archived;
};

export const isActiveInstrument = (instrument: Instrument) => {
  return !isArchivedInstrument(instrument) && !instrument.isPending();
};

export const isPendingInstrument = (instrument: Instrument) => {
  return instrument.isPending();
};

export const isDarkRedStyleInstrument = (instrument: Instrument) => {
  const { state } = instrument;

  return state === 'enforced' || state === 'protested';
};

export const isGoldStyleInstrument = (instrument: Instrument) => {
  return instrument.state === 'matured';
};

export const isGreenStyleInstrument = (instrument: Instrument) => {
  return instrument.state === 'active';
};

export const getNumberOfDaysToMaturity = (maturityDate: Date) => {
  return differenceInCalendarDays(maturityDate, new Date());
};

export const getNumberOfMillisecondsToMaturity = (maturityDate: Date) => {
  return differenceInMilliseconds(maturityDate, new Date());
};

export const hasDownloadableCopy = (
  instrument: Instrument
): instrument is Instrument & { urlToDboe: string } => {
  const { state, urlToDboe } = instrument;

  if (!urlToDboe) {
    return false;
  }

  return (
    state === 'matured' ||
    state === 'protested' ||
    state === 'enforced' ||
    isCheckedOut(instrument)
  );
};

export const isCheckedOut = (instrument: Instrument) => {
  return Boolean(instrument.checkedOut) && instrument.state !== 'expired';
};

export const isExpiredDate = (date: Date) => {
  return addHours(date, TIME_BETWEEN_MATURE_AND_EXPIRED_IN_HOURS) <= new Date();
};
