import { useApproveMember } from 'api/hooks/useApproveMember';
import { Avatar } from 'components/Avatar';
import { EllipsisIcon } from 'components/Icon';
import { Menu, MenuItem } from 'components/Menu';
import { ClickOverlay } from 'components/Overlay';
import { TableRow } from 'components/Table';
import { formatDate, parseApiDate } from 'helpers/date';
import { getErrorMessage } from 'helpers/error';
import { getFullName } from 'helpers/user';
import { Member as MemberType } from 'types/member';

import * as Styles from './Member.styles';
import { MemberStatus } from './MemberStatus';

type Props = {
  member: MemberType;
};

export const Member = (props: Props) => {
  const { member } = props;
  const { admin, creation_date, email, id, state } = member;

  const {
    error: approveMemberError,
    isLoading: isApprovingMember,
    mutate: approveMember,
  } = useApproveMember();

  return (
    <>
      {Boolean(approveMemberError) && (
        <TableRow>
          <Styles.TableCell colSpan={5}>
            <Styles.Error>{getErrorMessage(approveMemberError)}</Styles.Error>
          </Styles.TableCell>
        </TableRow>
      )}

      <TableRow>
        <Styles.TableCell>
          <Styles.UserPreview>
            <Avatar name={getFullName(member)} />
            <Styles.UserSummary>
              <Styles.UserName>{getFullName(member)}</Styles.UserName>
              <Styles.UserEmail>{email}</Styles.UserEmail>
            </Styles.UserSummary>
          </Styles.UserPreview>
        </Styles.TableCell>
        <Styles.TableCell>
          {admin && 'Administrator'}

          {!admin && 'Member'}
        </Styles.TableCell>
        <Styles.TableCell>
          <MemberStatus status={state} />
        </Styles.TableCell>
        <Styles.TableCell>
          {formatDate(parseApiDate(creation_date))}
        </Styles.TableCell>
        <Styles.TableCell>
          {state === 'Pending' && (
            <ClickOverlay
              closeOnOverlayClick
              content={
                <Menu>
                  <MenuItem onClick={() => approveMember(id)}>
                    Approve request
                  </MenuItem>
                </Menu>
              }
            >
              <Styles.ActionsButton
                disabled={isApprovingMember}
                title="Member actions"
              >
                <EllipsisIcon />
              </Styles.ActionsButton>
            </ClickOverlay>
          )}
        </Styles.TableCell>
      </TableRow>
    </>
  );
};
