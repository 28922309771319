import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  target: 'dialogs';
  children: ReactNode;
};

export const Portal = (props: Props) => {
  const { children, target } = props;
  const container = document.querySelector(`#${target}`);

  if (!container) {
    return <>{children}</>;
  }

  return createPortal(children, container);
};
