import { useQuery } from '@tanstack/react-query';

import { getBuyerMembers, getInvestorMembers } from 'api/client/members';
import { QueryKeys } from 'api/client/types';
import { useUser } from 'components/UserProvider';

export const useMembers = () => {
  const { isBuyer } = useUser();

  return useQuery({
    queryKey: [QueryKeys.Members],
    queryFn: () => {
      if (isBuyer) {
        return getBuyerMembers();
      }

      return getInvestorMembers();
    },
  });
};
