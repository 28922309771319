import first from 'lodash/first';

import * as Styles from './Avatar.styles';

type Props = {
  name?: string;
};

export const Avatar = (props: Props) => {
  const { name = '' } = props;
  const [firstInitial, secondInitial] = name
    .toUpperCase()
    .split(' ')
    .map(first);

  return (
    <Styles.Container>
      {firstInitial}
      {secondInitial}
    </Styles.Container>
  );
};
