import { rem } from 'polished';
import styled from 'styled-components/macro';

import { Input } from 'components/Input';
import { getTheme } from 'styles';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: ${getTheme('space.2')};
`;

export const CharacterInput = styled(Input)`
  padding: 0 ${getTheme('space.4')};
  font-size: ${rem(40)};
  line-height: ${rem(60)};
  text-align: center;
`;
