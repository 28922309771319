import { Instrument, InstrumentType } from 'types/instrument';

import { BillOfExchangeMargin } from './BillOfExchangeMargin';
import { PromissoryNoteSignature } from './PromissoryNoteSignatures';

const TypeSpecificDetails = (instrument: Instrument) => {
  switch (instrument.getInstrumentType()) {
    case InstrumentType.PROMISSORY_NOTE:
      return <PromissoryNoteSignature instrument={instrument} />;
    case InstrumentType.BILL_OF_EXCHANGE:
      return <BillOfExchangeMargin instrument={instrument} />;
  }
};

export default TypeSpecificDetails;
