import { useQuery } from '@tanstack/react-query';

import { getAllSuppliers } from 'api/client/supplierCompany';
import { QueryKeys } from 'api/client/types';

export const useSupplierCompany = () => {
  return useQuery({
    queryKey: [QueryKeys.SupplierCompany],
    queryFn: getAllSuppliers,
  });
};
