import jwtDecode, { JwtPayload } from 'jwt-decode';

export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

const isTokenExpired = (token: string) => {
  const { exp } = jwtDecode<JwtPayload>(token);

  return !exp || new Date(exp * 1000) < new Date();
};

export const isTokenValid = (token?: string) => {
  if (!token) {
    return false;
  }

  return !isTokenExpired(token);
};
