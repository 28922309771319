import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const DropDownMenuSelect = styled.select`
  display: inline-flex;
  column-gap: ${getTheme('space.2')};
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-color: ${getTheme('colors.black.60')};
  border-radius: ${getTheme('radius.sm')};
  border: 1px solid ${getTheme('colors.greyBorder')};
  padding: 0 ${getTheme('space.6')} 0 ${getTheme('space.6')};
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  color: ${getTheme('colors.black.90')};
  height: 48px;
  outline-color: 1px solid ${getTheme('colors.greyBorder')};

  &:focus {
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid ${getTheme('colors.green.base')};
  }

  option:checked {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
