import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';

import { ApiClientConfiguration } from './ApiClientConfiguration';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const ApiClientProvider = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApiClientConfiguration>
        <Outlet />
      </ApiClientConfiguration>
    </QueryClientProvider>
  );
};
