import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type ContextProps = {
  view: string;
  setView: (view: string) => void;
};

const MultiStepViewContext = createContext<ContextProps | null>(null);

type Props = {
  children: ReactNode;
  initialView: string;
};

export const MultiStepView = (props: Props) => {
  const { children, initialView } = props;
  const [view, setView] = useState(initialView);

  const value = useMemo(() => ({ view, setView }), [view]);

  return (
    <MultiStepViewContext.Provider value={value}>
      {children}
    </MultiStepViewContext.Provider>
  );
};

export const useMultiStepView = () => {
  const value = useContext(MultiStepViewContext);

  if (!value) {
    throw new Error('useMultiStepView must be used within MultiStepView');
  }

  return value;
};
