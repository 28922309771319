import { useNavigate } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from 'components/Table';
import { formatDate, parseApiDate } from 'helpers/date';
import { formatMoneyAmount } from 'helpers/money';
import { Instrument } from 'types/instrument';

import * as Styles from './InstrumentTable.styles';

type Props = {
  instruments: Instrument[];
};

export const InstrumentTable = (props: Props) => {
  const { instruments } = props;
  const navigate = useNavigate();

  return (
    <Table>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Type of instrument</TableHeaderCell>
          <TableHeaderCell>Name of issuer</TableHeaderCell>
          <TableHeaderCell>Date of issuance</TableHeaderCell>
          <TableHeaderCell>Principal amount</TableHeaderCell>
          <TableHeaderCell>Maturity date</TableHeaderCell>
          <TableHeaderCell>Place of issuance</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>

      <TableBody>
        {instruments.map((instrument) => (
          <Styles.TableRow
            key={instrument.id}
            onClick={() =>
              navigate(
                `${instrument.getInstrumentTypeAbbreviation()}/${instrument.id}`
              )
            }
          >
            <TableCell>{instrument.type}</TableCell>
            <TableCell>{instrument.issuerName}</TableCell>
            <TableCell>
              {formatDate(parseApiDate(instrument.issuedDate))}
            </TableCell>
            <TableCell>{formatMoneyAmount(instrument.amount)}</TableCell>
            <TableCell>
              {formatDate(parseApiDate(instrument.maturity))}
            </TableCell>
            <TableCell>{instrument.issuancePlace}</TableCell>
          </Styles.TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
