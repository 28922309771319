import { useState } from 'react';

import { ENDPOINT_OVERRIDE_MAP } from 'api/config/ClientConfiguration';
import { ApproveDBoEDialog } from 'components/ApproveDBoEDialog';
import { ApproveDPNDialog } from 'components/ApproveDPNDialog';
import { Button } from 'components/Button';
import { CheckOutInstrumentDialog } from 'components/CheckOutInstrumentDialog';
import { InstrumentStatus } from 'components/InstrumentStatus';
import { NavLink } from 'components/NavLink';
import { RejectInstrumentDialog } from 'components/RejectInstrumentDialog';
import { RetireInstrumentDialog } from 'components/RetireInstrumentDialog';
import { Tag } from 'components/Tag';
import { useUser } from 'components/UserProvider';
import { formatDate, parseApiDate } from 'helpers/date';
import { downloadExternalFile } from 'helpers/download';
import { getErrorMessage } from 'helpers/error';
import { hasDownloadableCopy, isCheckedOut } from 'helpers/instrument';
import { formatMoneyAmount } from 'helpers/money';
import { Instrument, InstrumentType } from 'types/instrument';

import * as Styles from './InstrumentCard.styles';
import { MaturityTag } from './MaturityTag';

type Props = {
  instrument: Instrument;
};

const InstrumentCard = (props: Props) => {
  const { instrument } = props;
  const {
    amount,
    currency,
    id,
    issuancePlace,
    issuedDate,
    issuerName,
    maturity,
    state,
    title,
  } = instrument;
  const [
    isApproveInstrumentDialogDisplayed,
    setIsApproveInstrumentDialogDisplayed,
  ] = useState(false);
  const [
    isCheckOutInstrumentDialogDisplayed,
    setIsCheckOutInstrumentDialogDisplayed,
  ] = useState(false);
  const [
    isRetireInstrumentDialogDisplayed,
    setIsRetireInstrumentDialogDisplayed,
  ] = useState(false);
  const [
    isRejectInstrumentDialogDisplayed,
    setIsRejectInstrumentDialogDisplayed,
  ] = useState(false);

  const { isInvestor } = useUser();

  const endpointConfig = ENDPOINT_OVERRIDE_MAP[instrument.getInstrumentType()];
  const {
    error: markInstrumentAsPaidError,
    isLoading: isMarkingInstrumentAsPaid,
    mutate: markInstrumentAsPaid,
  } = endpointConfig.useMarkInstrumentAsPaid();

  const actionError = markInstrumentAsPaidError;
  const isActingOnInstrument = isMarkingInstrumentAsPaid;

  return (
    <>
      <Styles.Container instrument={instrument}>
        <Styles.InstrumentPreview instrument={instrument} />
        <Styles.InstrumentSummary>
          <Styles.InstrumentTitle>{title}</Styles.InstrumentTitle>
          <Styles.InstrumentDetails>
            <Styles.InstrumentDetailsKey>
              Name of Issuer
            </Styles.InstrumentDetailsKey>
            <Styles.InstrumentDetailsValue>
              {issuerName}
            </Styles.InstrumentDetailsValue>
            <Styles.InstrumentDetailsKey>
              Date of issuance
            </Styles.InstrumentDetailsKey>
            <Styles.InstrumentDetailsValue>
              {formatDate(parseApiDate(issuedDate))}
            </Styles.InstrumentDetailsValue>
            <Styles.InstrumentDetailsKey>
              Principal amount
            </Styles.InstrumentDetailsKey>
            <Styles.InstrumentDetailsValue>
              {formatMoneyAmount(amount, { currency })}
            </Styles.InstrumentDetailsValue>
            <Styles.InstrumentDetailsKey>
              Maturity date
            </Styles.InstrumentDetailsKey>
            <Styles.InstrumentDetailsValue>
              {formatDate(parseApiDate(maturity))}
            </Styles.InstrumentDetailsValue>
            <Styles.InstrumentDetailsKey>
              Place of issuance
            </Styles.InstrumentDetailsKey>
            <Styles.InstrumentDetailsValue>
              {issuancePlace}
            </Styles.InstrumentDetailsValue>
          </Styles.InstrumentDetails>
        </Styles.InstrumentSummary>

        <Styles.InstrumentStatuses>
          <InstrumentStatus instrument={instrument} />

          {!isCheckedOut(instrument) && (
            <>
              {instrument.isPending() && <Tag>Pending approval</Tag>}

              {state === 'active' && (
                <MaturityTag maturityDate={parseApiDate(maturity)} />
              )}
            </>
          )}
        </Styles.InstrumentStatuses>

        <Styles.InstrumentActions>
          <Button
            fullWidth
            as={NavLink}
            to={`${instrument.getInstrumentTypeAbbreviation()}/${id}`}
          >
            View Detail
          </Button>

          {isInvestor && (
            <>
              {!isCheckedOut(instrument) && (
                <>
                  {instrument.isPending() && (
                    <>
                      <Button
                        fullWidth
                        variant="secondary"
                        onClick={() =>
                          setIsApproveInstrumentDialogDisplayed(true)
                        }
                        disabled={isActingOnInstrument}
                      >
                        Approve
                      </Button>
                      <Button
                        fullWidth
                        variant="secondary"
                        disabled={isActingOnInstrument}
                        onClick={() =>
                          setIsRejectInstrumentDialogDisplayed(true)
                        }
                      >
                        Reject
                      </Button>
                    </>
                  )}

                  {state === 'active' && (
                    <>
                      {
                        // Temporarily removing this test until check out to paper functionality is finalised
                        <Button
                          fullWidth
                          variant="secondary"
                          onClick={() =>
                            setIsCheckOutInstrumentDialogDisplayed(true)
                          }
                          disabled={isActingOnInstrument}
                        >
                          Check out to paper
                        </Button>
                      }
                      <Button
                        fullWidth
                        variant="secondary"
                        disabled={isActingOnInstrument}
                        onClick={() =>
                          setIsRetireInstrumentDialogDisplayed(true)
                        }
                      >
                        Retire
                      </Button>
                    </>
                  )}

                  {state === 'matured' && (
                    <Button
                      fullWidth
                      variant="secondary"
                      disabled={isActingOnInstrument}
                      isLoading={isMarkingInstrumentAsPaid}
                      onClick={() => markInstrumentAsPaid(id)}
                    >
                      Mark as paid
                    </Button>
                  )}
                </>
              )}

              {hasDownloadableCopy(instrument) && (
                <Button
                  fullWidth
                  variant="secondary"
                  onClick={() => downloadExternalFile(instrument.urlToDboe)}
                >
                  Download a copy
                </Button>
              )}

              {/*TODO: Re-enable check in when correctly defined*/}
              {/* {isCheckedOut(instrument) && (
                <Button
                  fullWidth
                  variant="secondary"
                  disabled={isActingOnInstrument}
                  isLoading={isCheckingInInstrument}
                  onClick={() => checkInInstrument(id)}
                >
                  Check back in
                </Button>
              )} */}

              {Boolean(actionError) && (
                <Styles.InstrumentActionsErrorMessage>
                  {getErrorMessage(actionError)}
                </Styles.InstrumentActionsErrorMessage>
              )}
            </>
          )}
        </Styles.InstrumentActions>
      </Styles.Container>

      {isRejectInstrumentDialogDisplayed && (
        <RejectInstrumentDialog
          instrument={instrument}
          onClose={() => setIsRejectInstrumentDialogDisplayed(false)}
        />
      )}

      {isRetireInstrumentDialogDisplayed && (
        <RetireInstrumentDialog
          instrument={instrument}
          onClose={() => setIsRetireInstrumentDialogDisplayed(false)}
        />
      )}

      {isApproveInstrumentDialogDisplayed &&
        (instrument.getInstrumentType() === InstrumentType.PROMISSORY_NOTE ? (
          <ApproveDPNDialog
            instrumentId={id}
            onClose={() => setIsApproveInstrumentDialogDisplayed(false)}
            amount={amount}
            currency={currency}
            maturity={maturity}
          />
        ) : (
          <ApproveDBoEDialog
            instrumentId={id}
            onClose={() => setIsApproveInstrumentDialogDisplayed(false)}
            amount={amount}
            currency={currency}
            maturity={maturity}
            issued={issuedDate}
          />
        ))}

      {isCheckOutInstrumentDialogDisplayed && (
        <CheckOutInstrumentDialog
          instrument={instrument}
          onClose={() => setIsCheckOutInstrumentDialogDisplayed(false)}
        />
      )}
    </>
  );
};

export default InstrumentCard;
