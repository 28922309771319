import { rem } from 'polished';
import styled from 'styled-components/macro';

import { Spinner } from 'components/Spinner';
import { getTheme } from 'styles';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${getTheme('colors.white.100')};
  border-radius: ${getTheme('radius.lg')};
  width: calc(100% - ${rem(20)});
  max-height: calc(100% - ${rem(20)});
  max-width: ${rem(1200)};
  padding: ${getTheme('space.15')};
`;

export const Preview = styled.div`
  overflow: auto;

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

export const CloseButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${rem(22)};
  right: ${rem(22)};
  height: ${rem(40)};
  width: ${rem(40)};
  font-size: ${rem(32)};
  border: 1px solid ${getTheme('colors.black.10')};
  border-radius: ${getTheme('radius.full')};
`;

export const Loader = styled(Spinner)`
  color: ${getTheme('colors.green.base')};
  font-size: ${rem(32)};
  margin: ${getTheme('space.5')};
`;
