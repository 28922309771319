import { Outlet } from 'react-router-dom';

import { PageContent } from 'components/PageContent';
import {
  BackLink,
  PageHeader,
  PageHeaderDetails,
  PageHeaderTitle,
} from 'components/PageHeader';

export const AccountLayout = () => {
  return (
    <>
      <PageHeader>
        <BackLink>Settings</BackLink>
        <PageHeaderDetails>
          <PageHeaderTitle>Account</PageHeaderTitle>
        </PageHeaderDetails>
      </PageHeader>
      <PageContent>
        <Outlet />
      </PageContent>
    </>
  );
};
