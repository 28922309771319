import { UserState } from 'types/shared';

import * as Styles from './MemberStatus.styles';

type Props = {
  status: UserState;
};

const STATUS_LABELS: Record<UserState, string> = {
  Approved: 'Active',
  Pending: 'Access requested',
  Rejected: 'Access rejected',
};

export const MemberStatus = (props: Props) => {
  const { status } = props;

  return (
    <Styles.Container>
      <Styles.StatusDot status={status} />
      {STATUS_LABELS[status]}
    </Styles.Container>
  );
};
