import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Title = styled.h1`
  font-size: ${getTheme('fontSizes.2xl')};
  line-height: ${getTheme('lineHeights.5')};
  font-weight: 500;
  margin-bottom: ${getTheme('space.8')};
`;

export const Form = styled.form``;

export const FormControl = styled.div`
  margin-bottom: ${getTheme('space.4')};
`;

export const FormLabel = styled.label`
  display: block;
  font-weight: 500;
  font-size: ${getTheme('fontSizes.base')};
  line-height: ${getTheme('lineHeights.3')};
  padding-bottom: ${getTheme('space.2')};
`;
