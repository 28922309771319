import { useMutation } from '@tanstack/react-query';

import { login } from 'api/client/authentication';
import { useAuthentication } from 'hooks/useAuthentication';

export const useLogin = () => {
  const { setAccessToken } = useAuthentication();

  return useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      if (data.tokens) {
        setAccessToken(data.tokens.token.accessToken);
      }
    },
  });
};
