import { rem } from 'polished';
import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Stats = styled.ul`
  display: flex;
  column-gap: ${rem(6)};
  line-height: ${getTheme('lineHeights.3')};
`;

export const Stat = styled.li`
  font-size: ${getTheme('fontSizes.sm')};
  font-weight: 300;
  color: ${getTheme('colors.white.70')};

  &:not(:first-child)::before {
    content: '|';
    margin-right: ${rem(6)};
    color: ${getTheme('colors.white.30')};
  }
`;
