import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';

import {
  EnforceInstrumentRequest,
  ProtestInstrumentRequest,
} from 'api/client/types';
import {
  useBillOfExchange,
  useBillOfExchangeHistory,
  useCheckOutBillOfExchange,
  useDeclineBillOfExchange,
  useEnforceBillOfExchange,
  useMarkBillOfExchangeAsPaid,
  useProtestBillOfExchange,
  useRetireBillOfExchange,
} from 'api/hooks/BillOfExchange';
import {
  useCheckOutPromissoryNote,
  useEnforcePromissoryNote,
  useMarkPromissoryNoteAsPaid,
  usePromissoryNote,
  usePromissoryNoteHistory,
  useProtestPromissoryNote,
  useRejectPromissoryNote,
  useRetirePromissoryNote,
} from 'api/hooks/PromissoryNote';
import {
  useActiveBillsOfExchange,
  useArchivedBillsOfExchange,
  usePendingBillsOfExchange,
} from 'hooks/BillOfExchange';
import {
  useActivePromissoryNotes,
  useArchivedPromissoryNotes,
  usePendingPromissoryNotes,
} from 'hooks/PromissoryNotes';
import { InstrumentHistoryRecord } from 'types/audit';
import { Instrument, InstrumentType } from 'types/instrument';

type InstrumentListResponse = {
  instruments: Instrument[];
  error: unknown;
  isLoading: boolean;
};
type HookOverride = {
  useInstrument: (id: string) => UseQueryResult<Instrument, unknown>;
  useMarkInstrumentAsPaid: () => UseMutationResult<
    void,
    unknown,
    string,
    unknown
  >;
  useActiveInstruments: () => InstrumentListResponse;
  usePendingInstruments: () => InstrumentListResponse;
  useArchivedInstruments: () => InstrumentListResponse;
  useRejectInstrument: () => UseMutationResult<void, unknown, string, unknown>;
  useRetireInstrument: () => UseMutationResult<void, unknown, string, unknown>;
  useCheckOutInstrument: () => UseMutationResult<
    void,
    unknown,
    string,
    unknown
  >;
  useProtestInstrument: () => UseMutationResult<
    void,
    unknown,
    ProtestInstrumentRequest,
    unknown
  >;
  useEnforceInstrument: () => UseMutationResult<
    void,
    unknown,
    EnforceInstrumentRequest,
    unknown
  >;
  useInstrumentHistory: (
    id: string
  ) => UseQueryResult<InstrumentHistoryRecord[], unknown>;
};

export const ENDPOINT_OVERRIDE_MAP: Record<InstrumentType, HookOverride> = {
  [InstrumentType.BILL_OF_EXCHANGE]: {
    useInstrument: useBillOfExchange,
    useMarkInstrumentAsPaid: useMarkBillOfExchangeAsPaid,
    useActiveInstruments: useActiveBillsOfExchange,
    usePendingInstruments: usePendingBillsOfExchange,
    useArchivedInstruments: useArchivedBillsOfExchange,
    useRejectInstrument: useDeclineBillOfExchange,
    useRetireInstrument: useRetireBillOfExchange,
    useCheckOutInstrument: useCheckOutBillOfExchange,
    useProtestInstrument: useProtestBillOfExchange,
    useEnforceInstrument: useEnforceBillOfExchange,
    useInstrumentHistory: useBillOfExchangeHistory,
  },
  [InstrumentType.PROMISSORY_NOTE]: {
    useInstrument: usePromissoryNote,
    useMarkInstrumentAsPaid: useMarkPromissoryNoteAsPaid,
    useActiveInstruments: useActivePromissoryNotes,
    usePendingInstruments: usePendingPromissoryNotes,
    useArchivedInstruments: useArchivedPromissoryNotes,
    useRejectInstrument: useRejectPromissoryNote,
    useRetireInstrument: useRetirePromissoryNote,
    useCheckOutInstrument: useCheckOutPromissoryNote,
    useProtestInstrument: useProtestPromissoryNote,
    useEnforceInstrument: useEnforcePromissoryNote,
    useInstrumentHistory: usePromissoryNoteHistory,
  },
};
