import { Member } from 'types/member';

import { get, post } from './client';
import { ListResponse } from './types';

export const getInvestorMembers = async () => {
  const response = await get('/investoruser/investorPoll');
  const data: ListResponse<Member> = await response.json();

  return data.found;
};

export const approveInvestorMember = async (id: number) => {
  await post('/investoruser/approve', { body: { user_id: id } });
};

export const getBuyerMembers = async () => {
  const response = await get('/buyeruser/buyerPoll');
  const data: ListResponse<Member> = await response.json();

  return data.found;
};

export const approveBuyerMember = async (id: number) => {
  await post('/buyeruser/approve', { body: { user_id: id } });
};
