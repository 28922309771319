import { User } from 'types/user';

import { get, getWithoutAuth, post, postWithoutAuth } from './client';
import {
  ConfirmPasswordRequest,
  GetRegistrationUserRequest,
  ListResponse,
  LoginRequest,
  LoginResponse,
  OnboardingCodeDetails,
  SignUpRequest,
  ValidateWebLinkResponse,
  VerifyRegistrationRequest,
} from './types';

export const login = async (data: LoginRequest): Promise<LoginResponse> => {
  const response = await postWithoutAuth('/login', { body: data });

  return response.json();
};

export const signUpInvestor = async (data: SignUpRequest) => {
  await postWithoutAuth('/signupInvestor', { body: data });
};

export const signUpBuyer = async (data: SignUpRequest) => {
  await postWithoutAuth('/signupBuyer', { body: data });
};

export const getOnboardingCodeDetails = async (code: string) => {
  const params = new URLSearchParams({ code });
  const response = await get(`/company?${params}`);
  const data: ListResponse<OnboardingCodeDetails> = await response.json();

  return data.found[0];
};

export const resetPassword = async (email: string) => {
  await postWithoutAuth('/resetPassword', { body: { email } });
};

export const confirmPassword = async (data: ConfirmPasswordRequest) => {
  await postWithoutAuth('/confirmPassword', { body: data });
};

export const validateWebLink = async (
  encodedWebLink: string
): Promise<ValidateWebLinkResponse> => {
  const response = await getWithoutAuth(
    `/ts-auth/api/v1/auth/web-link/validate?url=${encodedWebLink}`
  );
  return response.json();
};

export const getRegistrationUser = async (data: GetRegistrationUserRequest) => {
  const params = new URLSearchParams(data);
  const response = await get(`/getUser?${params}`);
  const users: ListResponse<User> = await response.json();

  return users.found[0];
};

export const verifyRegistration = async (data: VerifyRegistrationRequest) => {
  await postWithoutAuth('/verify', { body: data });
};

export const logout = async () => {
  await post('/logout');
};
