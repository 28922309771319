import { FetchedInstrument } from 'pages/WebLinkValidator/types';

import { post, postWithoutAuth } from './client';
import {
  AcceptBillOfExchangeRequest,
  FetchInstrumentToAcceptRequest,
  RejectBillOfExchangeRequest,
} from './types';

enum BUYER_ACCEPTANCE_STATE {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}
export const fetchInstrumentToAccept = async (
  request: FetchInstrumentToAcceptRequest
): Promise<FetchedInstrument> => {
  const { otp, otpId, webLinkId } = request;

  const response = await postWithoutAuth(
    `/buyeracceptance/${webLinkId}/otp/${otpId}`,
    { body: { otp } }
  );

  return response.json();
};

export const rejectBillOfExchange = async (
  data: RejectBillOfExchangeRequest
) => {
  const { accessToken, buyerContact, id } = data;

  await postWithoutAuth(`/buyeracceptance/${id}/complete`, {
    body: {
      buyerContact,
      state: BUYER_ACCEPTANCE_STATE.REJECT.valueOf(),
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};

export const acceptBillOfExchange = async (
  data: AcceptBillOfExchangeRequest
) => {
  const { accessToken, buyerContact, id } = data;

  await post(`/buyeracceptance/${id}/complete`, {
    body: {
      buyerContact,
      state: BUYER_ACCEPTANCE_STATE.ACCEPT.valueOf(),
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};
