import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useUser as useUserQuery } from 'api/hooks/useUser';
import { Alert } from 'components/Alert';
import { FullPageContainer } from 'components/Layout';
import { PageLoader } from 'components/PageLoader';
import { getErrorMessage } from 'helpers/error';
import { User } from 'types/user';

type ContextProps = {
  user: User;
  isAdmin: boolean;
  isInvestor: boolean;
  isBuyer: boolean;
};

const UserContext = createContext<ContextProps | null>(null);

type Props = {
  children: ReactNode;
};

export const UserProvider = (props: Props) => {
  const { data: user, error, isLoading } = useUserQuery();

  const value = useMemo(() => {
    if (!user) {
      return null;
    }

    return {
      isAdmin: user.admin,
      isInvestor: user.is_investor,
      isBuyer: !user.is_investor,
      user,
    };
  }, [user]);

  if (isLoading) {
    return (
      <UserContext.Provider value={null}>
        <FullPageContainer>
          <PageLoader />
        </FullPageContainer>
      </UserContext.Provider>
    );
  }

  if (error || !user) {
    return (
      <UserContext.Provider value={null}>
        <FullPageContainer>
          <Alert>
            {Boolean(error) && getErrorMessage(error)}
            {!error && 'Could not find user'}
          </Alert>
        </FullPageContainer>
      </UserContext.Provider>
    );
  }

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

export const useUser = () => {
  const value = useContext(UserContext);

  if (!value) {
    throw new Error('userUser must be used within UserProvider');
  }

  return value;
};
