import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components/macro';

import { getTheme } from 'styles';
import { UserState } from 'types/shared';

export const Container = styled.span`
  display: inline-flex;
  align-items: center;
  column-gap: ${getTheme('space.2')};
`;

type StatusDotProps = {
  status: UserState;
};

export const StatusDot = styled.span<StatusDotProps>`
  border-radius: ${getTheme('radius.full')};
  height: ${rem(12)};
  width: ${rem(12)};
  border: 1.5px solid transparent;

  ${(props) =>
    props.status === 'Approved' &&
    css`
      background-color: ${getTheme('colors.memberStatus.green.background')};
      border-color: ${getTheme('colors.green.light01')};
    `}

  ${(props) =>
    props.status === 'Pending' &&
    css`
      background-color: ${getTheme('colors.memberStatus.yellow.background')};
      border-color: ${getTheme('colors.memberStatus.yellow.border')};
    `}

  ${(props) =>
    props.status === 'Rejected' &&
    css`
      background-color: ${rgba(getTheme('colors.red')(props), 0.8)};
      border-color: ${getTheme('colors.red')};
    `}
`;
