import './styles/fonts.css';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';

import { AdminPage } from 'components/AdminPage';
import { ApiClientProvider } from 'components/ApiClient';
import { Dashboard } from 'components/Dashboard';
import { ProtectedPage } from 'components/ProtectedPage';
import { UserProvider } from 'components/UserProvider';
import { displayNonFunctionalComponent } from 'config';
import { NOT_FOUND_PATH } from 'helpers/navigation';
import { Account } from 'pages/Account';
import { AccountLayout } from 'pages/Account/AccountLayout';
import { ActiveInstruments } from 'pages/ActiveInstruments';
import { ArchivedInstruments } from 'pages/ArchivedInstruments';
import { CreditLimits } from 'pages/CreditLimits';
import { DataVisibility } from 'pages/DataVisibility';
import { DigitalTwin } from 'pages/DigitalTwin';
import { ForgotPassword } from 'pages/ForgotPassword';
import { InstrumentDetails } from 'pages/InstrumentDetails';
import { InstrumentHistory } from 'pages/InstrumentHistory';
import { Login } from 'pages/Login';
import { Margins } from 'pages/Margins';
import { Members } from 'pages/Members';
import { Settings } from 'pages/Settings';
import { WebLink } from 'pages/WebLinkValidator/WebLink';
import { Workspace, WorkspaceLayout } from 'pages/Workspace';
import { GlobalStyles, theme } from 'styles';
import { InstrumentType } from 'types/instrument';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<ApiClientProvider />}>
      <Route
        path="/"
        element={
          <ProtectedPage>
            <UserProvider>
              <Dashboard />
            </UserProvider>
          </ProtectedPage>
        }
      >
        <Route index element={<Navigate to="active" replace />} />

        <Route path="active">
          <Route index element={<ActiveInstruments />} />
          <Route path="dpn/:id">
            <Route
              index
              element={
                <InstrumentDetails mode={InstrumentType.PROMISSORY_NOTE} />
              }
            />
            <Route
              path="history"
              element={
                <InstrumentHistory mode={InstrumentType.PROMISSORY_NOTE} />
              }
            />
          </Route>
          <Route path="dboe/:id">
            <Route
              index
              element={
                <InstrumentDetails mode={InstrumentType.BILL_OF_EXCHANGE} />
              }
            />
            <Route
              path="history"
              element={
                <InstrumentHistory mode={InstrumentType.BILL_OF_EXCHANGE} />
              }
            />
          </Route>
        </Route>

        <Route path="archived">
          <Route index element={<ArchivedInstruments />} />
          <Route path="dpn/:id">
            <Route
              index
              element={
                <InstrumentDetails mode={InstrumentType.PROMISSORY_NOTE} />
              }
            />
            <Route
              path="history"
              element={
                <InstrumentHistory mode={InstrumentType.PROMISSORY_NOTE} />
              }
            />
          </Route>
          <Route path="dboe/:id">
            <Route
              index
              element={
                <InstrumentDetails mode={InstrumentType.BILL_OF_EXCHANGE} />
              }
            />
            <Route
              path="history"
              element={
                <InstrumentHistory mode={InstrumentType.BILL_OF_EXCHANGE} />
              }
            />
          </Route>
        </Route>

        <Route path="credit-limits">
          <Route index element={<CreditLimits />} />
        </Route>

        <Route path="margins">
          <Route index element={<Margins />} />
        </Route>

        <Route path="data-visibility">
          <Route index element={<DataVisibility />} />
        </Route>

        <Route path="settings">
          <Route index element={<Settings />} />

          {displayNonFunctionalComponent && (
            <Route path="account" element={<AccountLayout />}>
              <Route index element={<Account />} />
            </Route>
          )}

          <Route
            path="workspace"
            element={
              <AdminPage>
                <WorkspaceLayout />
              </AdminPage>
            }
          >
            <Route index element={<Workspace />} />
            <Route path="members" element={<Members />} />
          </Route>
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password">
        <Route index element={<ForgotPassword />} />
      </Route>

      <Route path="/web-link/:webLink" element={<WebLink />} />

      <Route path="/digital-twin">
        <Route path=":id">
          <Route index element={<DigitalTwin />} />
        </Route>
      </Route>
      {/* TODO: Re-enable registration back whenever it's needed */}
      {/* <Route path="/sign-up">
        <Route index element={<SignUp />} />
        <Route path="admin" element={<AdminSignUp />} />
      </Route> */}
      <Route path="*" element={<Navigate to={NOT_FOUND_PATH} replace />} />
    </Route>
  )
);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
