import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const PageSubHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${getTheme('space.4')};
  margin-top: ${getTheme('space.4')};
  padding: ${getTheme('space.2')};
`;
export const PageSubHeaderDescription = styled.h2`
  font-size: ${getTheme('fontSizes.lg')};
  font-weight: 400;
`;
