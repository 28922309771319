import { rem } from 'polished';
import styled from 'styled-components/macro';

import { Button } from 'components/Button';
import {
  PageHeader as PageHeaderComponent,
  PageHeaderTitle as PageHeaderTitleComponent,
} from 'components/PageHeader';
import { Spinner } from 'components/Spinner';
import { getTheme } from 'styles';

export const ActionButton = styled(Button)`
  min-width: ${rem(192)};
  flex-shrink: 0;
`;

export const AlertContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
`;

export const CardPreview = styled.span`
  display: inline-flex;
  padding: ${getTheme('space.2')};
  align-items: center;
  justify-content: center;
  font-size: ${rem(44)};
  border-right: 1px solid ${getTheme('colors.cardBorder')};
`;

export const Loader = styled(Spinner)`
  color: ${getTheme('colors.green.base')};
  font-size: ${rem(32)};
  margin: ${getTheme('space.5')};
`;

export const Page = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${getTheme('colors.white.100')};
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100%;
`;

export const PageFooter = styled.footer`
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: auto;
  column-gap: ${getTheme('space.4')};
  padding: ${getTheme('space.4')} ${getTheme('space.6')};
  background-color: ${getTheme('colors.white.100')};
  box-shadow: ${getTheme('shadows.footer')};
`;

export const PageHeader = styled(PageHeaderComponent)`
  padding-bottom: ${getTheme('space.2')};
  padding-left: ${getTheme('space.8')};
`;

export const PageHeaderTitle = styled(PageHeaderTitleComponent)`
  max-width: ${rem(306)};
  width: 100%;
`;

export const PdfContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${getTheme('space.8')};
`;

export const Preview = styled.div`
  overflow: auto;

  .react-pdf__Page__canvas {
    min-width: 70%;
  }
`;
