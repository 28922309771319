import { forwardRef, ReactNode } from 'react';
import { StyledComponentPropsWithRef } from 'styled-components/macro';

import * as Styles from './Checkbox.styles';

type Props = StyledComponentPropsWithRef<typeof Styles.Checkbox> & {
  children?: ReactNode;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { children, ...otherProps } = props;

  return (
    <Styles.Label>
      <Styles.CheckboxContainer>
        <Styles.Checkbox ref={ref} {...otherProps} type="checkbox" />
        <Styles.NotCheckedIcon />
        <Styles.CheckedIcon />
      </Styles.CheckboxContainer>
      <Styles.Text>{children}</Styles.Text>
    </Styles.Label>
  );
});

Checkbox.displayName = 'Checkbox';
