import { saveAs } from 'file-saver';

const getFileName = (url: string) => {
  const { pathname } = new URL(url);

  return pathname.split('/').pop();
};

export const downloadExternalFile = (url: string) => {
  return saveAs(url, getFileName(url));
};
