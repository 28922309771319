import { rem } from 'polished';
import styled, { css } from 'styled-components/macro';

import { InstrumentPreview as InstrumentPreviewComponent } from 'components/InstrumentPreview';
import {
  isDarkRedStyleInstrument,
  isGoldStyleInstrument,
  isGreenStyleInstrument,
} from 'helpers/instrument';
import { getTheme } from 'styles';
import { Instrument } from 'types/instrument';

import InstrumentActiveBackgroundImage from './assets/instrument-active-background.jpg';
import InstrumentEnforcedBackgroundImage from './assets/instrument-enforced-background.jpg';
import InstrumentExpiredBackgroundImage from './assets/instrument-expired-background.jpg';
import InstrumentMaturedBackgroundImage from './assets/instrument-matured-background.jpg';
import InstrumentMintedBackgroundImage from './assets/instrument-minted-background.jpg';

export const InstrumentStatuses = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: ${getTheme('space.3')};
`;

export const InstrumentActions = styled.div`
  display: grid;
  align-content: center;
  justify-items: center;
  row-gap: ${getTheme('space.3')};
  background-color: ${getTheme('colors.white.100')};
  width: ${rem(224)};
  border: 1px solid ${getTheme('colors.greyBorder')};
  border-radius: ${getTheme('radius.md')};
  padding: ${getTheme('space.4')};
`;

export const InstrumentActionsErrorMessage = styled.p`
  margin: 0;
  font-size: ${getTheme('fontSizes.xs')};
  line-height: ${getTheme('lineHeights.3')};
  color: ${getTheme('colors.red')};
`;

type ContainerProps = {
  instrument: Instrument;
};

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: minmax(auto, ${rem(158)}) 1fr auto;
  column-gap: ${getTheme('space.4')};
  text-decoration: none;
  color: inherit;
  border: 1px solid ${getTheme('colors.greyBorder')};
  border-radius: ${getTheme('radius.lg')};
  padding: ${getTheme('space.4')};
  background-color: ${getTheme('colors.white.100')};
  background-size: cover;

  ${InstrumentActions} {
    display: none;
  }

  &:hover {
    ${InstrumentStatuses} {
      display: none;
    }

    ${InstrumentActions} {
      display: grid;
    }
  }

  ${(props) =>
    isDarkRedStyleInstrument(props.instrument) &&
    css`
      background-image: url(${InstrumentMintedBackgroundImage});
    `}

  ${(props) =>
    isGoldStyleInstrument(props.instrument) &&
    css`
      background-image: url(${InstrumentMaturedBackgroundImage});
    `}

  ${(props) =>
    props.instrument.isPending() &&
    css`
      background-image: url(${InstrumentEnforcedBackgroundImage});
    `}

  ${(props) =>
    isGreenStyleInstrument(props.instrument) &&
    css`
      background-image: url(${InstrumentActiveBackgroundImage});
    `}

  ${(props) =>
    props.instrument.isDarkStyle() &&
    css`
      background-image: url(${InstrumentExpiredBackgroundImage});
    `}
`;

export const InstrumentPreview = styled(InstrumentPreviewComponent)`
  max-width: 100%;
  margin-top: ${rem(-7)};
  margin-left: ${rem(-7)};
  margin-bottom: ${rem(-7)};
`;

export const InstrumentSummary = styled.div`
  padding: ${getTheme('space.2')} 0 ${getTheme('space.2')} ${rem(10)};
`;

export const InstrumentTitle = styled.h3`
  font-size: ${getTheme('fontSizes.base')};
  font-weight: 600;
  padding-bottom: ${getTheme('space.2')};
  line-height: ${getTheme('lineHeights.3')};
`;

export const InstrumentDetails = styled.dl`
  display: grid;
  grid-template-columns: minmax(auto, ${rem(136)}) 1fr;
  column-gap: ${getTheme('space.4')};
  margin: 0;
  font-size: ${getTheme('fontSizes.xs')};
  line-height: ${getTheme('lineHeights.3')};
`;

export const InstrumentDetailsKey = styled.dt`
  text-align: right;
  color: ${getTheme('colors.black.70')};
`;

export const InstrumentDetailsValue = styled.dd`
  margin: 0;
  font-weight: 500;
`;
