import range from 'lodash/range';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';

import { Backdrop } from 'components/Backdrop';
import { CloseIcon } from 'components/Icon';
import { Portal } from 'components/Portal';

import * as Styles from './NotePdfPreviewDialog.styles';

type Props = {
  pdfUrl: string;
  onClose: () => void;
};

export const NotePdfPreviewDialog = (props: Props) => {
  const { onClose, pdfUrl } = props;
  const [numberOfPages, setNumberOfPages] = useState(0);

  const onLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setNumberOfPages(pdf.numPages);
  };

  return (
    <Portal target="dialogs">
      <Backdrop>
        <Styles.Container>
          <Styles.Preview>
            <Document
              file={pdfUrl}
              loading={<Styles.Loader />}
              onLoadSuccess={onLoadSuccess}
            >
              {range(numberOfPages).map((pageNumber) => (
                <Page
                  key={pageNumber}
                  pageNumber={pageNumber + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              ))}
            </Document>
          </Styles.Preview>
          <Styles.CloseButton onClick={onClose}>
            <CloseIcon />
          </Styles.CloseButton>
        </Styles.Container>
      </Backdrop>
    </Portal>
  );
};
