import { ComponentPropsWithRef, ComponentType, ReactNode } from 'react';
import {
  StyledComponentInnerOtherProps,
  StyledComponentPropsWithRef,
} from 'styled-components/macro';

import * as Styles from './MenuItem.styles';

type AsComponentProps<AsComponent> = {
  as?: AsComponent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & (AsComponent extends keyof JSX.IntrinsicElements | ComponentType<any>
  ? ComponentPropsWithRef<AsComponent>
  : unknown);

type Props<AsComponent> = StyledComponentPropsWithRef<typeof Styles.Button> &
  Partial<StyledComponentInnerOtherProps<typeof Styles.Button>> &
  AsComponentProps<AsComponent> & {
    children: ReactNode;
  };

export const MenuItem = <
  AsComponent extends
    | JSX.IntrinsicElements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ComponentType<any>
    | undefined = undefined
>(
  props: Props<AsComponent>
) => {
  const { children, variant = 'item', ...otherProps } = props;

  return (
    <Styles.Container>
      <Styles.Button {...otherProps} variant={variant}>
        {children}
      </Styles.Button>
    </Styles.Container>
  );
};
