import { rem } from 'polished';
import styled, { css } from 'styled-components/macro';

import { getTheme } from 'styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${getTheme('space.4')};
  margin-left: auto;
  justify-content: end;
  padding: 0px 1.5rem;
`;

export const ExportButton = styled.button`
  color: ${getTheme('colors.black.50')};
  font-size: ${getTheme('fontSizes.xs')};
  line-height: ${getTheme('lineHeights.1')};
  padding: ${rem(7)} ${getTheme('space.3')};
  border: 1px solid ${getTheme('colors.greyBorder')};
  border-radius: ${getTheme('radius.lg')};
`;

export const ToggleButtonGroup = styled.div`
  display: inline-flex;
`;

type ToggleButtonProps = {
  isSelected: boolean;
};

export const ToggleButton = styled.button<ToggleButtonProps>`
  display: inline-flex;
  font-size: ${rem(30)};
  align-items: center;
  justify-content: center;
  color: ${getTheme('colors.greyBorder')};
  border: 1px solid ${getTheme('colors.greyBorder')};

  &:not(:first-child) {
    border-left: none;
  }

  &:first-child {
    border-top-left-radius: ${rem(8)};
    border-bottom-left-radius: ${rem(8)};
  }

  &:last-child {
    border-top-right-radius: ${rem(8)};
    border-bottom-right-radius: ${rem(8)};
  }

  ${(props) =>
    props.isSelected &&
    css`
      color: ${getTheme('colors.green.base')};
    `}
`;
