import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: ${getTheme('space.6')};
`;

export const FormGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: ${getTheme('space.6')};
`;

export const FormLabel = styled.label`
  font-size: ${getTheme('fontSizes.xs')};
  line-height: ${getTheme('lineHeights.3')};
  color: ${getTheme('colors.black.50')};
  font-weight: 500;
`;

export const FormControl = styled.div`
  ${FormLabel} {
    margin-bottom: ${getTheme('space.1')};
  }
`;
