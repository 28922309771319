import { ElementProps, FloatingContext } from '@floating-ui/react';
import { useMemo } from 'react';

type Options = {
  closeOnFloatingClick?: boolean;
};

export const useFloatingDismiss = (
  context: FloatingContext,
  options: Options = {}
): ElementProps => {
  const { onOpenChange } = context;
  const { closeOnFloatingClick } = options;

  return useMemo(
    () => ({
      floating: {
        onClick: () => {
          if (closeOnFloatingClick) {
            onOpenChange(false);
          }
        },
      },
    }),
    [closeOnFloatingClick, onOpenChange]
  );
};
