import styled, { css } from 'styled-components/macro';

import { getTheme } from 'styles';

type ButtonProps = {
  variant: 'item' | 'heading';
};

export const Button = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  column-gap: ${getTheme('space.2')};
  width: 100%;
  padding: ${getTheme('space.2')} ${getTheme('space.4')};
  font-size: ${getTheme('fontSizes.xs')};
  line-height: ${getTheme('lineHeights.3')};
  text-align: left;

  &:hover {
    background-color: ${getTheme('colors.black.5')};
  }

  ${(props) =>
    props.variant === 'heading' &&
    css`
      && {
        color: ${getTheme('colors.black.50')};
      }
    `}
`;

export const Container = styled.li`
  &:first-child {
    border-top-left-radius: ${getTheme('radius.lg')};
    border-top-right-radius: ${getTheme('radius.lg')};
  }

  &:last-child {
    border-bottom-left-radius: ${getTheme('radius.lg')};
    border-bottom-right-radius: ${getTheme('radius.lg')};
  }
`;
