import { Alert } from 'components/Alert';
import { NoDataIcon } from 'components/Icon';

import * as Styles from './PageNotFound.styles';

type Props = {
  error?: string;
};

export const PageNotFound = (props: Props) => {
  const { error } = props;

  return (
    <Styles.Container>
      <Styles.Icon>
        <NoDataIcon />
      </Styles.Icon>
      <Styles.Title>
        {'The link provided was invalid and could not be followed.'}
      </Styles.Title>
      {error && <Alert>{error}</Alert>}
    </Styles.Container>
  );
};
