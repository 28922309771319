import { yupResolver } from '@hookform/resolvers/yup';
import { currencyOptions } from 'data/currency';
import { tenorDaysOptions } from 'data/tenorDays';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { MarginRequest } from 'api/client/types';
import { useAddMargin } from 'api/hooks/useAddMargin';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Dialog, DialogActions, DialogTitle } from 'components/Dialog';
import { DropDownMenu } from 'components/DropDownMenu';
import { getErrorMessage } from 'helpers/error';
import { SupplierCompany } from 'types/financing';

import {
  BoldFormInput,
  Content,
  FormItem,
  FormLabel,
} from './AddMarginsDialog.styles';

type Props = {
  supplierCompanies: SupplierCompany[];
  id: string;
  onClose: () => void;
};

const marginsSchema = yup.object().shape({
  supplierId: yup.string().required('Supplier is required'),
  supplierCompanyName: yup.string().required(),
  margin: yup
    .number()
    .integer('Margin must be an integer')
    .typeError('Margin is required')
    .min(0, 'Margin must be a valid positive number')
    .max(2147483647, 'Margin must be of a smaller value')
    .required('Margin is required'),
  currency: yup
    .string()
    .oneOf(
      currencyOptions.map((currency) => currency.id),
      'Currency is required'
    )
    .required('Currency is required'),
  tenorDays: yup.string().required('Tenor Days are required'),
});

export const AddMarginsDialog = (props: Props) => {
  const { id, onClose, supplierCompanies } = props;
  const {
    error,
    isLoading,
    isSuccess,
    mutateAsync: addMargin,
  } = useAddMargin();

  const {
    formState: { errors, isSubmitting, isValid, touchedFields },
    handleSubmit,
    register,
    resetField,
    setError,
    setValue,
    trigger,
  } = useForm<MarginRequest>({
    resolver: yupResolver(marginsSchema),
    mode: 'onChange',
  });

  const handleSelectSupplier = (supplierId: string) => {
    const supplier = supplierCompanies.find(
      (supplier) => supplier.supplier_id === supplierId
    );
    if (supplier) {
      setValue('supplierId', supplier.supplier_id, {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue('supplierCompanyName', supplier.trading_name, {
        shouldValidate: true,
      });
      setValue('clientPrefix', supplier.client_prefix);
    } else {
      resetField('supplierId', { keepTouched: true });
      setValue('supplierCompanyName', '');
      setValue('clientPrefix', '');
      setError('supplierId', {
        type: 'required',
        message: 'Supplier is required',
      });
    }
  };

  const onSubmit = async (data: MarginRequest) => {
    const margin: MarginRequest = {
      supplierId: data.supplierId,
      investorId: id,
      supplierCompanyName: data.supplierCompanyName,
      margin: data.margin,
      currency: data.currency,
      tenorDays: data.tenorDays,
      clientPrefix: data.clientPrefix,
    };

    await addMargin({ ...margin });
    onClose();
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <Dialog>
      <DialogTitle>Set Margin for a Supplier</DialogTitle>
      {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}
      {isSuccess && (
        <Alert severity={'info'}>{'Margin added successfully'}</Alert>
      )}
      <Content>
        <FormItem>
          <FormLabel>Supplier Company</FormLabel>
          <DropDownMenu
            placeholder="Select a Supplier"
            options={supplierCompanies}
            valueKey="supplier_id"
            labelKey="trading_name"
            {...register('supplierId')}
            onChange={(value) => handleSelectSupplier(value)}
          />
        </FormItem>
        <FormItem>
          <FormLabel>Margin Value</FormLabel>
          <BoldFormInput
            type="number"
            min={0}
            placeholder="Enter a Margin"
            {...register('margin')}
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
          />
        </FormItem>
        <FormItem>
          <FormLabel>Currency</FormLabel>
          <DropDownMenu
            placeholder="Select Currency"
            options={currencyOptions}
            valueKey="id"
            labelKey="name"
            {...register('currency')}
            onChange={(value) =>
              setValue('currency', value, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }
          />
        </FormItem>
        <FormItem>
          <FormLabel>Tenor Days</FormLabel>
          <DropDownMenu
            placeholder="Set Tenor Days"
            options={tenorDaysOptions}
            valueKey="id"
            labelKey="name"
            onChange={(value) =>
              setValue('tenorDays', value, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }
          />
        </FormItem>
      </Content>
      {!(Object.keys(touchedFields).length === 0) && errors.supplierId && (
        <Alert>{errors.supplierId.message}</Alert>
      )}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.supplierId &&
        errors.margin && <Alert>{errors.margin.message}</Alert>}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.supplierId &&
        !errors.margin &&
        errors.currency && <Alert>{errors.currency.message}</Alert>}
      {!(Object.keys(touchedFields).length === 0) &&
        !errors.supplierId &&
        !errors.margin &&
        !errors.currency &&
        errors.tenorDays && <Alert>{errors.tenorDays.message}</Alert>}
      <DialogActions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading || isSubmitting}
          disabled={!isValid || isLoading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
