export type LoginRequest = {
  email: string;
  password: string;
  code?: string;
};

export type LoginResponse = {
  tokens?: {
    token: {
      accessToken: string;
      idToken: string;
      refreshToken: string;
    };
    email: string;
    exp: number;
    uid: string;
    auth_time: number;
    token_use: string;
  };
  investorUserProfileId: number;
};

export enum QueryKeys {
  PromissoryNotes = 'PromissoryNotes',
  PromissoryNote = 'PromissoryNote',
  PromissoryNoteHistory = 'PromissoryNoteHistory',
  BillsOfExchange = 'BillsOfExchange',
  BillOfExchange = 'BillOfExchange',
  BillOfExchangeHistory = 'BillOfExchangeHistory',
  Members = 'Members',
  Me = 'Me',
  Signatures = 'Signatures',
  RegistrationUser = 'RegistrationUser',
  BuyerAcceptance = 'BuyerAcceptance',
  ValidateWebLink = 'ValidateUrl',
  CreditLimits = 'CreditLimits',
  BuyerCompany = 'BuyerCompany',
  SupplierCompany = 'SupplierCompany',
  DigitalTwin = 'DigitalTwin',
  DataVisibility = 'DataVisibility',
  Margins = 'Margins',
}

export type ProtestInstrumentRequest = {
  id: string;
  protestReferenceNumber: string;
};

export type EnforceInstrumentRequest = {
  id: string;
  enforceableDate: Date;
};

export type SignUpRequest = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  company_number: string;
};

export type ListResponse<Data> = {
  found: Data[];
};

export type OnboardingCodeDetails = {
  is_investor: boolean;
  is_buyer: boolean;
};

export type ConfirmPasswordRequest = {
  code: string;
  password: string;
  email: string;
};

export type GetRegistrationUserRequest = {
  email: string;
  key: string;
};

export type VerifyRegistrationRequest = {
  email: string;
  code: string;
};

export type ValidateWebLinkResponse = {
  isValid: boolean;
  responseContent?: {
    otpId: string;
    webLinkId: string;
  };
  validationErrors?: string[];
};

export type FetchInstrumentToAcceptRequest = {
  otpId: string;
  webLinkId: string;
  otp: string;
};

export type RejectBillOfExchangeRequest = {
  id: string;
  buyerContact: {
    name: string;
    email: string;
  };
  accessToken: string;
};

export type AcceptBillOfExchangeRequest = {
  id: string;
  buyerContact: {
    name: string;
    email: string;
  };
  accessToken: string;
};

export type ApproveBillOfExchangeRequest = {
  id: string;
  disbursementDate: string;
  fundsDisbursed: number;
};

export type CreditLimitItem = {
  creditLimitId: string;
  creditLimit: number;
  buyerId: string;
  buyerCompanyName: string;
  currency: string;
  effectiveDate: number;
};

export interface CreditLimitResponse {
  creditLimits: CreditLimitItem[];
}

export interface CreditLimitRequest {
  creditLimit: number;
  investorId: string;
  buyerId: string;
  buyerCompanyName: string;
  currency: string;
  effectiveDate: string;
}

export interface InvestorProviderRequest {
  investorId: string;
  clientPrefix: string;
}

export type MarginItem = {
  marginId: string;
  margin: number;
  currency: string;
  investorId: string;
  supplierId: string;
  supplierCompanyName: string;
  tenorDays: string;
}

export interface MarginResponse {
  margins: MarginItem[]
}

export interface MarginRequest {
  margin: number;
  investorId: string;
  supplierId: string;
  supplierCompanyName: string;
  currency: string;
  tenorDays: string;
  clientPrefix: string;
}