import { rem } from 'polished';
import styled, { css } from 'styled-components/macro';

import { getTheme } from 'styles';

import { InputIcon } from './styles';

export const Container = styled.div`
  position: relative;
`;
const Adornment = styled.span`
  display: inline-flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

type InputProps = {
  hasEndAdornment: boolean;
  $size: 'medium' | 'large';
  isInvalid?: boolean;
};

export const Input = styled.input<InputProps>`
  width: 100%;
  appearance: none;
  background-color: ${getTheme('colors.black.5')};
  border: 1px solid ${getTheme('colors.greyBorder')};
  line-height: ${getTheme('lineHeights.3')};

  &:focus {
    outline: 1px solid ${getTheme('colors.green.base')};
  }

  &::placeholder {
    font-weight: 400;
    color: ${getTheme('colors.black.50')};
  }

  &:disabled {
    &,
    & + ${Adornment} ${InputIcon} {
      color: ${getTheme('colors.black.50')};
    }
  }

  ${(props) =>
    props.$size === 'medium' &&
    css`
      border-radius: ${getTheme('radius.sm')};
      font-size: ${getTheme('fontSizes.sm')};
      padding: ${getTheme('space.3')};
    `}

  ${(props) =>
    props.$size === 'large' &&
    css`
      border-radius: ${getTheme('radius.md')};
      font-size: ${getTheme('fontSizes.base')};
      font-weight: 500;
      padding: ${rem(14)} ${getTheme('space.6')};
    `}

  ${(props) =>
    props.hasEndAdornment &&
    css`
      ${props.$size === 'medium' &&
      css`
        padding-right: calc(${rem(24)} + ${getTheme('space.3')} * 2);
      `}
    `}

  ${(props) =>
    props.isInvalid &&
    css`
      && {
        outline: none;
        border-color: ${getTheme('colors.error')};
      }
    `}
`;

export const EndAdornment = styled(Adornment)`
  right: ${getTheme('space.3')};
`;
