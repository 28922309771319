import { useQuery } from '@tanstack/react-query';

import { getBillsOfExchange } from 'api/client/billOfExchange';
import { QueryKeys } from 'api/client/types';

export const useBillsOfExchange = () => {
  return useQuery({
    queryKey: [QueryKeys.BillsOfExchange],
    queryFn: getBillsOfExchange,
  });
};
