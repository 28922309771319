import { rem } from 'polished';
import styled from 'styled-components/macro';

import { CheckboxCheckedIcon, CheckboxNotCheckedIcon } from 'components/Icon';
import { getTheme } from 'styles';

export const Label = styled.label`
  display: inline-flex;
  cursor: pointer;
  column-gap: ${rem(11)};
`;

export const CheckboxContainer = styled.span`
  display: inline-flex;
  font-size: ${rem(24)};
`;

export const NotCheckedIcon = styled(CheckboxNotCheckedIcon)`
  color: ${getTheme('colors.black.10')};
`;

export const CheckedIcon = styled(CheckboxCheckedIcon)`
  color: ${getTheme('colors.green.base')};

  --secondary-color: ${getTheme('colors.white.100')};
`;

export const Checkbox = styled.input`
  appearance: none;

  &:not(:checked) {
    & ~ ${CheckedIcon} {
      display: none;
    }
  }

  &:checked {
    & ~ ${NotCheckedIcon} {
      display: none;
    }
  }
`;

export const Text = styled.span`
  font-size: ${getTheme('fontSizes.sm')};
  line-height: ${getTheme('lineHeights.3')};
`;
