import { useMutation } from '@tanstack/react-query';

import { approvePromissoryNote } from 'api/client/promissoryNote';
import { useUpdatePromissoryNoteCache } from 'hooks/PromissoryNotes/useUpdatePromissoryNoteCache';

export const useApprovePromissoryNote = () => {
  const { updatePromissoryNote } = useUpdatePromissoryNoteCache();

  return useMutation({
    mutationFn: approvePromissoryNote,
    onSuccess: (_, id) => {
      updatePromissoryNote(id, { state: 'active', archived: false });
    },
  });
};
