const { REACT_APP_API_URL, REACT_APP_FEATURE_NON_FUNCTIONAL } = process.env;

const getStringValue = (value?: string) => {
  return value ?? '';
};

export const API_URL = getStringValue(REACT_APP_API_URL);

const featureEnabled = (feature?: string): boolean => feature === 'true';

export const displayNonFunctionalComponent = featureEnabled(
  REACT_APP_FEATURE_NON_FUNCTIONAL
);
