import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useResetPassword } from 'api/hooks/useResetPassword';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { FormInput } from 'components/Form';
import { useMultiStepView } from 'components/MultiStepView';
import { NavLink } from 'components/NavLink';
import { getErrorMessage } from 'helpers/error';
import { silentSubmit } from 'helpers/form';

import * as Styles from './ResetPassword.styles';
import * as SharedStyles from './styles';
import { EnterEmailFormData } from './types';

const validation: yup.ObjectSchema<EnterEmailFormData> = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

type Props = {
  initialFormData?: EnterEmailFormData;
  onSuccess: (data: EnterEmailFormData) => void;
};

export const ResetPassword = (props: Props) => {
  const { setView } = useMultiStepView();
  const { initialFormData, onSuccess } = props;
  const {
    formState: { isSubmitSuccessful, isSubmitting, isValid },
    handleSubmit,
    register,
    watch,
  } = useForm<EnterEmailFormData>({
    resolver: yupResolver(validation),
    defaultValues: initialFormData,
  });

  const {
    error: resetPasswordError,
    isSuccess,
    mutateAsync: resetPassword,
  } = useResetPassword();

  const data = watch();

  useEffect(() => {
    if (isSubmitSuccessful && isSuccess) {
      onSuccess(data);
      setView('confirm-password');
    }
  }, [isSubmitSuccessful, setView, onSuccess, data, isSuccess]);

  const onSubmit = (data: EnterEmailFormData) => resetPassword(data.email);

  return (
    <>
      {Boolean(resetPasswordError) && (
        <Alert>{getErrorMessage(resetPasswordError)}</Alert>
      )}
      <SharedStyles.Title>Forgot Your Password?</SharedStyles.Title>
      <Styles.Description>
        Please enter your email address. You will receive an email with a
        one-time passcode.
      </Styles.Description>
      <SharedStyles.Form onSubmit={handleSubmit(silentSubmit(onSubmit))}>
        <SharedStyles.FormControl>
          <SharedStyles.FormLabel htmlFor="email">Email</SharedStyles.FormLabel>
          <FormInput
            type="email"
            id="email"
            placeholder="Your email address"
            autoComplete="email"
            autoFocus
            size="medium"
            {...register('email')}
          />
        </SharedStyles.FormControl>

        <Styles.Actions>
          <Button
            type="button"
            size="medium"
            variant="secondary"
            as={NavLink}
            to="/"
          >
            Back
          </Button>
          <Button
            type="submit"
            size="medium"
            disabled={!isValid || isSubmitting}
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </Styles.Actions>
      </SharedStyles.Form>
    </>
  );
};
