import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { QueryKeys } from 'api/client/types';
import { IBillOfExchange } from 'types/billOfExchange';

export const useUpdateBillOfExchangeCache = () => {
  const queryClient = useQueryClient();

  const updateBillOfExchange = useCallback(
    (id: string, data: Partial<Omit<IBillOfExchange, 'id'>>) => {
      queryClient.setQueryData<IBillOfExchange>(
        [QueryKeys.BillOfExchange, id],
        (instrument) => instrument && { ...instrument, ...data }
      );

      queryClient.setQueryData<IBillOfExchange[]>(
        [QueryKeys.BillsOfExchange],
        (instruments) => {
          return instruments?.map((instrument) => {
            if (instrument.id === id) {
              return { ...instrument, ...data };
            }

            return instrument;
          });
        }
      );
    },
    [queryClient]
  );

  const invalidateBillOfExchange = useCallback(
    (id: string) => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.BillsOfExchange] });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.BillOfExchange, id],
      });
    },
    [queryClient]
  );

  return { updateBillOfExchange, invalidateBillOfExchange };
};
