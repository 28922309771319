import { rem } from 'polished';
import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  row-gap: ${getTheme('space.4')};
`;

export const Icon = styled.div`
  display: inline-flex;
  font-size: ${rem(80)};
  color: ${getTheme('colors.green.base')};

  --secondary-color: ${getTheme('colors.iconLightGreen')};
`;

export const Title = styled.h3`
  font-size: ${getTheme('fontSizes.base')};
  line-height: ${getTheme('lineHeights.3')};
  font-weight: 500;
  color: ${getTheme('colors.black.50')};
`;
