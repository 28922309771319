import { useApprovePromissoryNote } from 'api/hooks/PromissoryNote/useApprovePromissoryNote';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogText,
  DialogTitle,
} from 'components/Dialog';
import { parseApiDate } from 'helpers/date';
import { getErrorMessage } from 'helpers/error';
import { formatMoneyAmount } from 'helpers/money';

type Props = {
  instrumentId: string;
  amount: string;
  maturity: string;
  currency: string;
  onClose: () => void;
};

export const ApproveDPNDialog = (props: Props) => {
  const { amount, currency, instrumentId, maturity, onClose } = props;

  const {
    error: approveInstrumentError,
    isLoading: isApprovingInstrument,
    mutateAsync: approveInstrument,
  } = useApprovePromissoryNote();

  const onApprove = async () => {
    await approveInstrument(instrumentId);
    onClose();
  };

  const hasValidMaturity = () => {
    if (maturity) {
      const maturityDate = parseApiDate(maturity);
      const now = new Date();
      return maturityDate >= now;
    }
    return false;
  };

  const canApproveInstrument = hasValidMaturity();

  return (
    <Dialog>
      <DialogTitle>Approve this Promissory Note?</DialogTitle>
      {Boolean(approveInstrumentError) && (
        <Alert>{getErrorMessage(approveInstrumentError)}</Alert>
      )}
      {!canApproveInstrument && (
        <Alert>
          This instrument cannot be approved as the maturity date has passed.
        </Alert>
      )}
      <DialogContent>
        <DialogText>
          <strong>
            Approving this Promissory Note means that you will be assuming an
            investment of {formatMoneyAmount(amount, { currency })}
          </strong>
        </DialogText>
        <DialogText>Are you sure you want to proceed?</DialogText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          size="large"
          onClick={onClose}
          disabled={isApprovingInstrument}
        >
          Cancel
        </Button>
        <Button
          size="large"
          onClick={onApprove}
          isLoading={isApprovingInstrument}
          disabled={!canApproveInstrument}
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};
