import isEmpty from 'lodash/isEmpty';

import InstrumentCard from 'components/InstrumentCard/InstrumentCard';
import {
  InstrumentList,
  InstrumentListCount,
  InstrumentListItem,
  InstrumentListTitle,
} from 'components/InstrumentList';
import { InstrumentsViewType } from 'components/InstrumentsPageActions';
import { InstrumentTable } from 'components/InstrumentTable';
import { PageContent } from 'components/PageContent';
import { Instrument } from 'types/instrument';

type Props = {
  instrumentsView: InstrumentsViewType;
  activeInstruments: Instrument[];
  pendingInstruments: Instrument[];
};

export const ActiveInstrumentsList = (props: Props) => {
  const { activeInstruments, instrumentsView, pendingInstruments } = props;

  return (
    <>
      <PageContent>
        {!isEmpty(pendingInstruments) && (
          <>
            <InstrumentListTitle>
              Pending approval{' '}
              <InstrumentListCount>
                ({pendingInstruments.length})
              </InstrumentListCount>
            </InstrumentListTitle>

            {instrumentsView === 'card' && (
              <InstrumentList>
                {pendingInstruments.map((instrument) => (
                  <InstrumentListItem key={instrument.id}>
                    <InstrumentCard instrument={instrument} />
                  </InstrumentListItem>
                ))}
              </InstrumentList>
            )}

            {instrumentsView === 'table' && (
              <InstrumentTable instruments={pendingInstruments} />
            )}
          </>
        )}

        {!isEmpty(activeInstruments) && (
          <>
            <InstrumentListTitle>
              Active Instruments{' '}
              <InstrumentListCount>
                ({activeInstruments.length})
              </InstrumentListCount>
            </InstrumentListTitle>

            {instrumentsView === 'card' && (
              <InstrumentList>
                {activeInstruments.map((instrument) => (
                  <InstrumentListItem key={instrument.id}>
                    <InstrumentCard instrument={instrument} />
                  </InstrumentListItem>
                ))}
              </InstrumentList>
            )}

            {instrumentsView === 'table' && (
              <InstrumentTable instruments={activeInstruments} />
            )}
          </>
        )}
      </PageContent>
    </>
  );
};
