import { rem } from 'polished';
import styled from 'styled-components/macro';

import { NavLink } from 'components/NavLink';
import { getTheme } from 'styles';

export const CloseLink = styled(NavLink)`
  display: inline-flex;
  font-size: ${rem(32)};
  color: ${getTheme('colors.black.50')};
`;
