import styled from 'styled-components/macro';

import { Button } from 'components/Button';
import { NavLink } from 'components/NavLink';
import { getTheme } from 'styles';

export const LoginButton = styled(Button)`
  margin-top: ${getTheme('space.4')};
`;

export const SignUpDescription = styled.p`
  font-size: ${getTheme('fontSizes.sm')};
  margin-top: ${getTheme('space.8')};

  ${NavLink} {
    font-weight: 500;
    color: ${getTheme('colors.green.base')};
  }
`;

export const ForgotPassword = styled.p`
  font-size: ${getTheme('fontSizes.sm')};
  margin-top: ${getTheme('space.8')};

  ${NavLink} {
    font-weight: 500;
    color: ${getTheme('colors.green.base')};
  }
`;
