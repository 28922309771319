import { rem } from 'polished';
import styled, { css } from 'styled-components/macro';

import { getTheme } from 'styles';

type ContainerProps = {
  severity: string;
};

export const Icon = styled.span`
  display: inline-flex;
  font-size: ${rem(24)};
`;

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${getTheme('space.2')};
  padding: ${rem(10)};
  margin-bottom: ${getTheme('space.8')};
  border-radius: ${getTheme('radius.md')};
  border: 1px solid transparent;

  ${(props) =>
    props.severity === 'error' &&
    css`
      background-color: ${getTheme('colors.errorAlertBackground')};
      border-color: ${getTheme('colors.orange')};

      ${Icon} {
        color: ${getTheme('colors.orange')};
      }
    `}

  ${(props) =>
    props.severity === 'info' &&
    css`
      background-color: ${getTheme('colors.infoAlertBackground')};
      border-color: ${getTheme('colors.success')};

      ${Icon} {
        color: ${getTheme('colors.success')};
      }
    `}
`;

export const Text = styled.p`
  margin: 0;
  font-size: ${getTheme('fontSizes.sm')};
  line-height: ${getTheme('lineHeights.3')};
  color: ${getTheme('colors.black.70')};
`;
