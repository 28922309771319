import { useMemo } from 'react';

import { usePromissoryNotes } from 'api/hooks/PromissoryNote/usePromissoryNotes';
import { isActiveInstrument } from 'helpers/instrument';
import { PromissoryNote } from 'types/promissoryNote';

export const useActivePromissoryNotes = () => {
  const { data = [], ...query } = usePromissoryNotes();

  const instruments = useMemo(() => {
    return data
      .map((promissoryNote) => new PromissoryNote(promissoryNote))
      .filter(isActiveInstrument);
  }, [data]);

  return { ...query, instruments };
};
