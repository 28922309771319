import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuthentication } from 'hooks/useAuthentication';

type Props = {
  children: ReactNode;
};

export const ProtectedPage = (props: Props) => {
  const { isAccessTokenValid } = useAuthentication();
  const location = useLocation();

  if (!isAccessTokenValid) {
    return <Navigate to="/login" state={{ redirectTo: location.pathname }} />;
  }

  return <>{props.children}</>;
};
