import { useQuery } from '@tanstack/react-query';

import { getInstrumentById } from 'api/client/promissoryNote';
import { QueryKeys } from 'api/client/types';
import { PromissoryNote } from 'types/promissoryNote';

export const usePromissoryNote = (id: string) => {
  return useQuery({
    queryKey: [QueryKeys.PromissoryNote, id],
    queryFn: () => getInstrumentById(id),
    select: (instrument) => new PromissoryNote(instrument),
  });
};
