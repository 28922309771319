import { useState } from 'react';

import { AuthLayout } from 'components/AuthLayout';
import { MultiStepView, View } from 'components/MultiStepView';

import { ConfirmPassword } from './ConfirmPassword';
import { ConfirmSuccess } from './ConfirmSuccess';
import * as Styles from './ForgotPassword.styles';
import { ResetPassword } from './ResetPassword';
import { EnterEmailFormData } from './types';

export const ForgotPassword = () => {
  const [enterEmailFormData, setEnterEmailFormData] = useState<
    EnterEmailFormData | undefined
  >();

  return (
    <AuthLayout>
      <Styles.Card>
        <MultiStepView initialView="reset-password">
          <View id="reset-password">
            <ResetPassword
              initialFormData={enterEmailFormData}
              onSuccess={setEnterEmailFormData}
            />
          </View>

          {enterEmailFormData && (
            <View id="confirm-password">
              <ConfirmPassword enterEmailFormData={enterEmailFormData} />
            </View>
          )}
          <View id="confirm-success">
            <ConfirmSuccess />
          </View>
        </MultiStepView>
      </Styles.Card>
    </AuthLayout>
  );
};
