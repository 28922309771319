import { rem } from 'polished';
import styled, { css } from 'styled-components/macro';

import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { InstrumentPreview as InstrumentPreviewComponent } from 'components/InstrumentPreview';
import { NavLink } from 'components/NavLink';
import {
  PageHeader as PageHeaderComponent,
  PageHeaderTitle as PageHeaderTitleComponent,
} from 'components/PageHeader';
import { Tag } from 'components/Tag';
import {
  isDarkRedStyleInstrument,
  isGoldStyleInstrument,
  isGreenStyleInstrument,
} from 'helpers/instrument';
import { getTheme } from 'styles';
import { Instrument } from 'types/instrument';

import InstrumentActiveBackgroundImage from './assets/instrument-active-background.jpg';
import InstrumentEnforcedBackgroundImage from './assets/instrument-enforced-background.jpg';
import InstrumentExpiredBackgroundImage from './assets/instrument-expired-background.jpg';
import InstrumentMaturedBackgroundImage from './assets/instrument-matured-background.jpg';
import InstrumentMintedBackgroundImage from './assets/instrument-minted-background.jpg';

type PageProps = {
  instrument?: Instrument;
};

export const Page = styled.div<PageProps>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex: 1;
  background-color: ${getTheme('colors.white.100')};
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100%;

  ${(props) =>
    props.instrument &&
    isDarkRedStyleInstrument(props.instrument) &&
    css`
      background-image: url(${InstrumentEnforcedBackgroundImage});
    `}

  ${(props) =>
    props.instrument &&
    isGoldStyleInstrument(props.instrument) &&
    css`
      background-image: url(${InstrumentMaturedBackgroundImage});
    `}

  ${(props) =>
    props.instrument &&
    props.instrument.isPending() &&
    css`
      background-image: url(${InstrumentMintedBackgroundImage});
    `}

  ${(props) =>
    props.instrument &&
    isGreenStyleInstrument(props.instrument) &&
    css`
      background-image: url(${InstrumentActiveBackgroundImage});
    `}

  ${(props) =>
    props.instrument &&
    props.instrument.isDarkStyle() &&
    css`
      background-image: url(${InstrumentExpiredBackgroundImage});
    `}
`;

export const PageHeader = styled(PageHeaderComponent)`
  padding-bottom: ${getTheme('space.2')};
`;

export const PageHeaderTitle = styled(PageHeaderTitleComponent)`
  max-width: ${rem(306)};
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, ${rem(360)}) 1fr;
  flex: 1;
  align-items: start;
  column-gap: ${rem(10)};
`;

export const PreviewContainer = styled.div`
  padding-left: ${getTheme('space.8')};
  padding-right: ${rem(26)};
  padding-top: ${getTheme('space.10')};
`;

export const DownloadCopyButton = styled(Button)`
  border-radius: ${getTheme('radius.lg')};
`;

type InstrumentPreviewProps = {
  $isClickable?: boolean;
};

export const InstrumentPreview = styled(
  InstrumentPreviewComponent
)<InstrumentPreviewProps>`
  width: calc(100% + ${rem(26)});
  margin-top: ${rem(-10)};
  margin-left: ${rem(-13)};
  margin-bottom: ${getTheme('space.3')};

  ${(props) =>
    props.$isClickable &&
    css`
      cursor: pointer;
    `}
`;

type InstrumentContentProps = {
  isCheckedOut: boolean;
};

export const InstrumentContent = styled.div<InstrumentContentProps>`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: start;

  ${(props) =>
    props.isCheckedOut &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const InstrumentDetailsContainer = styled.div`
  flex: 1;
  overflow: auto;
  position: relative;
  margin-top: ${getTheme('space.6')};
  width: 100%;
`;

export const InstrumentDetails = styled.dl`
  position: absolute;
  top: ${rem(16)};
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(auto, ${rem(180)}) 1fr;
  column-gap: ${getTheme('space.6')};
  row-gap: ${getTheme('space.4')};
  font-size: ${getTheme('fontSizes.sm')};
  line-height: ${getTheme('lineHeights.3')};
  margin: 0;
`;

export const InstrumentDetailsKey = styled.dt`
  color: ${getTheme('colors.black.70')};
  text-align: right;
`;

export const InstrumentDetailsValue = styled.dd`
  margin: 0;
  font-weight: 500;
`;

export const Signatures = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${getTheme('space.4')};
`;

export const Signature = styled.li`
  display: inline-flex;
`;

export const InstrumentSignature = styled.img`
  max-width: ${rem(362)};
`;

export const InstrumentDetailsSecondaryKey = styled(InstrumentDetailsKey)`
  color: ${getTheme('colors.black.100')};
  font-weight: 500;
`;

export const InstrumentDetailsInvoicesValue = styled(InstrumentDetailsValue)`
  font-weight: 400;
`;

export const InstrumentDetailsApprovalKey = styled(InstrumentDetailsKey)`
  color: ${getTheme('colors.black.100')};
  font-weight: 500;
`;

export const InstrumentDetailsApprovalValue = styled(InstrumentDetailsValue)`
  font-weight: normal;
  margin-bottom: ${getTheme('space.4')};
`;

export const InstrumentDetailsEnforceableTitleValue = styled(
  InstrumentDetailsValue
)`
  font-weight: 400;
  padding-bottom: ${getTheme('space.10')};
`;

export const ApprovalTag = styled(Tag)`
  border-color: ${getTheme('colors.status.yellow')};
`;

export const InstrumentNestedDetails = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, ${rem(188)}) 1fr;
  column-gap: ${rem(26)};
  row-gap: ${getTheme('space.3')};
`;

export const InstrumentNestedDetailsKey = styled.span`
  color: ${getTheme('colors.black.70')};
`;

export const InstrumentNestedDetailsValue = styled.span`
  font-weight: 500;
`;

export const InvoicesTitle = styled.span`
  color: ${getTheme('colors.black.70')};
`;

export const InvoiceName = styled.span`
  color: ${getTheme('colors.green.base')};
`;

export const InvoiceAmount = styled.span`
  font-weight: 500;
`;

export const PageFooter = styled.footer`
  display: flex;
  align-items: end;
  justify-content: end;
  column-gap: ${getTheme('space.4')};
  padding: ${getTheme('space.4')} ${getTheme('space.6')};
  background-color: ${getTheme('colors.white.100')};
  box-shadow: ${getTheme('shadows.footer')};
`;

export const ActionButton = styled(Button)`
  min-width: ${rem(192)};
  flex-shrink: 0;
`;

export const InstrumentFormattedAmount = styled.span`
  display: inline-block;
  margin-top: ${getTheme('space.2')};
`;

export const ReferenceNumberInput = styled(Input)`
  width: ${rem(300)};
`;

export const EnforceableTitleDateInput = styled(Input)`
  width: ${rem(170)};

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

export const ActionFormContainer = styled.div``;

export const ActionForm = styled.form`
  display: inline-flex;
  align-items: center;
  justify-self: end;
  column-gap: ${getTheme('space.2')};
  padding-right: ${getTheme('space.4')};
`;

export const ActionFormHelperText = styled.p`
  font-size: ${getTheme('fontSizes.base')};
  line-height: ${getTheme('lineHeights.3')};
  color: ${getTheme('colors.black.50')};
  font-weight: 500;
  margin: 0;
  padding-bottom: ${rem(8)};
`;

export const HistoryLink = styled(NavLink)`
  font-size: ${getTheme('fontSizes.sm')};
  color: ${getTheme('colors.black.70')};
  text-decoration: underline;
`;

export const SupplierBankAccountTitle = styled.button`
  display: flex;
  flex-direction: row;
  column-gap: ${getTheme('space.2')};
  text-align: right;
  font-size: ${getTheme('fontSizes.sm')};
  color: ${getTheme('colors.black.70')};
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`;

export const SupplierBankAccountKey = styled.span`
  color: ${getTheme('colors.black.70')};
`;

export const SupplierBankAccountValue = styled.span`
  font-weight: 500;
`;

export const ChevronIcon = styled.div`
  display: inline-flex;
  font-size: ${rem(32)};
  color: ${getTheme('colors.black.50')};
`;
