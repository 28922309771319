import { Button } from 'components/Button';
import { NavLink } from 'components/NavLink';

import * as Styles from './ConfirmSuccess.styles';
import * as SharedStyles from './styles';

export const ConfirmSuccess = () => {
  return (
    <>
      <SharedStyles.Title>
        You have successfully updated your password.
      </SharedStyles.Title>
      <Styles.Actions>
        <Button
          type="button"
          fullWidth
          size="large"
          variant="primary"
          id="back-to-login"
          as={NavLink}
          to="/"
        >
          Back to log in
        </Button>
      </Styles.Actions>
    </>
  );
};
