import { rem, rgba, transparentize } from 'polished';

const colors = {
  green: {
    base: '#04857c',
    light01: '#059e93',
    light02: '#06b8ab',
    light03: '#06d1c3',
    light04: '#07ebdb',
    dark01: '#036b64',
    dark02: '#02524c',
    dark03: '#023834',
    dark04: '#062f31',
    dark05: '#011f1d',
  },

  black: {
    100: '#000000',
    90: 'rgba(0, 0, 0, 0.9)',
    80: 'rgba(0, 0, 0, 0.8)',
    70: 'rgba(0, 0, 0, 0.7)',
    60: 'rgba(0, 0, 0, 0.6)',
    50: 'rgba(0, 0, 0, 0.5)',
    40: 'rgba(0, 0, 0, 0.4)',
    30: 'rgba(0, 0, 0, 0.3)',
    20: 'rgba(0, 0, 0, 0.2)',
    10: 'rgba(0, 0, 0, 0.1)',
    5: 'rgba(0, 0, 0, 0.05)',
  },

  white: {
    100: '#ffffff',
    90: 'rgba(255, 255, 255, 0.9)',
    80: 'rgba(255, 255, 255, 0.8)',
    70: 'rgba(255, 255, 255, 0.7)',
    60: 'rgba(255, 255, 255, 0.6)',
    50: 'rgba(255, 255, 255, 0.5)',
    40: 'rgba(255, 255, 255, 0.4)',
    30: 'rgba(255, 255, 255, 0.3)',
    20: 'rgba(255, 255, 255, 0.2)',
  },

  orange: '#f7a52b',
  yellow: '#f2cc43',
  purple: '#ae50ae',
  navy: '#2D3258',
  red: '#FF5454',
  success: '#54ac68',

  status: {
    darkRed: '#9a2020',
    gold: '#d2c5b2',
    yellow: '#c5a034',
  },

  get memberStatus() {
    return {
      yellow: {
        background: '#FFCC33',
        border: '#D48F00',
      },

      green: {
        background: '#31B7B8',
      },

      red: {
        background: '#FF5454',
      },
    };
  },

  cardBorder: transparentize(0.15, 'rgba(255, 255, 255, 0.3)'),
  greyBorder: '#e5e5e5',
  backdrop: rgba('#000000', 0.5),
  iconLightGreen: '#C0E0DE',
  error: '#FF4949',

  get errorAlertBackground() {
    return rgba(this.orange, 0.05);
  },

  get infoAlertBackground() {
    return rgba(this.success, 0.05);
  },

  get cardBorderDark() {
    return rgba(this.green.base, 0.3);
  },

  get cardBackground() {
    return rgba(this.green.dark02, 0.5);
  },
};

const shadows = {
  footer: `0px ${rem(-4)} ${rem(16)} rgba(0, 0, 0, 0.08)`,
  menu: `0px 0px ${rem(4)} rgba(0, 0, 0, 0.16);`,
};

const space = [
  rem(0), // 0
  rem(4), // 1
  rem(8), // 2
  rem(12), // 3
  rem(16), // 4
  rem(20), // 5
  rem(24), // 6
  rem(28), // 7
  rem(32), // 8
  rem(36), // 9
  rem(40), // 10
  rem(44), // 11
  rem(48), // 12
  rem(56), // 13
  rem(64), // 14
  rem(80), // 15
  rem(96), // 16
  rem(112), // 17
  rem(128), // 18
] as const;

const fontSizes = {
  xs: rem(12),
  sm: rem(14),
  base: rem(16),
  lg: rem(18),
  xl: rem(20),
  '2xl': rem(24),
  '3xl': rem(30),
  '4xl': rem(36),
  '5xl': rem(48),
  '6xl': rem(60),
  '7xl': rem(72),
  '8xl': rem(96),
  '9xl': rem(128),
};

const lineHeights = [
  rem(12), // 0
  rem(16), // 1
  rem(20), // 2
  rem(24), // 3
  rem(28), // 4
  rem(32), // 5
  rem(36), // 6
  rem(40), // 7
] as const;

const radius = {
  none: 0,
  sm: rem(2),
  md: rem(4),
  lg: rem(8),
  xl: rem(12),
  full: '9999px',
};

const opacity = {
  disabled: 0.3,
};

export const theme = {
  colors,
  shadows,
  space,
  fontSizes,
  lineHeights,
  radius,
  opacity,
};
