import { BillOfExchange } from 'types/billOfExchange';
import { Instrument } from 'types/instrument';

import * as Styles from './InstrumentDetails.styles';

type Props = {
  instrument: Instrument;
};

export const BillOfExchangeMargin = (props: Props) => {
  const { instrument } = props;
  return (
    <>
      {instrument instanceof BillOfExchange && instrument.marginBps && (
        <>
          <Styles.InstrumentDetailsKey>
            Margin (BPS)
          </Styles.InstrumentDetailsKey>
          <Styles.InstrumentDetailsValue>
            {instrument.marginBps}
          </Styles.InstrumentDetailsValue>
        </>
      )}
    </>
  );
};
