import styled from 'styled-components/macro';

import { CodeInput as CodeInputComponent } from 'components/CodeInput';
import { getTheme } from 'styles';

export const Description = styled.p`
  font-size: ${getTheme('fontSizes.base')};
  line-height: ${getTheme('lineHeights.3')};
  font-weight: 500;
  margin-bottom: ${getTheme('space.8')};
`;

export const CodeInput = styled(CodeInputComponent)`
  margin-top: ${getTheme('space.6')};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${getTheme('space.14')};
`;
