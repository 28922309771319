import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';

import { Alert } from 'components/Alert';
import { ArchivedInstrumentsList } from 'components/ArchivedInstrumentList';
import { AlarmIcon } from 'components/Icon';
import {
  InstrumentsPageActions,
  InstrumentsViewType,
} from 'components/InstrumentsPageActions';
import { NothingToSee } from 'components/NothingToSee';
import {
  PageHeader,
  PageHeaderDetails,
  PageHeaderIcon,
  PageHeaderTitle,
} from 'components/PageHeader';
import { PageLoader } from 'components/PageLoader';
import { getErrorMessage } from 'helpers/error';
import { useArchivedBillsOfExchange } from 'hooks/BillOfExchange/useArchivedBillsOfExchange';
import { useArchivedPromissoryNotes } from 'hooks/PromissoryNotes/useArchivedPromissoryNotes';
import { useExportInstruments } from 'hooks/useExportInstruments';
import { Instrument, InstrumentType } from 'types/instrument';

import * as Styles from './ArchivedInstruments.styles';

export const ArchivedInstruments = () => {
  const [activeTab, setActiveTab] = useState<InstrumentType>(
    InstrumentType.PROMISSORY_NOTE
  );
  const [instrumentsView, setInstrumentsView] =
    useState<InstrumentsViewType>('card');
  const [archivedInstruments, setArchivedInstruments] = useState<Instrument[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [hasInstruments, setHasInstruments] = useState<boolean>(false);
  const { exportInstruments } = useExportInstruments();

  const {
    error: archivedPromissoryNotesError,
    instruments: archivedPromissoryNotes,
    isLoading: isLoadingArchivedPromissoryNotes,
  } = useArchivedPromissoryNotes();

  const {
    error: archivedBillsOfExchangeError,
    instruments: archivedBillsOfExchange,
    isLoading: isLoadingArchivedBillsOfExchange,
  } = useArchivedBillsOfExchange();

  useEffect(() => {
    switch (activeTab) {
      case InstrumentType.PROMISSORY_NOTE:
        setArchivedInstruments(archivedPromissoryNotes);
        setIsLoading(isLoadingArchivedPromissoryNotes);
        setHasInstruments(!isEmpty(archivedPromissoryNotes));
        setError(archivedPromissoryNotesError);
        break;
      case InstrumentType.BILL_OF_EXCHANGE:
        setArchivedInstruments(archivedBillsOfExchange);
        setIsLoading(isLoadingArchivedBillsOfExchange);
        setHasInstruments(!isEmpty(archivedBillsOfExchange));
        setError(archivedBillsOfExchangeError);
        break;
    }
  }, [
    activeTab,
    archivedBillsOfExchange,
    archivedBillsOfExchangeError,
    archivedPromissoryNotes,
    archivedPromissoryNotesError,
    isLoadingArchivedBillsOfExchange,
    isLoadingArchivedPromissoryNotes,
  ]);

  return (
    <>
      <PageHeader>
        <PageHeaderDetails>
          <PageHeaderIcon>
            <AlarmIcon />
          </PageHeaderIcon>
          <PageHeaderTitle>Archived Instruments</PageHeaderTitle>
          {hasInstruments && (
            <InstrumentsPageActions
              onExport={() => exportInstruments(archivedInstruments)}
              view={instrumentsView}
              onViewChange={setInstrumentsView}
            />
          )}
        </PageHeaderDetails>
      </PageHeader>
      <Styles.TabContainer>
        <Styles.TabItem
          active={activeTab === InstrumentType.PROMISSORY_NOTE}
          onClick={() => setActiveTab(InstrumentType.PROMISSORY_NOTE)}
        >
          {'Promissory Notes'}
        </Styles.TabItem>{' '}
        <Styles.TabItem
          active={activeTab === InstrumentType.BILL_OF_EXCHANGE}
          onClick={() => setActiveTab(InstrumentType.BILL_OF_EXCHANGE)}
        >
          {'Bills of Exchange'}
        </Styles.TabItem>
      </Styles.TabContainer>
      <Styles.PageContent>
        <Styles.TabContent active={true}>
          {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}

          {isLoading && <PageLoader />}

          {!hasInstruments && !isLoading && <NothingToSee />}

          {hasInstruments && (
            <ArchivedInstrumentsList
              archivedInstruments={archivedInstruments}
              instrumentsView={instrumentsView}
            />
          )}
        </Styles.TabContent>
      </Styles.PageContent>
    </>
  );
};
