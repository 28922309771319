import styled, { css } from 'styled-components/macro';

import { Tag as TagComponent } from 'components/Tag';
import { getTheme } from 'styles';

type TagProps = {
  isToday?: boolean;
  isSoon?: boolean;
};

export const Tag = styled(TagComponent)<TagProps>`
  ${(props) =>
    props.isToday &&
    css`
      background-color: ${getTheme('colors.yellow')};
      border-color: ${getTheme('colors.yellow')};
    `}

  ${(props) =>
    props.isSoon &&
    css`
      border-color: ${getTheme('colors.yellow')};
    `}
`;
