import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';

import { useMembers } from 'api/hooks/useMembers';
import { Alert } from 'components/Alert';
import { PageLoader } from 'components/PageLoader';
import {
  CloseButton,
  Content,
  Description,
  Header,
  HeaderDetails,
  Page,
  Title,
} from 'components/SettingsPage';
import { TableBody, TableHeader, TableHeaderRow } from 'components/Table';
import { getErrorMessage } from 'helpers/error';
import { getAdminMembers, getRegularMembers } from 'helpers/members';

import { Member } from './Member';
import * as Styles from './Members.styles';

export const Members = () => {
  const { data: members = [], error, isLoading } = useMembers();

  const adminMembers = getAdminMembers(members);
  const regularMembers = getRegularMembers(members);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Page>
      <Header>
        <HeaderDetails>
          <Title>Members</Title>

          {!error && (
            <Description>
              You have {pluralize('member', regularMembers.length, true)} and{' '}
              {pluralize('Administrator', adminMembers.length, true)}
            </Description>
          )}
        </HeaderDetails>
        <CloseButton />
      </Header>
      <Content>
        {Boolean(error) && <Alert>{getErrorMessage(error)}</Alert>}

        {!isEmpty(members) && (
          <Styles.Table>
            <TableHeader>
              <TableHeaderRow>
                <Styles.TableHeaderCell>Name</Styles.TableHeaderCell>
                <Styles.TableHeaderCell>Account type</Styles.TableHeaderCell>
                <Styles.TableHeaderCell>Status</Styles.TableHeaderCell>
                <Styles.TableHeaderCell>Member since</Styles.TableHeaderCell>
                <Styles.TableHeaderCell />
              </TableHeaderRow>
            </TableHeader>

            <TableBody>
              {members.map((member) => (
                <Member key={member.id} member={member} />
              ))}
            </TableBody>
          </Styles.Table>
        )}
      </Content>
    </Page>
  );
};
