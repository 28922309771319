import {
  AddCircleOutline as AddIcon,
  DeleteOutline as RemoveIcon,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';

import { CreditLimitItem } from 'api/client/types';
import { useBuyerCompany } from 'api/hooks/useBuyerCompanies';
import { useCreditLimits } from 'api/hooks/useCreditLimits';
import { AddCreditLimitDialog } from 'components/AddCreditLimitDialog';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { DeleteCreditLimitDialog } from 'components/DeleteCreditLimitDialog/DeleteCreditLimitDialog';
import { EditCreditLimitDialog } from 'components/EditCreditLimitDialog';
import { AnalyticsIcon } from 'components/Icon';
import { PageContent } from 'components/PageContent';
import {
  PageHeader,
  PageHeaderDetails,
  PageHeaderIcon,
  PageHeaderTitle,
} from 'components/PageHeader';
import { PageLoader } from 'components/PageLoader';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table';
import { useUser } from 'components/UserProvider';
import { getErrorMessage } from 'helpers/error';
import { BuyerCompany } from 'types/financing';

import * as Styles from './CreditLimits.styles';
export const CreditLimits = () => {
  const { user } = useUser();
  const {
    data,
    error: creditLimitsError,
    isLoading: isLoadingCreditLimits,
  } = useCreditLimits(user);

  const creditLimits = useMemo(() => (data ? data.creditLimits : []), [data]);

  const {
    data: buyerCompanies = [],
    error: buyerCompaniesError,
    isLoading: isLoadingBuyerCompanies,
  } = useBuyerCompany();

  const [isAddCreditLimitDialogDisplayed, setIsAddCreditLimitDialogDisplayed] =
    useState<boolean>(false);

  const [isEditCreditLimitDialogDisplayed, setIsEdiCreditLimitDialogDisplayed] =
    useState<boolean>(false);

  const [
    isDeleteCreditLimitDialogDisplayed,
    setIsDeleteCreditLimitDialogDisplayed,
  ] = useState<boolean>(false);

  const [selectedCreditLimitId, setSelectedCreditLimitId] = useState('');
  const [selectedCreditLimitData, setSelectedCreditLimitData] =
    useState<CreditLimitItem>();

  const [buyersWithoutCreditLimits, setBuyersWithoutCreditLimits] = useState<
    BuyerCompany[]
  >([]);

  useEffect(() => {
    if (creditLimits) {
      const creditLimitsBuyerIds = new Set(
        creditLimits.map((creditLimit) => creditLimit.buyerId)
      );
      setBuyersWithoutCreditLimits(
        buyerCompanies.filter(
          (buyer) => !creditLimitsBuyerIds.has(buyer.buyer_id)
        )
      );
    }
  }, [buyerCompanies, creditLimits]);

  const onAddCreditLimit = () => {
    setIsAddCreditLimitDialogDisplayed(true);
  };

  const onDeleteCreditLimit = async (id: string) => {
    setSelectedCreditLimitId(id);
    setIsDeleteCreditLimitDialogDisplayed(true);
  };

  const onEditCreditLimit = async (id: string) => {
    const creditLimitData = creditLimits?.find(
      (creditLimit) => creditLimit.creditLimitId === id
    );
    setSelectedCreditLimitData(creditLimitData || undefined);
    setIsEdiCreditLimitDialogDisplayed(true);
  };

  if (isLoadingCreditLimits || isLoadingBuyerCompanies) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderDetails>
          <PageHeaderIcon>
            <AnalyticsIcon />
          </PageHeaderIcon>
          <PageHeaderTitle>Buyer Credit Limits</PageHeaderTitle>
        </PageHeaderDetails>
      </PageHeader>
      <PageContent>
        {Boolean(creditLimitsError) && (
          <Alert>{getErrorMessage(creditLimitsError)}</Alert>
        )}
        {Boolean(buyerCompaniesError) && (
          <Alert>{getErrorMessage(buyerCompaniesError)}</Alert>
        )}
        <Table>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>Buyer Company Name</TableHeaderCell>
              <TableHeaderCell>Credit Limit</TableHeaderCell>
              <TableHeaderCell>Currency</TableHeaderCell>
              <TableHeaderCell>Effective Date</TableHeaderCell>
              <TableHeaderCell>
                <Button onClick={onAddCreditLimit}>
                  <AddIcon />
                  Add Credit Limit
                </Button>
              </TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {creditLimits &&
              creditLimits.map((limit) => (
                <TableRow key={limit.creditLimitId}>
                  <TableCell isTopBorderVisible={true}>
                    {limit.buyerCompanyName}
                  </TableCell>
                  <TableCell isTopBorderVisible={true}>
                    {limit.creditLimit}
                  </TableCell>
                  <TableCell isTopBorderVisible={true}>
                    {limit.currency}
                  </TableCell>
                  <TableCell isTopBorderVisible={true}>
                    {new Date(limit.effectiveDate * 1000).toLocaleDateString(
                      'en-GB'
                    )}
                  </TableCell>
                  <TableCell isTopBorderVisible={true}>
                    <IconButton
                      onClick={() => onEditCreditLimit(limit.creditLimitId)}
                    >
                      <Styles.EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => onDeleteCreditLimit(limit.creditLimitId)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {selectedCreditLimitData && isEditCreditLimitDialogDisplayed && (
              <EditCreditLimitDialog
                creditLimitData={selectedCreditLimitData}
                onClose={() => setIsEdiCreditLimitDialogDisplayed(false)}
                id={user.company_profile_id}
              />
            )}
            {isAddCreditLimitDialogDisplayed && !isEmpty(buyerCompanies) && (
              <AddCreditLimitDialog
                onClose={() => setIsAddCreditLimitDialogDisplayed(false)}
                buyerCompanies={buyersWithoutCreditLimits}
                id={user.company_profile_id}
              />
            )}
            {isDeleteCreditLimitDialogDisplayed && (
              <DeleteCreditLimitDialog
                onClose={() => setIsDeleteCreditLimitDialogDisplayed(false)}
                id={selectedCreditLimitId}
              />
            )}
          </TableBody>
        </Table>
      </PageContent>
    </>
  );
};
