import { rem } from 'polished';
import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Card = styled.div`
  width: 100%;
  max-width: ${rem(448)};
  background-color: ${getTheme('colors.white.100')};
  border: 1px solid ${getTheme('colors.greyBorder')};
  border-radius: ${getTheme('radius.lg')};
  padding: ${getTheme('space.8')};
`;
