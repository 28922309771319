import { useQuery } from '@tanstack/react-query';

import { getBillOfExchangeById } from 'api/client/billOfExchange';
import { QueryKeys } from 'api/client/types';
import { BillOfExchange } from 'types/billOfExchange';

export const useBillOfExchange = (id: string) => {
  return useQuery({
    queryKey: [QueryKeys.BillOfExchange, id],
    queryFn: () => getBillOfExchangeById(id),
    select: (instrument) => new BillOfExchange(instrument),
  });
};
