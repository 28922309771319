import { GridViewIcon, TableViewIcon } from 'components/Icon';

import * as Styles from './InstrumentsPageActions.styles';
import { InstrumentsViewType } from './types';

type Props = {
  view: InstrumentsViewType;
  onViewChange: (view: InstrumentsViewType) => void;
  onExport: () => void;
};

export const InstrumentsPageActions = (props: Props) => {
  const { onExport, onViewChange, view } = props;

  return (
    <Styles.Container>
      <Styles.ExportButton onClick={onExport}>Export data</Styles.ExportButton>

      <Styles.ToggleButtonGroup>
        <Styles.ToggleButton
          isSelected={view === 'card'}
          onClick={() => onViewChange('card')}
          title="Instrument list"
        >
          <GridViewIcon />
        </Styles.ToggleButton>
        <Styles.ToggleButton
          isSelected={view === 'table'}
          onClick={() => onViewChange('table')}
          title="Instrument table"
        >
          <TableViewIcon />
        </Styles.ToggleButton>
      </Styles.ToggleButtonGroup>
    </Styles.Container>
  );
};
