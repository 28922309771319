import { useMutation } from '@tanstack/react-query';

import { markBillOfExchangeAsPaid } from 'api/client/billOfExchange';
import { useUpdateBillOfExchangeCache } from 'hooks/BillOfExchange/useUpdateBillOfExchangeCache';

export const useMarkBillOfExchangeAsPaid = () => {
  const { updateBillOfExchange } = useUpdateBillOfExchangeCache();

  return useMutation({
    mutationFn: markBillOfExchangeAsPaid,
    onSuccess: (_, id) => {
      updateBillOfExchange(id, { state: 'expired', archived: true });
    },
  });
};
