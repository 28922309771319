import { useLocation } from 'react-router-dom';

import { PageNotFound } from 'components/PageNotFound';

import { BuyerAcceptance } from './BuyerAcceptance/BuyerAcceptance';

export const WebLink = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const flowType = queryParams.get('flowType');

  return (
    <>
      {(() => {
        switch (flowType) {
          case 'BuyerAcceptance':
            return <BuyerAcceptance url={window.location.href} />;
          default:
            return <PageNotFound />;
        }
      })()}
    </>
  );
};
