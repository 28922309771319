import {
  AddCircleOutline as AddIcon,
  DeleteOutline as RemoveIcon,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';

import { MarginItem } from 'api/client/types';
import { useMargins } from 'api/hooks/useMargins';
import { useSupplierCompany } from 'api/hooks/useSupplierCompanies';
import { AddMarginsDialog } from 'components/AddMarginsDialog';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { DeleteMarginsDialog } from 'components/DeleteMarginsDialog';
import { EditMarginsDialog } from 'components/EditMarginsDialog';
import { AnalyticsIcon } from 'components/Icon';
import { PageContent } from 'components/PageContent';
import {
  PageHeader,
  PageHeaderDetails,
  PageHeaderIcon,
  PageHeaderTitle,
} from 'components/PageHeader';
import { PageLoader } from 'components/PageLoader';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table';
import { useUser } from 'components/UserProvider';
import { getErrorMessage } from 'helpers/error';
import { SupplierCompany } from 'types/financing';

import * as Styles from './Margins.styles';
export const Margins = () => {
  const { user } = useUser();

  const {
    data,
    error: marginsError,
    isLoading: isLoadingMargins,
  } = useMargins(user);
  const margins = useMemo(() => (data ? data.margins : []), [data]);

  const {
    data: supplierCompanies = [],
    error: supplierCompaniesError,
    isLoading: isLoadingSupplierCompanies,
  } = useSupplierCompany();

  const [isAddMarginsDialogDisplayed, setIsAddMarginsDialogDisplayed] =
    useState<boolean>(false);
  const [isDeleteMarginsDialogDisplayed, setIsDeleteMarginsDialogDisplayed] =
    useState<boolean>(false);
  const [isEditMarginsDialogDisplayed, setIsEditMarginsDialogDisplayed] =
    useState<boolean>(false);

  const [selectedMarginId, setSelectedMarginId] = useState('');
  const [selectedMarginClientPrefix, setSelectedMarginClientPrefix] =
    useState('');
  const [selectedMarginData, setSelectedMarginData] = useState<MarginItem>();
  const [suppliersWithoutMargins, setSuppliersWithoutMargins] = useState<
    SupplierCompany[]
  >([]);

  useEffect(() => {
    if (margins) {
      const marginSupplierIds = new Set(
        margins.map((margin) => margin.supplierId)
      );
      setSuppliersWithoutMargins(
        supplierCompanies.filter(
          (supplier) => !marginSupplierIds.has(supplier.supplier_id)
        )
      );
    }
  }, [margins, supplierCompanies]);

  const onAddMargin = () => {
    setIsAddMarginsDialogDisplayed(true);
  };

  const onDeleteMargin = async (id: string) => {
    setSelectedMarginId(id);
    setIsDeleteMarginsDialogDisplayed(true);
  };

  const onEditMargin = async (selectedMargin: MarginItem) => {
    if (margins && supplierCompanies) {
      const marginData = margins.find(
        (margin) => margin.marginId === selectedMargin.marginId
      );
      setSelectedMarginData(marginData);

      const clientPrefix = supplierCompanies.find(
        (company) => company.supplier_id === selectedMargin.supplierId
      )?.client_prefix;
      if (clientPrefix) setSelectedMarginClientPrefix(clientPrefix);
      setIsEditMarginsDialogDisplayed(true);
    }
  };

  if (isLoadingMargins || isLoadingSupplierCompanies) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderDetails>
          <PageHeaderIcon>
            <AnalyticsIcon />
          </PageHeaderIcon>
          <PageHeaderTitle>Margins</PageHeaderTitle>
        </PageHeaderDetails>
      </PageHeader>
      <PageContent>
        <Table>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>Supplier Company Name</TableHeaderCell>
              <TableHeaderCell>Margins</TableHeaderCell>
              <TableHeaderCell>Currency</TableHeaderCell>
              <TableHeaderCell>Tenor Days</TableHeaderCell>
              <TableHeaderCell>
                <Button onClick={() => onAddMargin()}>
                  <AddIcon />
                  New Margin
                </Button>
              </TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {margins &&
              margins.map((margin) => (
                <TableRow key={margin.marginId}>
                  <TableCell isTopBorderVisible={true}>
                    {margin.supplierCompanyName}
                  </TableCell>
                  <TableCell isTopBorderVisible={true}>
                    {margin.margin}
                  </TableCell>
                  <TableCell isTopBorderVisible={true}>
                    {margin.currency}
                  </TableCell>
                  <TableCell isTopBorderVisible={true}>
                    {margin.tenorDays}
                  </TableCell>
                  <TableCell isTopBorderVisible={true}>
                    <IconButton onClick={() => onEditMargin(margin)}>
                      <Styles.EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => onDeleteMargin(margin.marginId)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {selectedMarginData &&
              isEditMarginsDialogDisplayed &&
              !isEmpty(supplierCompanies) && (
                <EditMarginsDialog
                  investorCompanyProfileId={user.company_profile_id}
                  marginsData={selectedMarginData}
                  onClose={() => setIsEditMarginsDialogDisplayed(false)}
                  clientPrefix={selectedMarginClientPrefix}
                />
              )}
            {isAddMarginsDialogDisplayed && !isEmpty(supplierCompanies) && (
              <AddMarginsDialog
                onClose={() => setIsAddMarginsDialogDisplayed(false)}
                supplierCompanies={suppliersWithoutMargins}
                id={user.company_profile_id}
              />
            )}
            {isDeleteMarginsDialogDisplayed && (
              <DeleteMarginsDialog
                onClose={() => setIsDeleteMarginsDialogDisplayed(false)}
                id={selectedMarginId}
              />
            )}
          </TableBody>
        </Table>
        {Boolean(marginsError) && (
          <Alert>{getErrorMessage(marginsError)}</Alert>
        )}
        {Boolean(supplierCompaniesError) && (
          <Alert>{getErrorMessage(supplierCompaniesError)}</Alert>
        )}
      </PageContent>
    </>
  );
};
