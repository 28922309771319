import { API_URL } from 'config';
import { getAccessToken } from 'helpers/authentication';

import { ApiError } from './ApiError';

type ClientOptions = Omit<RequestInit, 'body'> & {
  body?: Record<string, unknown>;
};

const buildUrl = (path: string) => {
  return `${API_URL}${path}`;
};

const buildApiError = async (response: Response) => {
  const data = await response.json();

  return new ApiError(response.status, data ?? response.statusText);
};

const requestWithoutAuth = async (
  path: string,
  options: ClientOptions = {}
) => {
  const { body, ...otherOptions } = options;

  const response = await fetch(buildUrl(path), {
    ...otherOptions,
    headers: {
      'Content-Type': 'application/json',
      ...otherOptions.headers,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw await buildApiError(response);
  }

  return response;
};

const request = (path: string, options: ClientOptions = {}) => {
  return requestWithoutAuth(path, {
    ...options,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
      ...options?.headers,
    },
  });
};

export const get = (path: string, options?: ClientOptions) => {
  return request(path, { ...options, method: 'GET' });
};

export const post = (path: string, options?: ClientOptions) => {
  return request(path, { ...options, method: 'POST' });
};

export const postWithoutAuth = (path: string, options?: ClientOptions) => {
  return requestWithoutAuth(path, { ...options, method: 'POST' });
};

export const getWithoutAuth = (path: string, options?: ClientOptions) => {
  return requestWithoutAuth(path, { ...options, method: 'GET' });
};

export const deleteWithAuth = (path: string, options?: ClientOptions) => {
  return request(path, { ...options, method: 'DELETE' });
};
