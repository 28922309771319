import { isToday } from 'date-fns';
import pluralize from 'pluralize';

import { getNumberOfDaysToMaturity } from 'helpers/instrument';

import * as Styles from './MaturityTag.styles';

const MATURITY_NUMBER_OF_DAYS_TO_WARN_ABOUT = 10;

type Props = {
  maturityDate: Date;
};

export const MaturityTag = (props: Props) => {
  const { maturityDate } = props;
  const numberOfDaysToMaturity = getNumberOfDaysToMaturity(maturityDate);

  const isMaturingSoon =
    numberOfDaysToMaturity <= MATURITY_NUMBER_OF_DAYS_TO_WARN_ABOUT;
  const isMaturingToday = isToday(new Date(maturityDate));

  if (isMaturingToday) {
    return (
      <Styles.Tag isToday>
        <span>
          Due <strong> Today</strong>
        </span>
      </Styles.Tag>
    );
  }

  return (
    <Styles.Tag isSoon={isMaturingSoon}>
      <span>
        Due{' '}
        <strong>
          in {numberOfDaysToMaturity} {pluralize('day', numberOfDaysToMaturity)}
        </strong>
      </span>
    </Styles.Tag>
  );
};
