import { rem } from 'polished';
import styled from 'styled-components/macro';

import { getTheme } from 'styles';

export const Container = styled.div`
  width: 100%;
  max-width: ${rem(448)};
  background-color: ${getTheme('colors.white.100')};
  padding: ${getTheme('space.6')};
  border-radius: ${getTheme('radius.lg')};
`;

export const DialogTitle = styled.h2`
  font-size: ${getTheme('fontSizes.xl')};
  font-weight: 600;
  line-height: 1.5;
  margin-top: ${getTheme('space.2')};
  margin-bottom: ${getTheme('space.4')};
  text-align: center;
`;

export const DialogContent = styled.div``;

export const DialogText = styled.p`
  margin: 0;
  font-size: ${getTheme('fontSizes.base')};
  line-height: ${getTheme('lineHeights.3')};
  color: ${getTheme('colors.black.70')};
  text-align: center;

  & + & {
    margin-top: ${getTheme('space.6')};
  }
`;

export const DialogActions = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: ${getTheme('space.4')};
  margin-top: ${getTheme('space.8')};
`;
